import React, { useEffect } from 'react'
import Base from '../../Components/Layout/Base'
import "./style.css";

const Tnc = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [])

  return (
    <Base>
      <div className="t-n-c-block">
        <div className="heading">
          <div className="head">
            <h1 className="title">Terms &amp; Conditions of Use</h1>
          </div>
          <div className="date">Last Updated: November 09, 2022</div>
        </div>

        <div className="acceptance-terms">
          <div className="head">
            <div className="term-title">Acceptance of Terms</div>
          </div>

          <div className="text-block">
            <div className="subtitle">
              Please read the terms of use thoroughly and carefully.
            </div>

            <p className="paragraph">
              The terms and conditions set forth below ("Terms of Use") and the
              Privacy Policy (as defined below) constitute a legally-binding
              agreement between KidEx Venture Private Limited ("masterNAUKRI")
              and you. These Terms of Use contain provisions that define your
              limits, legal rights and obligations with respect to your use of
              and participation in (i) the masterNAUKRI app and mobile
              application, including the classified advertisements, forums,
              various email functions and Internet links, and all content and
              masterNAUKRI services available through the domain and sub-domains
              of masterNAUKRI located at www.masternaukri.com (collectively
              referred to herein as the "App"), and (ii) the online transactions
              between those users of the App who are offering services (each, a
              "Coach") and those users of the App who are obtaining services
              (each, a "Service User") through the App (such services,
              collectively, the "Services"). The Terms of Use described below
              incorporate the Privacy Policy and apply to all users of the App,
              including users who are also contributors of text, photo &amp;
              video content, information, private and public messages,
              advertisements, and other materials or Services on the App.
            </p>

            <div className="subtitle">
              The App is currently owned and operated by KidEx Venture Private
              Limited.
            </div>

            <p className="paragraph">
              You acknowledge that the App serves as a venue for the online
              distribution and publication of user &amp; company submitted
              information between Coach and Service Users, and, by using,
              visiting, registering for, and/or otherwise participating in this
              App, including the Services presented, promoted, and displayed on
              the App, and by clicking on "I have read and agree to the terms of
              use," you hereby certify that: (1) you are either a Coach or a
              prospective Service User, (2) you have the authority to enter into
              these Terms of Use, (3) you authorize the transfer of payment for
              Services requested through the use of the App, and (4) you agree
              to be bound by all terms and conditions of these Terms of Use and
              any other documents incorporated by reference herein. If you do
              not so agree to the foregoing, you should not click to affirm your
              acceptance thereof, in which case you are prohibited from
              accessing or using the App. If you do not agree to any of the
              provisions set forth in the Terms of Use, kindly discontinue
              viewing or participating in this App immediately.
            </p>

            <p className="paragraph">
              You specifically agree that by using the app, you are at least 18
              years of age and you are competent under law to enter into a
              legally binding and enforceable contract.
            </p>

            <p className="paragraph">
              All references to "you" or "your," as applicable, mean the person
              that accesses, uses, and/or participates in the App in any manner.
              If you use the App or open an Account (as defined below) on behalf
              of a business, you represent and warrant that you have the
              authority to bind that business and your acceptance of the Terms
              of Use will be deemed an acceptance by that business and "you" and
              "your" herein shall refer to that business.
            </p>

            <div className="text">
              1. MODIFICATIONS TO TERMS OF USE AND/OR PRIVACY POLICY
            </div>
            <p className="paragraph">
              masterNAUKRI reserves the right, in its sole discretion, to
              change, modify, or otherwise amend the Terms of Use, and any other
              documents incorporated by reference herein for complying with
              legal and regulatory framework and for other legitimate business
              purposes, at any time, and masterNAUKRI will post notice of the
              changes and the amended Terms of Use at the domain of
              www.masternaukri.com/ppc. It is your responsibility to review the
              Terms of Use for any changes and you are encouraged to check the
              Terms of Use frequently. Your use of the App following any
              amendment of the Terms of Use will signify your assent to and
              acceptance of any revised Terms of Use. If you do not agree to
              abide by these or any future Terms of Use, please do not use or
              access the App.
            </p>

            <div className="text">2. PRIVACY POLICY</div>
            <p className="paragraph">
              masterNAUKRI has established a Privacy Policy that explains to
              users how their information is collected and used. The Privacy
              Policy is referenced above and hereby incorporated into the Terms
              of Use set forth herein. Your use of this App is governed by the
              Privacy Policy.
            </p>

            <p className="paragraph">
              The Privacy Policy is located at: www.masternaukri.com/ppc.
            </p>
          </div>
        </div>

        <div className="acceptance-terms">
          <div className="text-block">
            <div className="head">
              <div className="term-title">Membership and Accessibility</div>
            </div>

            <div className="text">1. LICENSE TO ACCESS</div>
            <p className="paragraph">
              masterNAUKRI hereby grants you a non-exclusive, revocable license
              to use the App as set forth in the Terms of Use; provided,
              however, that (i) you will not copy, distribute, or make
              derivative works of the App in any medium without masterNAUKRI's
              prior written consent; (ii) you will not alter or modify any part
              of the App other than as may be reasonably necessary to use the
              App for its intended purposes; and (iii) you will otherwise act in
              accordance with the terms and conditions of the Terms of Use and
              in accordance with all applicable laws.
            </p>

            <div className="text">2. MEMBERSHIP ELIGIBILITY CRITERIA</div>
            <p className="paragraph">
              Use of the App is available only to individuals who are at least
              18 years old and can form legally binding contracts under
              applicable law. You represent, acknowledge and agree that you are
              at least 18 years of age, and that: (a) all registration
              information that you submit is truthful and accurate, (b) you will
              maintain the accuracy of such information, and (c) your use of the
              App and Services offered through this App do not violate any
              applicable law or regulation. Your Account (defined below) may be
              terminated without warning if we at our discretion, believe that
              you are under the age of 18 or that you are not complying with any
              applicable laws, rules or regulations.
            </p>

            <p className="paragraph">
              You need not register with masterNAUKRI to simply visit and view
              the App, but to access and participate in certain features of the
              App, you will need to create a password-protected account
              ("Account"). To create an account, you must submit your phone
              number through the account registration page on the App and
              receive a third party generated one-time password. You will also
              have the ability to provide additional optional information, which
              is not required to register for an account but may be helpful to
              masterNAUKRI in providing you with a more customized experience
              when using the App.
            </p>

            <p className="paragraph">
              You are solely responsible for safeguarding your masterNAUKRI
              password and, if applicable, your Third-Party Site Password
              (collectively, "Passwords") at all times and shall keep your
              Passwords secure at all times. You shall be solely responsible for
              all activity that occurs on your Account and you shall notify
              masterNAUKRI immediately of any breach of security or any
              unauthorized use of your Account. Similarly, you shall never use
              another's Account without masterNAUKRI’s permission. You agree
              that you will not misrepresent yourself or represent yourself as
              another user of the App and/or the Services offered through the
              App.
            </p>

            <p className="paragraph">
              You hereby expressly acknowledge and agree that you yourself and
              not masterNAUKRI will be liable for your losses, damages etc.
              (whether direct or indirect) caused by an unauthorized use of your
              Account. Notwithstanding the foregoing, you may be liable for the
              losses of masterNAUKRI or others due to such unauthorized
              use.&ZeroWidthSpace;
            </p>

            <p className="paragraph">
              An Account holder is sometimes referred to herein as a "Registered
              User."
            </p>
            <ul>
              <li>
                You acknowledge and agree that you shall comply with the
                following policies (the "Account Policies"):
              </li>
              <li>
                You will not copy or distribute any part of the App in any
                medium without masterNAUKRI's prior written authorization.
              </li>
              <li>
                You will not alter or modify any part of the App other than as
                may be reasonably necessary to use the App for its intended
                purpose.
              </li>
              <li>
                You will provide true, accurate, current and complete
                information when creating your Account and you shall maintain
                and update such information during the term of this Agreement so
                that it will remain accurate, true, current and complete.
              </li>
              <li>
                You shall not use any automated system, including but not
                limited to, "robots," "spiders," "offline readers," "scrapers,"
                etc., to access the App for any purpose without masterNAUKRI's
                prior written approval. You shall not in any manual or automated
                manner collect Coach(es) or Service Users information, including
                but not limited to, names, addresses, phone numbers, or email
                addresses, copying copyrighted text, or otherwise misuse or
                misappropriate App information or content, including but not
                limited to, use on a "mirrored", competitive, or third party
                site.
              </li>
              <li>
                You shall not in any way that transmits more request messages to
                the masterNAUKRI servers, or any server of a masterNAUKRI
                subsidiary or affiliate, in a given period of time than a human
                can reasonably produce in the same period by using a
                conventional online web browser; provided, however, that the
                operators of public search engines may use spiders or robots to
                copy materials from the site for the sole purpose of creating
                publicly available searchable indices of the materials, but not
                caches or archives of such material. masterNAUKRI reserves the
                right to revoke these exceptions either generally or in specific
                cases.
              </li>
              <li>
                You shall not recruit, solicit, or contact in any form Coach(es)
                or Service Users for employment or contracting for a business
                not affiliated with masterNAUKRI without express written
                permission from masterNAUKRI. You shall not take any action that
                (i) unreasonably encumbers or, in masterNAUKRI's sole
                discretion, may unreasonably encumber the App's infrastructure;
                (ii) interferes or attempts to interfere with the proper working
                of the App or any third-party participation in the App; or (iii)
                bypasses masterNAUKRI's measures that are used to prevent or
                restrict access to the App.
              </li>
              <li>
                You agree not to collect or harvest any personally identifiable
                data, including without limitation, names or other Account
                information, from the App, nor to use the communication systems
                provided by the App for any commercial solicitation purposes.
              </li>
            </ul>

            <div className="text">3. ADDITIONAL POLICIES</div>
            <p className="paragraph">
              Your access to, use of, and participation in the App is subject to
              the Terms of Use and all applicable masterNAUKRI regulations,
              guidelines and additional policies that masterNAUKRI may set forth
              from time to time, including without limitation, a copyright
              policy and any other restrictions or limitations that masterNAUKRI
              publishes on the App (the "Additional Policies"). You hereby agree
              to comply with the Additional Policies and your obligations there
              under at all times. You hereby acknowledge and agree that if you
              fail to adhere to any of the terms and conditions of this
              Agreement or documents referenced herein, including the Account
              Policies, membership eligibility criteria or Additional Policies,
              masterNAUKRI, in its sole discretion, may terminate your Account
              at any time without prior notice to you as well as initiate
              appropriate legal proceedings, if necessary.
            </p>
          </div>
        </div>

        <div className="acceptance-terms">
          <div className="text-block">
            <div className="head"><div className="term-title">Member Conduct</div></div>

            <div className="text">1. PROHIBITIONS ON SUBMITTED CONTENT</div>
            <p className="paragraph">
              You shall not upload, post, transmit, transfer, disseminate,
              distribute, or facilitate distribution of any content, including
              text, images, video, sound, data, information, or software, to any
              part of the App, including your profile ("Profile"), the posting
              of your Service ("Offer"), the posting of your desired Service
              ("Want"), or the posting of any opinions or reviews in connection
              with the App, the Service, the Coach, or the Service User
              ("Feedback") (all of the foregoing content is sometimes
              collectively referred to herein as "Submitted Content" and the
              posting of Submitted Content is sometimes referred to as a
              "Posting" or as "Postings") that:"
            </p>

            <ul>
              <li>
                Misrepresents the source of anything you post, including
                impersonation of another individual or entity or any false or
                inaccurate biographical information for any coach(es); provides
                or create links to external sites that violate the Terms of Use;
                is intended to harm or exploit any individual under the age of
                18 ("Minor") in any way; is designed to solicit, or collect
                personally identifiable information of any Minor, including, but
                not limited to, name, email address, home address, phone number,
                or the name of his or her school;
              </li>
              <li>
                Invades anyone's privacy by attempting to harvest, collect,
                store, or publish private or personally identifiable
                information, such as names, email addresses, phone numbers,
                passwords, account information, credit card numbers, home
                addresses, or other contact information without their knowledge
                and willing consent;
              </li>
              <li>
                Contains falsehoods or misrepresentations that could damage
                masterNAUKRI or any third party;
              </li>
              <li>
                Is pornographic, harassing, hateful, illegal, obscene,
                defamatory, libelous, slanderous, threatening, discriminatory,
                racially, culturally or ethnically offensive; incites,
                advocates, or expresses pornography, obscenity, vulgarity,
                profanity, hatred, bigotry, racism, or gratuitous violence;
                encourages conduct that would be considered a criminal offense,
                give rise to civil liability or violate any law; promotes
                racism, hatred or physical harm of any kind against any group or
                individual; contains nudity, violence or inappropriate subject
                matter; or is otherwise inappropriate;
              </li>
              <li>
                Is copyrighted, protected by trade secret or otherwise subject
                to third-party proprietary rights, including privacy and
                publicity rights, unless you are the owner of such rights or
                have permission from the rightful owner to post the material and
                to grant masterNAUKRI all of the license rights granted herein;
              </li>
              <li>
                Contains or promotes an illegal or unauthorized copy of another
                person's copyrighted work, such as pirated computer programs or
                links to them, information to circumvent manufacture installed
                copy-protection devices, pirated music or links to pirated music
                files, or lyrics, guitar tabs or sheet music, works of art,
                teaching tools, or any other item the copy, display, use,
                performance, or distribution of which infringes on another's
                copyright, intellectual property right, or any other proprietary
                right;
              </li>
              <li>
                Is intended to threaten, stalk, defame, defraud, degrade,
                victimize, or intimidate an individual or group of individuals
                for any reason on the basis of age, gender, disability,
                ethnicity, sexual orientation, race, or religion; or to incite
                or encourage anyone else to do so;
              </li>
              <li>
                Intends to harm or disrupt another user's computer or would
                allow others to illegally access software or bypass security on
                apps or servers, including but not limited, to spamming;
                impersonates, uses the identity of, or attempts to impersonate a
                masterNAUKRI employee, agent, manager, host, another user, or
                any other person though any means;
              </li>
              <li>
                Advertises or solicits a business not related to or appropriate
                for the App (as determined by masterNAUKRI in its sole
                discretion); Contains or could be considered "junk mail",
                "spam", "chain letters", "pyramid schemes", "affiliate
                marketing", or unsolicited commercial advertisement;
              </li>
              <li>
                Contains advertising for ponzi schemes, discount cards, credit
                counseling, online surveys or online contests;
              </li>
              <li>
                Distributes or contains viruses or any other technologies that
                may harm masterNAUKRI, or the interests or property of
                masterNAUKRI users Contains links to commercial services or
                apps, except as allowed pursuant to the Terms of Use; is
                non-local or irrelevant content;
              </li>
              <li>
                Contains identical content to other open Postings you have
                already posted; or uses any form of automated device or computer
                program that enables the submission of Postings without the
                express written consent of masterNAUKRI
              </li>
            </ul>

            <div className="text">2. PROHIBITIONS ON SENDING MESSAGES</div>
            <p className="paragraph">
              You will not send messages to other users containing:
            </p>
            <ul>
              <li>
                Offers to make national or international money transfers for
                amounts exceeding the asking price of a service, with intent to
                request a refund of any portion of the payment; or
              </li>
              <li>
                Unsolicited advertising or marketing of a service not offered on
                the App or an external app.
              </li>
            </ul>

            <div className="text">3. NO DISCRIMINATION</div>
            <p className="paragraph">
              1. Discriminatory Postings. Indian laws prohibit any preference,
              limitation or discrimination based on race, color, religion, sex,
              national origin, age, handicap or other protected class.
              masterNAUKRI will not knowingly accept any Posting which is in
              violation of the law. masterNAUKRI has the right, in its sole
              discretion and without prior notice to you; to immediately remove
              any posting that discriminates or is any way in violation of any
              law.
            </p>
            <p className="paragraph">
              2. PROHIBITIONS WITH RESPECT TO SERVICES While using the App, you shall not:
            </p>

            <ul className="number-text">
              <li>
                Post content or items in any inappropriate category or areas on
                the App;
              </li>
              <li>
                Violate any laws, third-party rights, Account Policies, or any
                provision of the Terms of Use, such as the prohibitions
                described above;
              </li>
              <li>
                Fail to deliver payment for Services purchased by you, unless
                the Coach has materially changed the description of the Service
                description after you negotiate an agreement for such Service, a
                clear typographical error is made, or you cannot authenticate
                the Coach's identity;
              </li>
              <li>
                Fail to perform Services purchased from you, unless the Service
                User fails to materially meet the terms of the mutually agreed
                upon Agreement for the Services, refuses to pay, a clear
                typographical error is made, or you cannot authenticate the
                Service User's identity; manipulate the price of any Service or
                interfere with other users' Postings;
              </li>
              <li>
                Circumvent or manipulate our fee structure, the billing process,
                or fees owed to masterNAUKRI; post false, inaccurate,
                misleading, defamatory, or libelous content (including personal
                information about any App user);
              </li>
              <li>
                Take any action that may undermine the Feedback or ratings
                systems (such as displaying, importing or exporting Feedback
                information off of the App or using it for purposes unrelated to
                the App);
              </li>
            </ul>

            <p className="paragraph">
              3. FEEDBACK
              As a participant in the App, you agree to use careful, prudent,
              and good judgment when leaving Feedback for another user. The
              following actions constitute inappropriate uses of Feedback: (a)
              threatening to leave negative or impartial Feedback for another
              user unless that user provides services not included in the
              original Posting or not agreed to as part of the Service to be
              provided; (b) leaving Feedback in order to make the Coach or
              Service User appear better than he or she actually is or was;
              and (c) including conditions in an Offer or Want that restrict a
              Coach or a Service User from leaving Feedback.
            </p>
            <ul>
              <li>
                Sanctions for Inappropriate Use of Feedback. If you violate any
                of the above-referenced rules in connection with leaving
                Feedback, masterNAUKRI, in its sole discretion, may take any of
                the following actions: (i) cancel your Feedback or any of your
                Postings; (ii) limit your Account privileges; (iii) suspend your
                Account; and/or (iv) decrease your status earned via the
                Feedback.
              </li>
              <li>
                Reporting Inappropriate Use of Feedback. You may contact
                masterNAUKRI regarding any inappropriate use of Feedback
                via-email at info@masternaukri.com.
              </li>
              <li>
                Resolving Disputes in Connection with Feedback. In the event of
                any dispute between users of the App concerning Feedback,
                masterNAUKRI shall be the final arbiter of such dispute.
                Further, IN THE EVENT OF ANY DISPUTE BETWEEN USERS OF THE APP
                CONCERNING FEEDBACK, masterNAUKRI HAS THE RIGHT, IN ITS SOLE AND
                ABSOLUTE DISCRETION, TO REMOVE SUCH FEEDBACK OR TAKE ANY ACTION
                IT DEEMS REASONABLE WITHOUT INCURRING ANY LIABILITY THEREFROM.
              </li>
            </ul>
            <p className="paragraph">
              The foregoing lists of prohibitions provide examples and are not
              complete or exclusive. masterNAUKRI reserves the right to (a)
              terminate your access to your Account, your ability to post to
              this App (or the Services) and (b) refuse, delete or remove, move
              or edit the content, in whole or in part, of any Postings; with or
              without cause and with or without notice, for any reason or no
              reason, or for any action that masterNAUKRI determines is
              inappropriate or disruptive to this App or to any other user of
              this App and/or Services. masterNAUKRI reserves the right to
              restrict the number of e-mails or other messages that you are
              allowed to send to other users to a number that masterNAUKRI deems
              appropriate in masterNAUKRI's sole discretion. masterNAUKRI may
              report to law enforcement authorities any actions that may be
              illegal, and any reports it receives of such conduct. When legally
              required or at masterNAUKRI's discretion, masterNAUKRI will
              cooperate with law enforcement agencies in any investigation of
              alleged illegal activity on this App or on the Internet.
              masterNAUKRI does not and cannot review every Posting posted to
              the App. These prohibitions do not require masterNAUKRI to
              monitor, police or remove any Postings or other information
              submitted by you or any other user.
            </p>
          </div>
        </div>

        <div className="acceptance-terms">
          <div className="text-block">
            <div className="head">
              <div className="term-title">Rules for Coach(es)</div>
            </div>

            <div className="text">
              1. PROFILES AND OFFERS MUST NOT BE FRAUDULENT
            </div>
            <p className="paragraph">
              Subject to any exceptions set forth in these Terms of Use or
              Additional Policies, if any, Coach(es) shall not: (a) list
              Services or offers relating to any Service in a category that is
              inappropriate to the Service they are offering; (b) misrepresent
              the location at which they will provide a Service; (c) include
              brand names or other inappropriate keywords in their Profile,
              Offer, Want, Feedback, or any other title or description relating
              to a Service; (d) use misleading titles that do not accurately
              describe the Service; or (e) include any information in their
              Profile that is fraudulent.
            </p>

            <div className="text">
              2. PROFILES AND OFFERS CANNOT USE TECHNIQUES TO AVOID OR
              CIRCUMVENT masterNAUKRI FEES
            </div>
            <p className="paragraph">
              Subject to any exceptions set forth in these Terms of Use or
              Additional Policies, if any, Coach(es) shall not: (a) offer a
              catalog or a link to a third-party app from which Service Users or
              any Registered User or user of the App may obtain the Service
              directly; (b) exceed multiple Posting limits; (c) post a single
              Service but offer additional identical services in the Service
              description; (d) charge fees for traveling further than desired to
              provide the Service; (e) offer the opportunity through
              masterNAUKRI to purchase the Service or any other service outside
              of masterNAUKRI; (f) use their Profile page or user name to
              promote services not offered on or through the App and/or
              prohibited services.
            </p>

            <p className="paragraph">
              In case we realize that any professional is involved in any of the
              above activities, masterNAUKRI holds the sole discretion to
              blacklist the professional and withhold any outstanding credits or
              payments to the professional.
            </p>

            <div className="text">
              3. PROFILES AND OFFERS MUST PROMOTE A FAIR PLAYING FIELD AND
              PROVIDE A SAFE, SIMPLE, AND POSITIVE EXPERIENCE FOR ALL APP USERS
            </div>
            <p className="paragraph">
              Subject to any exceptions set forth in these Terms of Use or
              Additional Policies, if any, Coach(es) shall not: (a) solicit
              Service Users to mail cash or use other payment methods not
              specifically permitted by masterNAUKRI as approved payment
              methods; (b) include links that do not conform to masterNAUKRI's
              policies with respect to third-party links; (c) use certain types
              of HTML and JavaScript in Postings, your Profile page, your Offer
              page, or your Wants page; (d) promote raffles, prizes, bonuses,
              games of chance, giveaways, or random drawings; (e) use profanity
              in any Posting; (f) acknowledge or credit a third-party coach for
              services or products directly connected with your particular
              Posting (1) with more than 10 words of text at HTML font size
              greater than 3 and/or a logo of 88X33 pixels (provided that you
              represent and warrant that you have the necessary rights,
              licenses, permissions and/or authorizations from the applicable
              third party to use that third party's name and/or logo), (2) with
              any promotional material in connection with that third-party
              company, and/or (3) with a link to the third-party's app with any
              information in addition to the Service provided via masterNAUKRI;
              (g) include third-party endorsements in a Posting; or (h) create a
              Posting that does not offer a Service.
            </p>

            <div className="text">
              4. SANCTIONS FOR VIOLATING ANY OF THE RULES FOR COACH(ES)
            </div>
            <p className="paragraph">
              If a Coach violates any of the above-referenced rules in
              connection with his or her Posting, masterNAUKRI, in its sole
              discretion, may take any of the following actions: (a) cancel the
              Posting; (b) limit the Coach's Account privileges; (c) suspend the
              Coach's Account; (d) cause the Coach to forfeit any fees earned on
              a cancelled Posting; and/or (e) decrease the Coach's status earned
              via Feedback.
            </p>
          </div>
        </div>

        <div className="acceptance-terms">
          <div className="text-block">
            <div className="head">
              <div className="term-title">Rules for Service Users</div>
            </div>

            <div className="text">
              1. SERVICE USERS SHALL NOT TAKE ANY OF THE FOLLOWING ACTIONS:
            </div>
            <p className="paragraph">
              (a) commit to purchasing or using a Service without paying; (b)
              sign up for, negotiate a price for, use, or otherwise solicit a
              Service with no intention of following through with your use of or
              payment for the Service; (c) agree to purchase a Service when you
              do not meet the Coach's terms as outlined in the Posting, or agree
              to purchase a Service with the intention of disrupting a Posting;
              or (d) misuse any options made available now or in the future by
              masterNAUKRI in connection with the use or purchase of any
              Service.
            </p>

            <div className="text">
              2. SANCTIONS FOR VIOLATING ANY OF THE RULES FOR SERVICE USERS
            </div>
            <p className="paragraph">
              If a Service User violates any of the above-referenced rules in
              connection with his or her Posting, masterNAUKRI, in its sole
              discretion, may take any of the following actions: (a) cancel the
              Posting; (b) limit the Service User's Account privileges; (c)
              suspend the Service User's Account; and/or (d) decrease the
              Service User's status earned via the Feedback.
            </p>
          </div>
        </div>

        <div className="acceptance-terms">
          <div className="text-block">
            <div className="head">
              <div className="term-title">Use of Submitted Content</div>
            </div>

            <div className="text">1. NO CONFIDENTIALITY</div>
            <p className="paragraph">
              The App may now or in the future permit the submission of videos
              or other communications submitted by you and other users,
              including without limitation, your Profile, your Offer, your
              Wants, any Feedback, and all Submitted Content, and the hosting,
              sharing, and/or publishing of such Submitted Content. You
              understand that whether or not such Submitted Content is
              published, masterNAUKRI does not guarantee any confidentiality
              with respect to any Submitted Content.
            </p>

            <p className="paragraph">
              You agree that any Submitted Content provided by you for which you
              authorize to be searchable by Registered Users who have access to
              the App is provided on a non-proprietary and non confidential
              basis. You agree that masterNAUKRI shall be free to use or
              disseminate such freely searchable Submitted Content on an
              unrestricted basis for the purpose of providing the Services.
            </p>

            <p className="paragraph">
              You are aware that any information provided by you in the
              Submitted Content towards locating a Coach who ordinarily is bound
              to maintain confidentiality under law with his/her client (i.e. a
              doctor or a lawyer) is not extended to masterNAUKRI.
            </p>

            <p className="paragraph">
              masterNAUKRI may also disclose user information including personal
              information if masterNAUKRI reasonably believes that disclosure
              (i) is necessary in order to comply with a legal process (such as
              a court order, search warrant, etc.) or other legal requirement of
              any governmental authority, (ii) would potentially mitigate
              masterNAUKRI’s liability in an actual or potential lawsuit, (iii)
              is otherwise necessary or appropriate to protect our rights or
              property, or the rights or property of any person or entity, (iv)
              to enforce this Agreement (including, but not limited to ensuring
              payment of fees by users), or (v) as may be required or necessary
              to deter illegal behavior (including, but not limited to, fraud).
            </p>

            <div className="text">2. YOUR REPRESENTATIONS AND WARRANTIES</div>
            <p className="paragraph">
              You shall be solely responsible for your own Submitted Content and
              the consequences of posting or publishing it. In connection with
              Submitted Content, you affirm, represent, and/or warrant that: (a)
              you own or have the necessary licenses, rights, consents, and
              permissions to use and authorize masterNAUKRI to use all patent,
              trademark, trade secret, copyright or other proprietary rights in
              and to any and all Submitted Content to enable inclusion and use
              of the Submitted Content in the manner contemplated by the App and
              these Terms of Use; and (b) you have the written consent, release,
              and/or permission of each and every identifiable individual person
              in the Submitted Content to use the name or likeness of each and
              every such identifiable individual person to enable inclusion and
              use of the Submitted Content in the manner contemplated by the App
              and these Terms of Use. You agree to pay for all royalties, fees,
              and any other monies owing any person by reason of any Submitted
              Content posted by you to or through the App.
            </p>

            <div className="text">
              3. YOUR OWNERSHIP RIGHTS AND LICENSE TO masterNAUKRI
            </div>
            <p className="paragraph">
              You retain all of your ownership rights in your Submitted Content.
              However, by submitting the Submitted Content to masterNAUKRI for
              posting on the App, you hereby grant, and you represent and
              warrant that you have the right to grant, to masterNAUKRI a
              perpetual, worldwide, non-exclusive, royalty-free, sublicense able
              and transferable license to link to, use, reproduce, distribute,
              reformat, translate, prepare derivative works of, display, and
              perform the Submitted Content in connection with the App and
              masterNAUKRI's (and its successor's) business operations,
              including without limitation, for the promotion and redistribution
              of any part or all of the App, and any derivative works thereof,
              in any media formats and through any media channels. You also
              hereby grant each user of the App a non-exclusive license to
              access your Submitted Content through the App, and to use,
              reproduce, distribute, prepare derivative works of, display and
              perform such Submitted Content as permitted through the
              functionality of the App and under these Terms of Use. The
              foregoing license granted by you terminates once you remove or
              delete the Submitted Content from the App.
            </p>

            <p className="paragraph">
              You acknowledge and understand that the technical processing and
              transmission of the App, including your Submitted Content, may
              involve (a) transmissions over various networks; and (b) changes
              to conform and adapt to technical requirements of connecting
              networks or devices.
            </p>

            <p className="paragraph">
              You may remove your Submitted Content from the App at any time. If
              you choose to remove your Submitted Content, the license granted
              above will automatically expire.
            </p>

            <div className="text">
              4. masterNAUKRI'S DISCLAIMERS AND RIGHT TO REMOVE
            </div>
            <p className="paragraph">
              masterNAUKRI does not endorse any Submitted Content or any
              opinion, recommendation, or advice expressed therein, and
              masterNAUKRI expressly disclaims any and all liability in
              connection with all Submitted Content. masterNAUKRI does not
              permit copyright infringing activities and infringement of
              intellectual property rights on the App, and masterNAUKRI will
              remove any Data (as defined below) or Submitted Content if
              properly notified, pursuant to the "take down" notification
              procedure described in Section J below, that such Posting or
              Submitted Content infringes on another's intellectual property
              rights. masterNAUKRI reserves the right to remove any Data or
              Submitted Content without prior notice. masterNAUKRI will also
              terminate a user's access to the App, if he or she is determined
              to be a repeat infringer. A repeat infringer is a App user who has
              been notified of infringing activity more than twice and/ or has
              had Submitted Content removed from the App more than twice.
              masterNAUKRI also reserves the right, in its sole and absolute
              discretion, to decide whether any Data or Submitted Content is
              appropriate and complies with these Terms of Use for all
              violations, in addition to copyright infringement and violations
              of intellectual property law, including, but not limited to,
              pornography, obscene or defamatory material, or excessive length.
              masterNAUKRI may remove such Submitted Content and/or terminate a
              user's access for uploading such material in violation of these
              Terms of Use at any time, without prior notice and in its sole
              discretion.
            </p>

            <p className="paragraph">
              You acknowledge and understand that when using the App, you will
              be exposed to Submitted Content from a variety of sources, and
              that masterNAUKRI is not responsible for the accuracy, usefulness,
              safety, or intellectual property rights of or relating to such
              Submitted Content. You further acknowledge and understand that you
              may be exposed to Submitted Content that is inaccurate, offensive,
              indecent, or objectionable, and you agree to waive, and hereby do
              waive, any legal or equitable rights or remedies you have or may
              have against masterNAUKRI with respect thereto, and agree to
              indemnify and hold masterNAUKRI, its owners, members, managers,
              operators, directors, officers, agents, affiliates, and/or
              licensors, harmless to the fullest extent allowed by law regarding
              all matters related to your use of the App.
            </p>

            <p className="paragraph">
              You are solely responsible for the photos, profiles and other
              content, including, without limitation, Submitted Content that you
              publish or display on or through the App, or transmit to other App
              users. You understand and agree that masterNAUKRI may, in its sole
              discretion and without incurring any liability, review and delete
              or remove any Submitted Content that violates this Agreement or
              which might be offensive, illegal, or that might violate the
              rights, harm, or threaten the safety of App users or others.
            </p>

            <div className="text">5. SUGGESTIONS</div>
            <p className="paragraph">
              If you send or transmit any communications, comments, questions,
              suggestions, or related materials to masterNAUKRI, whether by
              letter, email, telephone, or otherwise (collectively,
              "Suggestions"), suggesting or recommending changes to the App,
              including, without limitation, new features or functionality
              relating thereto, all such Suggestions are, and will be treated
              as, non-confidential and non-proprietary. You hereby assign all
              right, title, and interest in, and masterNAUKRI is free to use,
              without any attribution or compensation to you, any ideas,
              know-how, concepts, techniques, or other intellectual property and
              proprietary rights contained in the Suggestions, whether or not
              patentable, for any purpose whatsoever, including but not limited
              to, developing, manufacturing, having manufactured, licensing,
              marketing, and selling, directly or indirectly, products and
              services using such Suggestions. You understand and agree that
              masterNAUKRI is not obligated to use, display, reproduce, or
              distribute any such ideas, know-how, concepts, or techniques
              contained in the Suggestions, and you have no right to compel such
              use, display, reproduction, or distribution or seek recognition if
              the Suggestions are in fact implemented.
            </p>
          </div>
        </div>

        <div className="acceptance-terms">
          <div className="text-block">
            <div className="head">
              <div className="term-title">
                Copyright Infringement Take Down Procedure
              </div>
            </div>

            <p className="paragraph">
              masterNAUKRI has high regard for intellectual property and expects
              the same level of standard to be employed by its users.
              masterNAUKRI may, in appropriate circumstances and at its
              discretion, terminate the Account or prohibit access to the App of
              users who infringe upon the intellectual property rights of
              others.&ZeroWidthSpace;
            </p>
            <p className="paragraph">
              If you believe that your work has been copied and posted on the
              App in a way that constitutes copyright infringement and/or
              trademark infringement, please send the following information to
              us at info@masternaukri.com &ZeroWidthSpace;
            </p>
            <ul className="roman-number">
              <li>
                (i) Identification of the copyrighted and/or trademarked work
                claimed to have been infringed, or, if multiple works at a
                single online site are covered by a single notification, a
                representative list of such works at that site;
              </li>
              <li>
                (ii) Identification of the material that is claimed to be
                infringing or to be the subject of infringing activity and that
                is to be removed or access to which is to be disabled at the
                App, and information reasonably sufficient to permit
                masterNAUKRI to locate the material.;
              </li>
              <li>
                (iii) A written statement that you have a good faith belief that
                the disputed use is not authorized by the copyright and/or
                trademark owner, its agent, or the law;
              </li>
              <li>
                (iv) Information reasonably sufficient to permit masterNAUKRI to
                contact you as the complaining party, such as an address,
                telephone number, and, if available, an electronic mail address
                at which you may be contacted;
              </li>

              <li>
                (v) An electronic or physical signature of the person authorized
                to act on behalf of the owner of an exclusive interest that is
                allegedly infringed; and
              </li>

              <li>
                (vi) A statement by you, made under penalty of perjury, that the
                information in your report is accurate and that you are the
                owner of the exclusive right or authorized to act on the behalf
                of the owner of the exclusive right. A statement by you
                comprised of the foregoing points is referred to herein as the
                "Notice."
              </li>
            </ul>
            <p className="paragraph">
              Only the intellectual property rights owner is permitted to report
              potentially infringing items through masterNAUKRI's reporting
              system set forth above. If you are not the intellectual property
              rights owner, you should contact the intellectual property rights
              owner and they can choose whether to use the procedures set forth
              in these Terms of Use.
            </p>
          </div>
        </div>

        <div className="acceptance-terms">
          <div className="text-block">
            <div className="head">
              <div className="term-title">
                Modifications to or Termination of App
              </div>
            </div>

            <div className="text">1. MODIFICATION OR CESSATION OF APP</div>
            <p className="paragraph">
              masterNAUKRI reserves the right at any time and from time to time
              to modify or discontinue, temporarily or permanently, the App (or
              any part thereof) with or without notice and in its sole
              discretion. You agree that masterNAUKRI shall not be liable to you
              or to any third party for any modification, suspension or
              discontinuance of masterNAUKRI services.
            </p>

            <div className="text">2. TERMINATION BY masterNAUKRI</div>
            <p className="paragraph">
              You hereby acknowledge and agree that masterNAUKRI, in its sole
              and absolute discretion, has the right (but not the obligation) to
              delete, terminate, or deactivate your Account, block your email or
              IP address, cancel the App or otherwise terminate your access to
              or participation in the use of the App (or any part thereof), or
              remove and discard any Submitted Content on the App ("Termination
              of Service"), immediately and without notice, for any reason,
              including without limitation, Account inactivity or if
              masterNAUKRI believes or has reason to believe that you have
              violated any provision of the Terms of Use..
            </p>

            <div className="text">3. TERMINATION BY YOU</div>
            <p className="paragraph">
              You may cancel your use of the App and/or terminate the Terms of
              Use with or without cause at any time by writing to
              info@masternaukri.com.
            </p>

            <div className="text">4. EFFECT OF TERMINATION</div>
            <p className="paragraph">
              Upon termination of your Account, your right to participate in the
              App, including, but not limited to, your right to offer or
              purchase Services and your right to receive any fees or
              compensation, including, without limitation, referral discounts,
              incentive bonuses, or other special offer rewards, shall
              automatically terminate. You acknowledge and agree that your right
              to receive any fees or compensation hereunder is conditional upon
              your proper use of the App, your adherence to the Terms of Use,
              the continuous activation of your Account, and your permitted
              participation in the App. In the event of Termination of Service,
              your Account will be disabled and you may not be granted access to
              your Account or any files or other data contained in your Account.
              Notwithstanding the foregoing, residual data may remain in the
              masterNAUKRI system.
            </p>

            <p className="paragraph">
              Unless masterNAUKRI has previously cancelled or terminated your
              use of the App (in which case subsequent notice by masterNAUKRI
              shall not be required), if you provided a valid email address
              during registration, masterNAUKRI will notify you via email of any
              such termination or cancellation, which shall be effective
              immediately upon masterNAUKRI's delivery of such notice.
            </p>

            <p className="paragraph">
              Upon Termination of Service, the following shall occur: all
              licenses granted to you hereunder will immediately terminate; and
              you shall promptly destroy all copies of masterNAUKRI Data (as
              defined below), Marks (as defined below) and other content in your
              possession or control. You further acknowledge and agree that
              masterNAUKRI shall not be liable to you or any third party for any
              termination of your access to the App. Upon Termination of
              Service, masterNAUKRI retains the right to use any data collected
              from your use of the App for internal analysis and archival
              purposes, and all related licenses you have granted masterNAUKRI
              hereunder shall remain in effect for the foregoing purpose. In no
              event is masterNAUKRI obligated to return any Submitted Content to
              you. Sections K, L, M, N, O, Q, R, S, T, U, V, W, and X, shall
              survive expiration or termination of the App or your
              Account.&ZeroWidthSpace;
            </p>

            <p className="paragraph">
              You agree to indemnify and hold masterNAUKRI, and its officers,
              managers, members, affiliates, successor, assigns, directors,
              agents, coach(es), suppliers, and employees harmless from any
              claim or demand, including reasonable attorneys' fees and court
              costs, made by any third party due to or arising out of the
              Termination of Service.
            </p>
          </div>
        </div>

        <div className="acceptance-terms">
          <div className="text-block">
            <div className="head">
              <div className="term-title">Intellectual Property Rights</div>
            </div>

            <div className="text">
              1. masterNAUKRI OWNS OR HOLDS THE LICENSES TO ALL DATA AND MARKS
              ON THE APP
            </div>
            <p className="paragraph">
              The content on the App (exclusive of all Submitted Content),
              including without limitation, the text, software, scripts,
              graphics, photos, sounds, music, videos, interactive features and
              the like ("Data") and the trademarks, service marks and logos
              contained therein ("Marks"), are owned by masterNAUKRI. Other
              trademarks, names and logos on this App are the property of their
              respective owners.
            </p>

            <p className="paragraph">
              Data on the App is provided to you AS IS for your information and
              personal use only and may not be used, copied, reproduced,
              distributed, transmitted, broadcast, displayed, sold, licensed, or
              otherwise exploited for any other purposes whatsoever without the
              prior written consent of the respective owners. masterNAUKRI
              reserves all rights not expressly granted in and to the App and
              the Data. You agree not to use, copy, or distribute, any of the
              Data other than as expressly permitted herein, including any use,
              copying, or distribution of Submitted Content obtained through the
              App for any commercial purposes. If you download or print a copy
              of the Data for personal use, you must retain all copyright and
              other proprietary notices contained thereon. You agree not to
              circumvent, disable or otherwise interfere with security features
              of the App or features that prevent or restrict use or copying of
              any Data or enforce limitations on use of the App or the Data
              therein.
            </p>

            <div className="text">
              2. masterNAUKRI'S LICENSE TO YOU FOR THE USE OF DATA AND MARKS
            </div>
            <p className="paragraph">
              The App contains masterNAUKRI's Data and Marks, which are, or may
              become, protected by copyright, trademark, patent, trade secret
              and other laws, and masterNAUKRI owns and retains all rights in
              the masterNAUKRI Data and Marks. Subject to these Terms of Use,
              masterNAUKRI hereby grants you a limited, revocable,
              nontransferable, nonsublicensable license to reproduce and display
              the masterNAUKRI Data (excluding any software source code) solely
              for your personal use in connection with accessing and
              participating in the App.
            </p>

            <p className="paragraph">
              The App may also contain Data of other users or licensors, which
              you shall not copy, modify, translate, publish, broadcast,
              transmit, distribute, perform, display, or sell.
            </p>
          </div>
        </div>

        <div className="acceptance-terms">
          <div className="text-block">
            <div className="head">
              <div className="term-title">masterNAUKRI Fees</div>
            </div>

            <div className="text">1. FEES INCURRED BY COACH(ES)</div>
            <p className="paragraph">
              Joining masterNAUKRI, opening an Account, posting Services and
              viewing posted Services is free. masterNAUKRI reserves the right
              at its sole discretion to charge fees to Coach(es) for other
              services that masterNAUKRI may provide in the future.
            </p>
            <p className="paragraph">
              masterNAUKRI may also in certain categories require Coach(es) to
              make payments for following up on job leads i.e. requests posted
              by Service Users, and permitting the Coach to contact the Service
              User (“Lead Fee”) irrespective of whether the job leads eventually
              result in a transaction or exchange of consideration for the
              service requested. In order to be eligible to make such Lead Fee
              payment, Coach(es) shall be required to make advance payments
              (“Credits”) from which the Lead Fee may be deducted as and when
              necessary. Whenever the Credits fall below the prescribed
              threshold, the coach shall be prompted to refill the Credits in
              order to continue enjoying the ability to follow up on leads and
              contacting Service Users.
            </p>

            <p className="paragraph">
              The coach is entitled to refund of credits that is credited back
              in their wallet in the following conditions:
            </p>

            <ul>
              <li>
                1.Refunds are processed only when a customer has cancelled the
                request and not viewed your profile
              </li>
              <li>
                2.Refunds are processed if the customer has not viewed quote and
                not cancelled request 4 days after your response
              </li>
            </ul>
            <p className="paragraph">
              Only the intellectual property rights owner is permitted to report
              potentially infringing items through masterNAUKRI's reporting
              system set forth above. If you are not the intellectual property
              rights owner, you should contact the intellectual property rights
              owner and they can choose whether to use the procedures set forth
              in these Terms of Use.
            </p>

            <div className="text">2. FEES INCURRED BY SERVICE USERS</div>

            <p className="paragraph">
              Joining masterNAUKRI, opening an Account, viewing posted Services,
              and bidding on posted Services is free. masterNAUKRI currently
              does not charge Service Users for transactions completed on the
              App between Service Users and Coach(es). However, masterNAUKRI
              reserves the right to charge a fee to Service Users in the future
              on a per-transaction basis or in any other manner, and reserves
              the right to do so in its sole discretion. Changes to this Fee
              Policy are effective after masterNAUKRI has provided you with
              thirty (30) days' notice by posting the changes on the App.
            </p>
            <p className="paragraph">
              If applicable, you agree to pay all fees or charges to your
              Account based on masterNAUKRI's fees, charges, and billing terms
              then in effect. If you do not pay on time or if masterNAUKRI
              cannot charge your credit card, any payment gateway or other
              payment method for any reason, masterNAUKRI reserves the right to
              either suspend or terminate your access to the App and Account and
              terminate these Terms of Use. You are expressly agreeing that
              masterNAUKRI is permitted to bill you for the applicable fees, any
              applicable tax and any other charges you may incur in connection
              with your use of this App and the fees will be billed to your
              credit card, a payment gateway or other payment method designated
              at the time you make a purchase or register for a fee-based
              service. If you cancel your Account at any time, you will not
              receive any refund. If you have a balance due on any account, you
              agree that masterNAUKRI may charge such unpaid fees to your credit
              card or otherwise bill you for such unpaid fees. masterNAUKRI
              reserves its right to initiate appropriate legal proceedings, if
              necessary, in its sole discretion, to recover such outstanding
              amounts.
            </p>

            <div className="text">3. TAXES</div>

            <p className="paragraph">
              You understand that we are acting solely as an intermediary for
              the collection of rents and fees between a Service User and a
              Coach who choose to enter into an Agreement for Service. Because
              state and local tax laws vary significantly by locality, you
              understand and agree that you are solely responsible for
              determining your own tax reporting requirements in consultation
              with tax advisors, and that we cannot and do not offer tax advice
              to either hosts or guests. Further, you understand that
              masterNAUKRI shall not be responsible or liable in any manner in
              relation to tax liability of a Service User or a Coach.
            </p>

            <div className="text">4. REFUND POLICY</div>

            <p className="paragraph">
              All commissions payable to masterNAUKRI by Coach(es) on
              consummated transactions, i.e. exchange of services for
              consideration, are final and non-refundable.
            </p>

            <p className="paragraph">
              All money paid by Coach(es) in form of “Credits” are non-interest
              bearing and shall be refunded to the Coach(es) if they remain
              un-utilised for a period greater than 364 days only. Such amounts
              shall not be refunded prior to that.&ZeroWidthSpace;
            </p>

            <p className="paragraph">
              If poor customer service or inappropriate behaviour with customers
              results in the professional being de-listed from the masterNAUKRI
              platform, then all credits (purchased or promotional) will have to
              forfeited, and there will be no refund made in such a case.
            </p>
          </div>
        </div>

        <div className="acceptance-terms">
          <div className="text-block">
            <div className="head">
              <div className="term-title">
                Negotiation of Terms of Service; Disputes Between Registered
                Users
              </div>
            </div>

            <div className="text">
              1. NEGOTIATION WORKSHEET AND CONTRACT TEMPLATE
            </div>
            <p className="paragraph">
              As a courtesy to Registered Users, to facilitate the negotiation
              and confirmation of the Agreement for Service, masterNAUKRI
              provides a general framework for negotiating the terms of Service
              (e.g., rate) ("Negotiation Worksheet"). Registered Users
              acknowledge and agree that (i) they are solely responsible for
              addressing all issues that exist now or may arise in the future in
              connection with the applicable Service; and (ii) it is solely up
              to such Registered Users, if they so desire, to enter into a
              signed, written contract, that addresses all of the relevant
              issues and memorializes the agreed upon Negotiation Worksheet.
            </p>

            <p className="paragraph">
              You should not rely on the any information or resources contained
              on the App, including, without limitation, the Negotiation
              Worksheet, as a replacement or substitute for any professional,
              financial, legal or other advice or counsel. masterNAUKRI makes no
              representations and warranties, and expressly disclaims any and
              all liability, concerning actions taken by a user following the
              information or using the resources offered or provided on or
              through the Apps, including, without limitation, the Negotiation
              Worksheet. In no way will masterNAUKRI be responsible for any
              actions taken or not taken based on the information or resources
              provided on this App. If you have a situation that requires
              professional advice, you should consult a qualified specialist. Do
              not disregard, avoid or delay obtaining professional advice from a
              qualified specialist because of information or resources that are
              provided on this App, however provided.
            </p>

            <div className="text">
              2. masterNAUKRI IS NOT A PARTY TO ANY SERVICE CONTRACT
            </div>

            <p className="paragraph">
              Each Registered User hereby acknowledges and agrees that
              masterNAUKRI is NOT a party to any oral or written Agreement for
              Service, Negotiation Worksheet, or any contract entered into
              between Registered Users in connection with any Service offered,
              directly or indirectly, through the App. Each Registered User
              acknowledges, agrees and understands that masterNAUKRI only seeks
              to provide a platform wherein the Service User and Coach can be
              brought together and masterNAUKRI itself has not role in the
              execution or provision of Services.
            </p>

            <div className="text">3. NO AGENCY OR PARTNERSHIP</div>

            <p className="paragraph">
              No agency, partnership, joint venture, or employment is created as
              a result of the Terms of Use or your use of any part of the App,
              including without limitation, the Negotiation Worksheet or
              Agreement for Service. You do not have any authority whatsoever to
              bind masterNAUKRI in any respect. All Coach(es) are independent
              contractors. Neither masterNAUKRI nor any users of the App may
              direct or control the day-to-day activities of the other, or
              create or assume any obligation on behalf of the other.
            </p>

            <div className="text">4. DISPUTES BETWEEN REGISTERED USERS</div>

            <p className="paragraph">
              Subject to the provisions regarding disputes between App
              participants in connection with Feedback, your interactions with
              individuals and/or organizations found on or through the App,
              including payment of and performance of any Service, and any other
              terms, conditions, warranties or representations associated with
              such transactions or dealings, are solely between you and such
              individual or organization. You should take reasonable precautions
              and make whatever investigation or inquiries you deem necessary or
              appropriate before proceeding with any online or offline
              transaction with any third party, including without limitation,
              Coach(es) and Service Users.
            </p>

            <p className="paragraph">
              You understand that deciding whether to use the Services of a
              Coach or provide Services to a Service User or use information
              contained in any Submitted Content, including, without limitation,
              Postings, Offers, Wants and/or Feedback, is your personal decision
              for which alone are responsible. You understand that masterNAUKRI
              does not warrant and cannot make representations as to the
              suitability of any individual you may decide to interact with on
              or through the App and/or the accuracy or suitability of any
              advice, information, or recommendations made by any individual.
              While masterNAUKRI may attempt to seek information about the
              background of a Coach, either via a telephonic or in-person
              interview, review of past work/customer feedback and/or check
              presence online (if available) on Facebook, personalised app,
              LinkedIn, Twitter etc., you understand that Coaches may register
              themselves suo moto. You also understand that any so called
              background check undertaken by masterNAUKRI is not exhaustive to
              the extent to determine previous criminal antecedents and hence,
              at the end of the day, the Service User should take an informed
              decision on his/her own accord and keep in mind the fact that
              masterNAUKRI only seeks to provide a platform wherein Service
              Users and Coaches have an opportunity to meet each other.
            </p>

            <p className="paragraph">
              NOTWITHSTANDING THE FOREGOING, YOU AGREE THAT SINCE masterNAUKRI
              only seeks to provide a platform wherein the Service User and
              Coach can be brought together and masterNAUKRI itself has not role
              in the execution or provision of Services ITSELF, masterNAUKRI
              SHALL NOT BE RESPONSIBLE OR LIABLE FOR ANY LOSS OR DAMAGE OF ANY
              SORT WHATSOEVER INCURRED AS THE RESULT OF ANY SUCH TRANSACTION OR
              DEALINGS. IF THERE IS A DISPUTE BETWEEN PARTICIPANTS ON THE APP,
              OR BETWEEN REGISTERED USERS OR ANY APP USER AND ANY THIRD PARTY,
              YOU ACKNOWLEDGE AND AGREE THAT masterNAUKRI IS UNDER NO OBLIGATION
              TO BECOME INVOLVED. IN THE EVENT THAT A DISPUTE ARISES BETWEEN YOU
              AND ONE OR MORE APP USERS, REGISTERED USERS OR ANY THIRD PARTY,
              YOU HEREBY RELEASE masterNAUKRI, ITS OFFICERS, MANAGERS, MEMBERS,
              DIRECTORS, EMPLOYEES, ATTORNEYS, AGENTS, AND SUCCESSORS IN RIGHTS
              FROM ANY CLAIMS, DEMANDS, AND DAMAGES (ACTUAL AND CONSEQUENTIAL)
              OF EVERY KIND OR NATURE, KNOWN OR UNKNOWN, SUSPECTED OR
              UNSUSPECTED, FORESEEABLE OR UNFORESEEABLE, DISCLOSED OR
              UNDISCLOSED, ARISING OUT OF OR IN ANY WAY RELATED TO SUCH DISPUTES
              AND/OR THE APP OR ANY SERVICE PROVIDED THEREUNDER.
            </p>
          </div>
        </div>

        <div className="acceptance-terms">
          <div className="text-block">
            <div className="head">
              <div className="term-title">Dispute Resolution</div>
            </div>

            <p className="paragraph">
              If a dispute arises between you and masterNAUKRI, our goal is to
              provide you with a neutral and cost effective means of resolving
              the dispute quickly. Accordingly, you and masterNAUKRI hereby
              agree that we will resolve any claim or controversy at law and
              equity that arises out of the Terms of Use or the App in
              accordance with this Section O or as we and you otherwise agree in
              writing. Before resorting to the filing of a formal lawsuit, we
              strongly encourage you to first contact us directly to seek a
              resolution via e-mail at info@masternaukri.com. The dispute shall
              be resolved through alternative dispute resolution procedures,
              such as mediation or arbitration, as alternatives to litigation.
            </p>

            <div className="text">1. GOVERNING LAW</div>

            <p className="paragraph">
              The Terms of Use shall be governed in all respects by the laws of
              India and any legal proceeding arising out of this Agreement will
              occur exclusively in the courts located in Guwahati, Assam, India.
            </p>
          </div>
        </div>

        <div className="acceptance-terms">
          <div className="text-block">
            <div className="head"><div className="term-title">Advertisements</div></div>

            <p className="paragraph">
              Aspects of the App and other masterNAUKRI services may be
              supported by advertising revenue. As such, masterNAUKRI may
              display advertisements and promotions on the service. The manner,
              mode and extent of advertising by masterNAUKRI on the App are
              subject to change and the appearance of advertisements on the App
              does not necessarily imply endorsement by masterNAUKRI of any
              advertised products or services. You agree that masterNAUKRI shall
              not be responsible or liable for any loss or damage of any sort
              incurred by you as a result of any such dealings or as the result
              of the presence of such advertisers on the App.
            </p>
          </div>
        </div>

        <div className="acceptance-terms">
          <div className="text-block">
            <div className="head">
              <div className="term-title">
                Third-party Links, Contact Forms and Phone Numbers
              </div>
            </div>

            <p className="paragraph">
              The App may provide, or third parties may provide, links, contact
              forms and/or phone numbers to other apps or resources, including,
              without limitation, social networking, blogging and similar apps
              through which you are able to log into the App using your existing
              account and log-in credentials for such third-party apps. Certain
              areas of the App may allow you to interact and/or conduct
              transactions with such third-party sites, and, if applicable,
              allow you to configure your privacy settings in your third-party
              site account to permit your activities on the App to be shared
              with your contacts in your third-party site account. Because
              masterNAUKRI has no control over such sites and resources, you
              acknowledge and agree that masterNAUKRI is not responsible for the
              availability of such external sites or resources, and is not
              responsible or liable for any content, advertising, products,
              goods or services on or available from such apps or resources.
              Unless expressly stated on the App, links to third-party sites
              should in no way be considered as or interpreted to be
              masterNAUKRI's endorsement of such third-party sites or any
              product or service offered through them. You further acknowledge
              and agree that masterNAUKRI shall not be responsible or liable,
              directly or indirectly, for any damage or loss caused or alleged
              to be caused by or in connection with use of or reliance on any
              such content, advertising, product, goods or services available on
              or through any such app or resource. The Third-party app may have
              different privacy policies and terms and conditions and business
              practices than masterNAUKRI. In certain situations, you may be
              transferred to a Third-party app through a link or connected to a
              Third-party resource by a contact form or phone but it may appear
              that you are still on this App or transacting with masterNAUKRI.
              In these situations, you acknowledge and agree that the
              Third-party app terms and conditions and privacy policy apply in
              this situation. Your dealings and communications through the App
              with any party other than masterNAUKRI are solely between you and
              such third party. Any complaints, concerns or questions you have
              relating to materials provided by third parties should be
              forwarded directly to the applicable third party.
            </p>
          </div>
        </div>

        <div className="acceptance-terms">
          <div className="text-block">
            <div className="head">
              <div className="term-title">Disclaimer of Warranties</div>
            </div>

            <p className="paragraph">
              YOU AGREE THAT YOUR USE OF THE APP SHALL BE AT YOUR OWN RISK. TO
              THE FULLEST EXTENT PERMITTED BY LAW, AND masterNAUKRI AND ITS
              OFFICERS, MANAGERS, MEMBERS, DIRECTORS, EMPLOYEES, SUCCESSORS,
              ASSIGNS, SUBSIDIARIES, AFFILIATES, COACH(ES), SUPPLIERS, AND
              AGENTS DISCLAIM ALL WARRANTIES, EXPRESS, IMPLIED, STATUTORY OR
              OTHERWISE, AND MAKE NO WARRANTIES, REPRESENTATIONS, OR GUARANTEES
              IN CONNECTION WITH THIS APP, THE SERVICES OFFERED ON OR THROUGH
              THIS APP, ANY DATA, MATERIALS, SUBMITTED CONTENT, RELATING TO THE
              QUALITY, SUITABILITY, TRUTH, ACCURACY OR COMPLETENESS OF ANY
              INFORMATION OR MATERIAL CONTAINED OR PRESENTED ON THIS APP,
              INCLUDING WITHOUT LIMITATION THE MATERIALS, DATA AND SUBMITTED
              CONTENT OF OTHER USERS OF THIS SITE OR OTHER THIRD PARTIES. UNLESS
              OTHERWISE EXPLICITLY STATED, TO THE MAXIMUM EXTENT PERMITTED BY
              APPLICABLE LAW, THIS APP, THE SERVICES OFFERED ON OR THROUGH THIS
              APP, DATA, MATERIALS, SUBMITTED CONTENT, AND ANY INFORMATION OR
              MATERIAL CONTAINED OR PRESENTED ON THIS APP IS PROVIDED TO YOU ON
              AN "AS IS," "AS AVAILABLE" AND "WHEREIS" BASIS WITH NO WARRANTY OF
              IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE, OR NON-INFRINGEMENT OF THIRD-PARTY RIGHTS. masterNAUKRI
              DOES NOT PROVIDE ANY WARRANTIES AGAINST ERRORS, MISTAKES, OR
              INACCURACIES OF DATA, CONTENT, INFORMATION, MATERIALS, SUBSTANCE
              OF THE APP OR SUBMITTED CONTENT, ANY UNAUTHORIZED ACCESS TO OR USE
              OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
              AND/OR FINANCIAL INFORMATION STORED THEREIN, ANY BUGS, VIRUSES,
              TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH
              THE APP BY ANY THIRD PARTY, ANY INTERRUPTION OR CESSATION OF
              TRANSMISSION TO OR FROM THE APP, ANY DEFAMATORY, OFFENSIVE, OR
              ILLEGAL CONDUCT OF ANY THIRD PARTY OR SERVICE USER OR SERVICE
              PROVIDER, OR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT
              OF THE USE OF ANY DATA, CONTENT, INFORMATION, MATERIALS, SUBSTANCE
              OF THE APP OR SUBMITTED CONTENT POSTED, EMAILED, TRANSMITTED, OR
              OTHERWISE MADE AVAILABLE VIA THE APP. masterNAUKRI DOES NOT
              ENDORSE, WARRANT, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
              PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH
              THE APP OR ANY HYPERLINKED SITE OR FEATURED IN ANY BANNER OR OTHER
              ADVERTISEMENT. masterNAUKRI WILL NOT BE A PARTY TO OR IN ANY WAY
              BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
              PARTY, INCLUDING THIRD PARTY COACH(ES) OF PRODUCTS OR SERVICES. AS
              WITH THE USE OF ANY PRODUCT OR SERVICE, AND THE PUBLISHING OR
              POSTING OF ANY MATERIAL THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT,
              YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE
              APPROPRIATE.ve relating to materials provided by third parties
              should be forwarded directly to the applicable third party.
            </p>
          </div>
        </div>

        <div className="acceptance-terms">
          <div className="text-block">
            <div className="head">
              <div className="term-title">Limitations of Liability</div>
            </div>

            <p className="paragraph">
              1. IN NO EVENT SHALL masterNAUKRI, OR ITS RESPECTIVE OFFICERS,
              MANAGERS, MEMBERS, DIRECTORS, EMPLOYEES, SUCCESSORS, ASSIGNS,
              SUBSIDIARIES, AFFILIATES, COACHES, SUPPLIERS, ATTORNEYS OR AGENTS,
              BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
              PUNITIVE, CONSEQUENTIAL OR EXEMPLARY DAMAGES (INCLUDING BUT NOT
              LIMITED TO LOSS OF BUSINESS, REVENUE, PROFITS, USE, DATA OR OTHER
              ECONOMIC ADVANTAGE) WHATSOEVER RESULTING FROM ANY (I) ACCESS TO OR
              USE OF THE APP OR ANY SERVICES OFFERED BY ANY COACHES VIA THE APP,
              INCLUDING SERVICES PROVIDED PURSUANT TO AN AGREEMENT FORMED
              INDEPENDENTLY OF THE APP, WHETHER OR NOT AN AGREEMENT FOR SERVICE
              FORMED VIA THE APP IS IN EFFECT; (II) ERRORS, MISTAKES, OR
              INACCURACIES OF DATA, MARKS, CONTENT, INFORMATION, MATERIALS OR
              SUBSTANCE OF THE APP OR SUBMITTED CONTENT; (III) ANY UNAUTHORIZED
              ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL
              INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN; (IV) ANY
              BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED
              TO OR THROUGH THE APP BY ANY THIRD PARTY; (V) ANY INTERRUPTION OR
              CESSATION OF TRANSMISSION TO OR FROM THE APP; (VI) ANY ERRORS OR
              OMISSIONS IN ANY DATA, CONTENT, INFORMATION, MATERIALS OR
              SUBSTANCE OF THE APP OR SUBMITTED CONTENT; (VII) ANY FAILED
              NEGOTIATIONS FOR A SERVICE, ANY DISPUTES THAT ARISE DURING OR
              AFTER THE NEGOTIATION OF A SERVICE OR THE FORMATION OF A CONTRACT
              FOR A SERVICE, OR ANY OTHER DISPUTE THAT ARISES BETWEEN USERS OF
              THE APP; (VIII) ANY DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF
              ANY THIRD PARTY OR SERVICE USER OR COACH; OR (IX) ANY USE OF ANY
              DATA, MARKS, CONTENT, INFORMATION, MATERIALS OR SUBSTANCE OF THE
              APP OR SUBMITTED CONTENT POSTED, EMAILED, TRANSMITTED, OR
              OTHERWISE MADE AVAILABLE ON OR THROUGH THE APP, WHETHER BASED ON
              WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER
              LEGAL THEORY, AND WHETHER OR NOT masterNAUKRI IS ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES. THE FOREGOING LIMITATION OF LIABILITY
              SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE
              APPLICABLE JURISDICTION.
            </p>
            <p className="paragraph">
              2. IN NO EVENT SHALL THE TOTAL, AGGREGATE LIABILITY OF
              masterNAUKRI, OR ANY OF THE ABOVE-REFERENCED RESPECTIVE PARTIES,
              ARISING FROM OR RELATING TO THE APP, AND/OR SUBMITTED CONTENT
              EXCEED THE TOTAL AMOUNT OF FEES ACTUALLY PAID TO masterNAUKRI BY
              YOU HEREUNDER.<br />
              YOU HEREBY ACKNOWLEDGE AND AGREE THAT masterNAUKRI SHALL NOT BE
              LIABLE FOR SUBMITTED CONTENT OR THE DEFAMATORY, OFFENSIVE, OR
              ILLEGAL CONDUCT OF ANY THIRD PARTY OR SERVICE USER OR SERVICE
              PROVIDER AND THAT THE RISK OF HARM OR DAMAGE FROM THE FOREGOING
              RESTS ENTIRELY WITH YOU. YOU FURTHER ACKNOWLEDGE AND AGREE THAT
              masterNAUKRI SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT,
              INCIDENTAL, SPECIAL, PUNITIVE, CONSEQUENTIAL OR EXEMPLARY DAMAGES
              (INCLUDING BUT NOT LIMITED TO LOSS OF BUSINESS, REVENUE, PROFITS,
              USE, DATA OR OTHER ECONOMIC ADVANTAGE) WHATSOEVER RESULTING FROM
              OR RELATING TO ANY CONTRACT BETWEEN APP USERS ENTERED INTO
              INDEPENDENTLY OF THE APP.<br />
              THE APP MAY CONTAIN LINKS TO THIRD-PARTY APPS THAT ARE NOT OWNED
              OR CONTROLLED BY masterNAUKRI. masterNAUKRI DOES NOT HAVE ANY
              CONTROL OVER, AND ASSUMES NO RESPONSIBILITY FOR, THE CONTENT,
              PRIVACY POLICIES, OR PRACTICES OF ANY THIRD-PARTY APPS. IN
              ADDITION, masterNAUKRI WILL NOT AND CANNOT CENSOR OR EDIT THE
              CONTENT OF ANY THIRD-PARTY SITE. BY USING THE APP, YOU EXPRESSLY
              RELIEVE masterNAUKRI FROM ANY AND ALL LIABILITY ARISING FROM YOUR
              USE OF ANY THIRDPARTY APP. ACCORDINGLY, PLEASE BE ADVISED TO READ
              THE TERMS AND CONDITIONS AND PRIVACY POLICY OF EACH THIRD-PARTY
              APP THAT YOU VISIT, INCLUDING THOSE DIRECTED BY THE LINKS
              CONTAINED ON THE APP.
            </p>

            <p className="paragraph">
              3. FORCE MAJEURE<br />
              Neither masterNAUKRI nor you shall be liable to the other for any
              delay or failure in performance under the Terms of Use, other than
              payment obligations, arising out of a cause beyond its control and
              without its fault or negligence. Such causes may include, but are
              not limited to fires, floods, earthquakes, strikes, unavailability
              of necessary utilities, blackouts, acts of God, acts of declared
              or undeclared war, acts of regulatory agencies, or national
              disasters.
            </p>
            <p></p>
          </div>
        </div>

        <div className="acceptance-terms">
          <div className="text-block">
            <div className="head">
              <div className="term-title">Indemnification and Release</div>
            </div>

            <p className="paragraph">
              YOU AGREE TO DEFEND, INDEMNIFY AND HOLD HARMLESS masterNAUKRI, AND
              ITS OFFICERS, MANAGERS, MEMBERS, DIRECTORS, EMPLOYEES, SUCCESSORS,
              ASSIGNS, SUBSIDIARIES, AFFILIATES, COACHES, SUPPLIERS, AND AGENTS,
              FROM AND AGAINST ANY AND ALL CLAIMS, DAMAGES, OBLIGATIONS, LOSSES,
              LIABILITIES, COSTS OR DEBT, AND EXPENSES (INCLUDING BUT NOT
              LIMITED TO ATTORNEYS' FEES) ARISING FROM YOUR USE OF, ACCESS TO,
              AND PARTICIPATION IN THE APP; YOUR VIOLATION OF ANY PROVISION OF
              THE TERMS OF USE, INCLUDING THE PRIVACY POLICY; YOUR VIOLATION OF
              ANY THIRD-PARTY RIGHT, INCLUDING WITHOUT LIMITATION ANY COPYRIGHT,
              PROPERTY, PROPRIETARY, INTELLECTUAL PROPERTY, OR PRIVACY RIGHT; OR
              ANY CLAIM THAT YOUR SUBMITTED CONTENT CAUSED DAMAGE TO A THIRD
              PARTY. THIS DEFENSE AND INDEMNIFICATION OBLIGATION WILL SURVIVE
              THESE TERMS OF SERVICE AND YOUR USE OF THE APP.
            </p>

            <p className="paragraph">
              IF YOU HAVE A DISPUTE WITH ONE OR MORE APP USERS, YOU FOREVER
              RELEASE masterNAUKRI (AND ITS OFFICERS, MANAGERS, MEMBERS,
              DIRECTORS, EMPLOYEES, SUCCESSORS, ASSIGNS, SUBSIDIARIES,
              AFFILIATES, COACHES, SUPPLIERS, AGENTS, SUBSIDIARIES, AND
              EMPLOYEES) FROM ANY AND ALL CLAIMS, DEMANDS AND DAMAGES (ACTUAL
              AND CONSEQUENTIAL) OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN,
              ARISING OUT OF OR IN ANY WAY CONNECTED WITH YOUR USE OF THE APP
              AND/OR ANY SUBMITTED CONTENT.
            </p>

            <p className="paragraph">
              THE TERMS OF USE, AND ANY RIGHTS AND LICENSES GRANTED HEREUNDER,
              MAY NOT BE TRANSFERRED OR ASSIGNED BY YOU, BUT MAY BE ASSIGNED BY
              masterNAUKRI WITHOUT RESTRICTION.
            </p>
          </div>
        </div>

        <div className="acceptance-terms">
          <div className="text-block">
            <div className="head">
              <div className="term-title">No Third-party Beneficiaries</div>
            </div>

            <p className="paragraph">
              You agree that, except as otherwise expressly provided in the
              Terms of Use, there shall be no third-party beneficiaries to the
              Terms of Use.
            </p>
          </div>
        </div>

        <div className="acceptance-terms">
          <div className="text-block">
            <div className="head"><div className="term-title">Notice</div></div>

            <p className="paragraph">
              ou agree that masterNAUKRI may provide you with notices, including
              those regarding changes to the Terms of Use, by email, regular
              mail, or postings on the App.
            </p>
          </div>
        </div>

        <div className="acceptance-terms">
          <div className="text-block">
            <div className="head">
              <div className="term-title">General Information</div>
            </div>

            <div className="text">1. ENTIRE TERMS OF USE</div>
            <p className="paragraph">
              The Terms of Use, together with the Privacy Policy and any other
              legal notices or Additional Policies published by masterNAUKRI on
              the App, shall constitute the entire agreement between you and
              masterNAUKRI concerning the App. If any provision of the Terms of
              Use is deemed invalid by a court of competent jurisdiction, the
              invalidity of such provision shall not affect the validity of the
              remaining provisions of the Terms of Use, which shall remain in
              full force and effect.&ZeroWidthSpace;
            </p>

            <p className="paragraph">
              No waiver of any provision of this these Terms of Use shall be
              deemed a further or continuing waiver of such term or any other
              term, and masterNAUKRI's failure to assert any right or provision
              under these Terms of Use shall not constitute a waiver of such
              right or provision.
            </p>

            <div className="text">2. STATUTE OF LIMITATIONS</div>

            <p className="paragraph">
              You agree that any cause of action arising out of or related to
              the App must commence within one (1) year after the cause of
              action accrues. Otherwise, such cause of action is permanently
              barred.
            </p>

            <div className="text">3. SECTION HEADINGS</div>

            <p className="paragraph">
              The section headings in the Terms of Use are for convenience only
              and have no legal or contractual effect.
            </p>
          </div>
        </div>
      </div>
    </Base>
  )
}

export default Tnc