import { combineReducers } from "redux";
import { LOGOUT } from "../Actions/types";

import auth from "./auth";
import champions from "./champions";
import quiz from "./quiz";
import dashboard from "./dashboard";
import user from "./user";
import digiProfile from "./digiProfile";

const appReducer = combineReducers({
  auth,
  dashboard,
  champions,
  quiz,
  user,
  digiProfile
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = {}
  };
  return appReducer(state, action);
};

export default rootReducer;