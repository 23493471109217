import React, { useState, useEffect } from "react";
import DatePicker from 'react-date-picker';
import { useDispatch, useSelector } from "react-redux";
import { getCityList } from "../../../Redux/Actions/dashboard";
import { getRegPermission, registerKid } from "../../../Redux/Actions/champions";
import { getUser, updateUser } from "../../../Redux/Actions/user";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const trialStyle = {
  color: "red",
  textAlign: "center",
};

const RegisterStepThree = (props) => {

  const { competitionId } = props;

  const dispatch = useDispatch();

  const userData = useSelector(state => state.user.userData);
  const user = useSelector(state => state.auth.user);
  const kids = useSelector(state => state.dashboard.kids);
  const citites = useSelector(state => state.dashboard.cities);

  const [formData, setFormData] = useState({ name: userData?.user?.name, city: userData?.user?.city, emailId: userData?.user?.emailId, dob: userData?.user?.dob });

  const [success, setSuccess] = useState("");
  const [successDob, setDobSuccess] = useState("");
  const [successCity, setCitySuccess] = useState("");
  const [failMsg, setFailMsg] = useState("");

  const handleInputChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

  useEffect(() => {
    if (!citites?.length) dispatch(getCityList());
    if (!userData?.user?.name) dispatch(getUser())
    // dispatch(getUser(data => { setFormData({ name: data?.name, city: data?.city, emailId: data?.emailId, dob: data?.dob }) }))
  }, [])

  const narcSubmitOne = (e) => {
    e.preventDefault();
    if (
      formData?.name === "" ||
      formData?.name === undefined
    ) {
      setSuccess("Please enter full name");
    } else if (
      formData?.name !== "" &&
      checkCharacterValidation(formData?.name) === false
    ) {
      setSuccess("Format inconsistent");
    } else {
      setSuccess("");
    }

    if (
      formData?.name === "" ||
      formData?.name === undefined
    ) {
      setSuccess("Please enter full name");
    }

    if (
      formData?.dob === null ||
      formData?.dob === "" ||
      formData?.dob === undefined
    ) {
      setDobSuccess("Please enter dob");
    } else if (formData?.dob === null) {
      setDobSuccess("Please enter dob");
    }

    if (
      formData?.city === "" ||
      formData?.city === undefined
    ) {
      setCitySuccess("Please enter city");
    } else {
      setCitySuccess("");
    }
    if (
      formData?.dob !== "" &&
      formData?.city !== "" && formData?.emailId !== "" &&
      checkCharacterValidation(formData?.name) === true
    ) {
      register_kid();
    }
  };

  const checkCharacterValidation = (name) => {
    let regex = /^[A-Za-z ]+$/;
    let isValid = regex.test(name);
    return isValid;
  }

  const register_kid = () => {
    dispatch(updateUser(formData, false));
    dispatch(getRegPermission({ competition_id: competitionId, kid_id: kids?.[0]?.id }, (res) => {
      handleNextForm(res);
    }))
  }

  const handleNextForm = (data) => {
    const { open } = data;
    if (open) {
      const postData = {
        competition_id: competitionId,
        kid_id: kids?.[0]?.id,
        email_id: user?.emailId,
        city: kids?.[0]?.city,
        registration_type: "OPEN",
      }
      dispatch(registerKid(postData, () => {
        props.onComplete({ step: 4, formData: { kidId: kids?.[0]?.id } })
      }))
    } else {
      setFailMsg("Registration not allowed!");
      setTimeout(() => { setFailMsg(""); }, 8000);
    }
  }

  const handleDateChange = date => {
    setFormData({ ...formData, dob: date })
  }

  return (
    <div className="register-new-child card p-4">
      <div className="card-body">
        <h4><strong>BASIC DETAILS</strong></h4>

        <form onSubmit={narcSubmitOne} className="form stepThree mt-3">
          {success && <div className="msg error">{success}</div>}
          <div className="form-group mb-3">
            <label>Name<span className="text-danger">*</span></label>
            <input
              className="form-control mt-1"
              type="text"
              name="name"
              value={formData?.name}
              onChange={handleInputChange}
              placeholder="Enter full name"
            />
          </div>
          <div className="form-group mb-3">
            <label>City  of residence<span className="text-danger">*</span></label>
            <select name="city" value={formData?.city} onChange={handleInputChange} className="form-select">
              <option selected>Select your city of residence</option>
              {citites?.map((item) => <option key={item.id} value={item.appString}>{item.appString}</option>)}
            </select>
          </div>
          <div className="form-group mb-3">
            <label>Email Id<span className="text-danger">*</span></label>
            <input type="text" name="emailId" value={formData?.emailId} onChange={handleInputChange} className="form-control" placeholder="Your email ID" />
          </div>
          <div className="form-group mb-3">
            <label>Date of birth<span className="text-danger">*</span></label>
            <DatePicker
              className="form-control"
              name="dob"
              onChange={handleDateChange}
              value={formData?.dob && moment(formData?.dob).format("YYYY-MM-DD")}
              defaultValue={new Date(moment().subtract(18, 'years'))}
              maxDate={new Date(moment().subtract(18, 'years'))}
              format="dd/MM/y"
            />
          </div>
          {failMsg && <div className="msg error" style={trialStyle}>{failMsg}</div>}
          <div className="submitMe">
            <button className="btn start-btn" disabled={!formData?.name || !formData?.dob || !formData.city || !formData?.emailId}>
              Continue
            </button>
          </div>
          <div className="form-pagination mt-2 text-center">
            <ul>
              <li className="active"></li>
              <li></li>
            </ul>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegisterStepThree;
