import React from 'react'
import ReactPlayer from 'react-player'

const MultipleChoice = ({ questionData, quizLocalData, handleAnswer, answer, setAnswer }) => {

    return (
        <div className="answers-type-content">
            {questionData?.options?.map((item, index) => {
                if (item.image_urls && item.video_urls) {
                    return (<div key={index} className="card answers-type-card answers-img-type-card answer-video-card">
                        <input
                            type="checkbox"
                            value={item?.option_id}
                            disabled={quizLocalData?.continue_button || quizLocalData?.summary_button}
                            onChange={(e) => handleAnswer(e, questionData?.is_single_choice)}
                            checked={answer.indexOf(item?.option_id) >= 0 ? true : false}
                            className="btn-check"
                            name="answer" id={item?.option_id}
                            autoComplete="off"
                        />
                        <label className="btn" htmlFor={item?.option_id}>
                            {item?.option_text}
                            <img className="text-ans-img" src={item.image_urls} alt="help_image" />
                            <div className="answer-video-player">
                                <ReactPlayer
                                    url={item.video_urls}
                                    controls={true}
                                />
                            </div>
                        </label>
                    </div>)
                } else if (item.image_urls) {
                    return (
                        <div key={index} className="card answers-type-card answers-img-type-card answer-video-card">
                            <input
                                type="checkbox"
                                value={item?.option_id}
                                disabled={quizLocalData?.continue_button || quizLocalData?.summary_button}
                                onChange={(e) => handleAnswer(e, questionData?.is_single_choice)}
                                checked={answer.indexOf(item?.option_id) >= 0 ? true : false}
                                className="btn-check" name="answer"
                                id={item?.option_id}
                                autoComplete="off"
                            />
                            <label className="btn" htmlFor={item?.option_id}>
                                {item?.option_text}
                                <img className="text-ans-img" src={item.image_urls} alt="help_image" />
                            </label>
                        </div>)
                } else if (item.video_urls) {
                    return (
                        <div key={index} className="card answers-type-card answers-img-type-card answer-video-card">
                            <input
                                type="checkbox"
                                value={item?.option_id}
                                disabled={quizLocalData?.continue_button || quizLocalData?.summary_button}
                                onChange={(e) => handleAnswer(e, questionData?.is_single_choice)}
                                checked={answer.indexOf(item?.option_id) >= 0 ? true : false}
                                className="btn-check"
                                name="answer"
                                id={item?.option_id}
                                autoComplete="off"
                            />
                            <label className="btn" htmlFor={item?.option_id}>
                                {item?.option_text}
                                <div className="answer-video-player">
                                    <ReactPlayer
                                        url={item.video_urls}
                                        controls={true}
                                    />
                                </div>
                            </label>
                        </div>)
                } else {
                    return (
                        <div className="card answers-type-card answers-img-type-card answer-video-card" key={index}>
                            <input
                                type="checkbox"
                                value={item?.option_id}
                                disabled={quizLocalData?.continue_button || quizLocalData?.summary_button}
                                onChange={(e) => handleAnswer(e, questionData?.is_single_choice)}
                                checked={answer.indexOf(item?.option_id) >= 0 ? true : false}
                                className="btn-check" name="answer"
                                id={item?.option_id}
                                autoComplete="off"
                            />
                            <label className="btn" htmlFor={item?.option_id}>{item?.option_text}</label>
                        </div>
                    )
                }
            })}
            <div className="response-sec">
                <a className={`clear-response ${(quizLocalData?.continue_button || quizLocalData?.summary_button) ? 'pe-none inactive' : 'pe-auto active'}`} onClick={() => setAnswer([])}>Clear response</a>
            </div>
        </div>
    )
}

export default MultipleChoice