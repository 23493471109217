import React, { useRef, useState } from 'react'
import DigitalPortfolio from './DigitalPortfolio';
import WorkAndEducation from './WorkAndEducation';
import { useSelector } from 'react-redux';

const TabData = () => {

  const tabDivRef = useRef(null);

  const profile = useSelector(state => state.digiProfile.data);
  const [activeTab, setActiveTab] = useState(0);

  const handleClick = (index) => {
    setActiveTab(index);
  }

  const scrollToTabs = () => {
    if (tabDivRef.current) {
      tabDivRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <div className='tabs-data'>
        <div className="tabs-compititions d-flex align-items-center justify-content-center text-center flex-wrap">
          <div className={`tab  me-2 d-flex align-items-center justify-content-center ${activeTab === 0 ? 'active' : ''}`} onClick={() => handleClick(0)}>Digital Portfolio</div>
          {profile?.college_id === 1 && <div className={`tab  d-flex align-items-center justify-content-center ${activeTab === 1 ? 'active' : ''}`} onClick={() => handleClick(1)}>Work and Education</div>}
        </div>
        <div className="tab-content-compititions">
          {activeTab === 0 && <DigitalPortfolio scrollToTabs={scrollToTabs} />}
          {activeTab === 1 && <WorkAndEducation scrollToTabs={scrollToTabs} />}
        </div>
      </div>
    </>
  )
}

export default TabData