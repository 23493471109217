import React from 'react'
import ReactPlayer from 'react-player'
import correct from "../../../Assets/images/correct.svg";
import incorrect from "../../../Assets/images/incorrect.svg";

const SingleChoice = ({ questionData, quizLocalData, handleAnswer, answer, setAnswer }) => {

    return (
        <div className="answers-type-content">
            {questionData?.options?.map((item, index) => {
                if (item.image_urls && item.video_urls) {
                    return (<div key={index} className={`card answers-type-card answers-img-type-card answer-video-card ${quizLocalData?.correct_options != null && quizLocalData?.correct_options == false && answer.indexOf(item?.option_id) >= 0 && 'selected-incorrect'}`}>
                        <input
                            type="checkbox"
                            value={item?.option_id}
                            disabled={quizLocalData?.continue_button || quizLocalData?.summary_button}
                            onChange={(e) => handleAnswer(e, questionData?.is_single_choice)}
                            checked={answer.indexOf(item?.option_id) >= 0 ? true : false}
                            className="btn-check"
                            name="answer" id={item?.option_id}
                            autoComplete="off"
                        />
                        <label className={`${(quizLocalData?.correct_options != null && quizLocalData?.correct_options == true && answer.indexOf(item?.option_id) >= 0 || quizLocalData?.answer_description?.option_ids?.indexOf(item?.option_id) >= 0) && 'selected-correct'} ${quizLocalData?.correct_options == null && 'selected'} label-btn`} htmlFor={item?.option_id}>
                            {item?.option_text}
                            <img className="text-ans-img" src={item.image_urls} />
                            <div className="answer-video-player">
                                <ReactPlayer
                                    url={item.video_urls}
                                    controls={true}
                                />
                            </div>
                        </label>
                        {(quizLocalData?.correct_options != null && (quizLocalData?.continue_button || quizLocalData?.show_complete_button)) && answer.indexOf(item?.option_id) >= 0 &&
                            <div className={`${quizLocalData?.correct_options ? 'correct' : 'incorrect'} text-center py-2`}>
                                <img className="mx-2" src={`${quizLocalData?.correct_options ? correct : incorrect}`} alt={`${quizLocalData?.correct_options ? 'correct' : 'incorrect'}`} />
                                <div className="d-inline">{quizLocalData?.correct_options ? 'You are correct' : 'That’s incorrect'}</div>
                            </div>
                        }
                        {quizLocalData?.correct_options != null && quizLocalData?.correct_options == false && quizLocalData?.answer_description?.option_ids?.length && quizLocalData?.answer_description?.option_ids?.indexOf(item?.option_id) >= 0 &&
                            <div className={`correct text-center py-2`}>
                                <div className="d-inline">Correct answer</div>
                            </div>
                        }
                    </div>)
                } else if (item.image_urls) {
                    return (<div key={index} className={`card answers-type-card answers-img-type-card answer-video-card ${quizLocalData?.correct_options != null && quizLocalData?.correct_options == false && answer.indexOf(item?.option_id) >= 0 && 'selected-incorrect'}`}>
                        <input
                            type="checkbox"
                            value={item?.option_id}
                            disabled={quizLocalData?.continue_button || quizLocalData?.summary_button}
                            onChange={(e) => handleAnswer(e, questionData?.is_single_choice)}
                            checked={answer.indexOf(item?.option_id) >= 0 ? true : false}
                            className="btn-check" name="answer"
                            id={item?.option_id}
                            autoComplete="off"
                        />
                        <label className={`${(quizLocalData?.correct_options != null && quizLocalData?.correct_options == true && answer.indexOf(item?.option_id) >= 0 || quizLocalData?.answer_description?.option_ids?.indexOf(item?.option_id) >= 0) && 'selected-correct'} ${quizLocalData?.correct_options == null && 'selected'} label-btn`} htmlFor={item?.option_id}>
                            {item?.option_text}
                            <img className="text-ans-img" src={item.image_urls} />
                        </label>
                        {(quizLocalData?.correct_options != null && (quizLocalData?.continue_button || quizLocalData?.show_complete_button)) && answer.indexOf(item?.option_id) >= 0 &&
                            <div className={`${quizLocalData?.correct_options ? 'correct' : 'incorrect'} text-center py-2`}>
                                <img className="mx-2" src={`${quizLocalData?.correct_options ? correct : incorrect}`} alt={`${quizLocalData?.correct_options ? 'correct' : 'incorrect'}`} />
                                <div className="d-inline">{quizLocalData?.correct_options ? 'You are correct' : 'That’s incorrect'}</div>
                            </div>
                        }
                        {quizLocalData?.correct_options != null && quizLocalData?.correct_options == false && quizLocalData?.answer_description?.option_ids?.length && quizLocalData?.answer_description?.option_ids?.indexOf(item?.option_id) >= 0 &&
                            <div className={`correct text-center py-2`}>
                                <div className="d-inline">Correct answer</div>
                            </div>
                        }
                    </div>)
                } else if (item.video_urls) {
                    return (<div key={index} className={`card answers-type-card answers-img-type-card answer-video-card ${quizLocalData?.correct_options != null && quizLocalData?.correct_options == false && answer.indexOf(item?.option_id) >= 0 && 'selected-incorrect'}`}>
                        <input
                            type="checkbox"
                            value={item?.option_id}
                            disabled={quizLocalData?.continue_button || quizLocalData?.summary_button}
                            onChange={(e) => handleAnswer(e, questionData?.is_single_choice)}
                            checked={answer.indexOf(item?.option_id) >= 0 ? true : false}
                            className="btn-check"
                            name="answer"
                            id={item?.option_id}
                            autoComplete="off"
                        />
                        <label className={`${(quizLocalData?.correct_options != null && quizLocalData?.correct_options == true && answer.indexOf(item?.option_id) >= 0 || quizLocalData?.answer_description?.option_ids?.indexOf(item?.option_id) >= 0) && 'selected-correct'} ${quizLocalData?.correct_options == null && 'selected'} label-btn`} htmlFor={item?.option_id}>
                            {item?.option_text}
                            <div className="answer-video-player">
                                <ReactPlayer
                                    url={item.video_urls}
                                    controls={true}
                                />
                            </div>
                        </label>
                        {(quizLocalData?.correct_options != null && (quizLocalData?.continue_button || quizLocalData?.show_complete_button)) && answer.indexOf(item?.option_id) >= 0 &&
                            <div className={`${quizLocalData?.correct_options ? 'correct' : 'incorrect'} text-center py-2`}>
                                <img className="mx-2" src={`${quizLocalData?.correct_options ? correct : incorrect}`} alt={`${quizLocalData?.correct_options ? 'correct' : 'incorrect'}`} />
                                <div className="d-inline">{quizLocalData?.correct_options ? 'You are correct' : 'That’s incorrect'}</div>
                            </div>
                        }
                        {quizLocalData?.correct_options != null && quizLocalData?.correct_options == false && quizLocalData?.answer_description?.option_ids?.length && quizLocalData?.answer_description?.option_ids?.indexOf(item?.option_id) >= 0 &&
                            <div className={`correct text-center py-2`}>
                                <div className="d-inline">Correct answer</div>
                            </div>
                        }
                    </div>)
                } else {
                    return (
                        <div className={`card answers-type-card answers-img-type-card answer-video-card ${quizLocalData?.correct_options != null && quizLocalData?.correct_options == false && answer.indexOf(item?.option_id) >= 0 && 'selected-incorrect'}`} key={index}>
                            <input
                                type="checkbox"
                                value={item?.option_id}
                                disabled={quizLocalData?.continue_button || quizLocalData?.summary_button}
                                onChange={(e) => handleAnswer(e, questionData?.is_single_choice)}
                                checked={answer.indexOf(item?.option_id) >= 0 ? true : false}
                                className="btn-check" name="answer"
                                id={item?.option_id}
                                autoComplete="off"
                            />
                            <label className={`${(quizLocalData?.correct_options != null && quizLocalData?.correct_options == true && answer.indexOf(item?.option_id) >= 0 || quizLocalData?.answer_description?.option_ids?.indexOf(item?.option_id) >= 0) && 'selected-correct'} ${quizLocalData?.correct_options == null && 'selected'} label-btn`} htmlFor={item?.option_id}>{item?.option_text}</label>
                            {(quizLocalData?.correct_options != null && (quizLocalData?.continue_button || quizLocalData?.show_complete_button)) && answer.indexOf(item?.option_id) >= 0 &&
                                <div className={`${quizLocalData?.correct_options ? 'correct' : 'incorrect'} text-center py-2`}>
                                    <img className="mx-2" src={`${quizLocalData?.correct_options ? correct : incorrect}`} alt={`${quizLocalData?.correct_options ? 'correct' : 'incorrect'}`} />
                                    <div className="d-inline">{quizLocalData?.correct_options ? 'You are correct' : 'That’s incorrect'}</div>
                                </div>
                            }
                            {quizLocalData?.correct_options != null && quizLocalData?.correct_options == false && quizLocalData?.answer_description?.option_ids?.length && quizLocalData?.answer_description?.option_ids?.indexOf(item?.option_id) >= 0 &&
                                <div className={`correct text-center py-2`}>
                                    <div className="d-inline">Correct answer</div>
                                </div>
                            }
                        </div>
                    )
                }
            })}
            <div className="response-sec">
                <a className={`clear-response ${(quizLocalData?.continue_button || quizLocalData?.summary_button) ? 'pe-none inactive' : 'pe-auto active'}`} onClick={() => setAnswer([])}>Clear response</a>
            </div>
        </div>
    )
}

export default SingleChoice