import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Confirm } from "notiflix";
import moment from "moment";
import ActivitySubmit from "./ActivitySubmit";
import EmptyActivity from "./EmptyActivity";
import List from "../submissions/List";
import grade_EX from "../../Assets/icons/grade-ex1.png";
import grade_M from "../../Assets/icons/grade-m.png";
import grade_B from "../../Assets/icons/grade-b.png";
import grade_I from "../../Assets/icons/grade-i.png";
import grade_A from "../../Assets/icons/grade-a.png";
import grade_NA from "../../Assets/icons/grade_na.png";
import ReactPlayer from "react-player";
import { activityByName, getEventByName, getKidSubmission, resetKidSubmission, setEventKidId } from "../../Redux/Actions/champions";
import { getQuizHistory, startQuiz } from "../../Redux/Actions/quiz";
import { convertTime } from "../../helper";
import "./style.css";
import "./responsive.css"
import Base from "../../Components/Layout/Base";
import banner from "../../Assets/banner/submission.svg"

const URLRegex = /^(http|https)/;

function ActivityDetails() {

  const { event_code, activity_code } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const eventId = location.state?.event?.event_id;
  const eventData = location.state?.event;
  const activityData = location.state?.activity;

  const user = useSelector(state => state.auth.user);
  const kidId = useSelector(state => state.champions.globalKidId);
  const quizHistory = useSelector(state => state.quiz.history);
  const quizData = useSelector(state => state.quiz.quizData);
  const quizLocalData = useSelector(state => state.quiz.localData);
  const submissionsList = useSelector(state => state.champions.submissionsList);
  const event_data = useSelector(state => state.champions.event_details);
  const activity_details = useSelector(state => state.champions.activity_details);

  const eventDetails = eventData ?? event_data;
  const activity = activityData ?? activity_details;

  const [reload, setReload] = useState(0);
  const activityId = activity?.id;

  useEffect(() => {
    if (!eventData?.event_id)
      dispatch(getEventByName({ eventCode: event_code }));
  }, [])

  useEffect(() => {
    if (eventDetails?.event_id && !activityData) {
      dispatch(activityByName({ competition_id: eventDetails?.event_id, activity_code }, () => {
        const kid_id = eventDetails?.kids?.[0]?.kid_id
        if (!kidId && kid_id) {
          dispatch(setEventKidId(kid_id));
        }
      }))
    }
  }, [eventDetails])

  useEffect(() => {
    if (kidId) {
      dispatch(getKidSubmission({ activityId, competitionId: eventDetails?.event_id, isActive: true, kidId }));
      if (activity?.is_show_quiz) {
        dispatch(getQuizHistory({ kidId, activity_id: activityId, competition_id: eventDetails?.event_id }))
      }
    }
    return () => dispatch(resetKidSubmission());
  }, [reload, kidId]);

  const handleKidChange = (e) => {
    const { value } = e.target;
    if (value == "add-child") {
      navigate('/account');
    } else {
      dispatch(setEventKidId(value));
    }
  };

  const [Grade, setGrade] = useState();
  const filteredData = activity;

  const YoutubeData = filteredData?.activityDetails?.youtube;
  const DescriptionData = filteredData?.activityDetails?.description?.details;
  const GuidlinesData = filteredData?.activityDetails?.description?.guidelines;
  const banner = filteredData?.activityDetails?.mediaList[0]?.url;

  const test = document.querySelectorAll(".guidline-icon");
  const hasClass = (index) => {
    if (test[index]?.classList?.contains("active")) {
      test?.map((item) => {
        item.classList?.remove("active");
      });
      test[index]?.classList?.remove("active");
    } else {
      test?.map((item) => {
        item.classList?.remove("active");
      });
      test[index]?.classList?.add("active");
    }
  };

  const [fileData, setFileData] = useState("");
  const [showData, setShowData] = useState("d-unset");

  useEffect(() => {
    if (quizData?.acm_quiz_id && !quizData?.attempt_complete_status)
      navigate("/quiz/start", { state: { kidId } });
  }, [quizData])

  const viewSummary = (attempt_id = null) => {
    attempt_id = attempt_id ?? quizHistory?.[0]?.quiz_attempts?.[0]?.attempt_id
    navigate("/quiz/summary", {
      state: {
        kidId,
        acm_quiz_id: quizHistory?.[0]?.acm_quiz_id,
        attempt_id: attempt_id
      }
    });
  }

  const compoleteTest = () => {
    Confirm.show(
      'masterNaukri - Quiz',
      `Are you ready to complete the quiz ?`,
      'Yes',
      'No',
      function okCb() {
        dispatch(startQuiz({ kidId, acm_quiz_id: quizHistory?.[0]?.acm_quiz_id, attempt_id: quizHistory?.[0]?.incomplete_attempt_id }));
      },
      function cancelCb() { },
      {
        className: 'notiflix-confirm',
        width: '320px',
        borderRadius: '8px',
      },
    );
  }

  const startTest = () => {
    Confirm.show(
      'masterNaukri - Quiz',
      `You have ${Math.floor(quizHistory?.[0]?.quiz_time / 60)} mins to complete the quiz. Good luck!`,
      'Proceed',
      'Cancel',
      function okCb() {
        dispatch(startQuiz({ kidId, acm_quiz_id: quizHistory?.[0]?.acm_quiz_id }));
      },
      function cancelCb() { },
      {
        className: 'notiflix-confirm',
        width: '320px',
        borderRadius: '8px',
      },
    );
  }

  const checkBtnState = () => {
    if (!quizLocalData?.show_complete_button && !quizLocalData?.show_start_button && !quizLocalData?.show_retake_button) {
      return false
    }
    return true
  }

  const getSubmissionsText = () => {
    const dateDiff = moment().diff(eventDetails?.submission_start_date, 'seconds');
    if (moment().diff(eventDetails?.end_date, 'seconds') > 0 && !eventDetails?.is_quiz_only) {
      return `Submission not allowed`;
    } else if (dateDiff < 0 && !eventDetails?.is_quiz_only) {
      return `Submission will start on ${moment(eventDetails?.submission_start_date).format("Do MMM YYYY")}`;
    } else if (!eventDetails?.is_quiz_only) {
      return `Submission is live`;
    }
    return false;
  }

  return (
    <Base>
      <div className="activity-details" id="act-details">
        <div className="wrapper">
          <div className={`${!submissionsList?.submission?.length ? "banner-sec" : "banner-sec mbs-120"}`}>
            <div className="narcreg-formBanner">
              {/* {!user &&
                <div className="submisstion-text-box">
                  <div className="activity-text-container">
                    {getSubmissionsText() && <div className="activity-text-title">{getSubmissionsText()}</div>}
                  </div>
                </div>
              } */}
              {/* {!submissionsList?.is_submission_allowed?.status && !submissionsList?.submission?.length &&
                <div className="submisstion-text-box text-md-start text-sm-center">
                  <div className="activity-text-container bg-white p-2 my-2 rounded mx-auto">
                    {submissionsList?.is_submission_allowed?.message && <div className="activity-text-title fw-normal fs-5" dangerouslySetInnerHTML={{ __html: submissionsList?.is_submission_allowed?.message?.replace(/\n/g, '<br />') }} />}
                  </div>
                </div>
              } */}
              {submissionsList?.is_submission_allowed?.status && !submissionsList?.submission?.length && (
                <div className="form-frame">
                  <div className="narc-submission-form">
                    <ActivitySubmit
                      setReload={setReload}
                      activity={activity}
                      eventDetails={eventDetails}
                      activityId={activityId}
                      eventId={eventId}
                      kidId={kidId}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={`${!submissionsList?.submission?.length ? "content-sec d-block container" : "content-sec d-block container mt-5"}`}>
            <div className="tab-header">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active border-0" id="Activity-tab" data-bs-toggle="tab" data-bs-target="#Activity" type="button" role="tab" aria-controls="Activity" aria-selected="true">
                    <span className="mb-activity">Activity</span> Description
                  </button>
                </li>
                {YoutubeData?.length > 0 &&
                  <li className="nav-item" role="presentation">
                    <button className="nav-link border-0" id="watch-video-tab" data-bs-toggle="tab" data-bs-target="#watch-video" type="button" role="tab" aria-controls="watch-video" aria-selected="false">
                      Watch videos
                    </button>
                  </li>
                }
                {activity?.is_show_quiz &&
                  <li className="nav-item" role="presentation">
                    <button className="nav-link border-0" id="my-quiz-tab" data-bs-toggle="tab" data-bs-target="#my-quiz" type="button" role="tab" aria-controls="my-quiz" aria-selected="false">
                      My quiz
                    </button>
                  </li>
                }
                {activity?.submission_allowed !== false && user &&
                  <li className="nav-item" role="presentation">
                    <button className="nav-link border-0" id="submissions-tab" data-bs-toggle="tab" data-bs-target="#submissions" type="button" role="tab" aria-controls="submissions" aria-selected="false">
                      Submissions
                    </button>
                  </li>
                }
              </ul>
            </div>
            <div className="tab-content" id="myTabContent">
              <div className="tab-post-sec tab-pane fade show active" id="Activity" role="tabpanel" aria-labelledby="Activity-tab">
                <div className="container-fluid"><div className="post-banner"><img src={banner} /></div></div>
                <div className="detail-sec container-fluid">
                  <div className="detail-heading sec-heading"><h5>Details</h5></div>
                  <div className="details-list-sec">
                    <ul class="navbar-nav">
                      {DescriptionData?.map((item) => <li class="nav-item">{item}</li>)}
                    </ul>
                    {(eventDetails?.kids == null || eventDetails?.kids?.length == 0) && eventDetails?.register_as_individual && activity?.is_available_Unregistered_user == false &&
                      <div className="text-center">
                        <Link className="btn btn-theme rounded-pill py-2 px-3 reg-cstm-btn" to={`/programs/${eventDetails?.event_code}/register/individual`}>
                          Register now
                        </Link>
                      </div>
                    }
                  </div>
                </div>
                {(GuidlinesData?.length > 0 && activity?.activityDetails?.is_grading_key !== false) &&
                  <div className="guidelines-sec container-fluid detail-sec">
                    <div className="guidelines-heading sec-heading"><h5>Guidelines</h5></div>
                    <div className="guidelines-content py-3">
                      <p>{Grade ? Grade : GuidlinesData?.[0]?.description}</p>
                      <div className="grades-icons mx-auto">
                        <nav class="activity-guidlines">
                          <div class="nav nav-tabs" id="nav-tab" role="tablist" style={{ justifyContent: "left" }}>
                            {GuidlinesData?.map((item, index) => {
                              return item.grade == "EX" ? (
                                <button
                                  key={index}
                                  class={`nav-link ${index == 0 ? "active" : ""
                                    }`}
                                  id={`nav-${item.grade}-tab`}
                                  data-bs-toggle="tab"
                                  data-bs-target={`#nav-${item.grade}`}
                                  type="button"
                                  role="tab"
                                  aria-controls={`nav-${item.grade}`}
                                  aria-selected="true"
                                >
                                  <img
                                    onClick={() => {
                                      setGrade(item.description);
                                      hasClass(index);
                                    }}
                                    className={`guidline-icon active`}
                                    src={grade_EX}
                                  />
                                </button>
                              ) : item.grade == "M" ? (
                                <button
                                  class={`nav-link`}
                                  id={`nav-${item.grade}-tab`}
                                  data-bs-toggle="tab"
                                  data-bs-target={`#nav-${item.grade}`}
                                  type="button"
                                  role="tab"
                                  aria-controls={`nav-${item.grade}`}
                                  aria-selected="true"
                                >
                                  <img
                                    onClick={() => {
                                      setGrade(item.description);
                                      hasClass(index);
                                    }}
                                    className={`guidline-icon`}
                                    src={grade_M}
                                  />
                                </button>
                              ) : item.grade == "I" ? (
                                <button
                                  class={`nav-link`}
                                  id={`nav-${item.grade}-tab`}
                                  data-bs-toggle="tab"
                                  data-bs-target={`#nav-${item.grade}`}
                                  type="button"
                                  role="tab"
                                  aria-controls={`nav-${item.grade}`}
                                  aria-selected="true"
                                >
                                  <img
                                    onClick={() => {
                                      setGrade(item.description);
                                      hasClass(index);
                                    }}
                                    className={`guidline-icon`}
                                    src={grade_I}
                                  />
                                </button>
                              ) : item.grade == "A" ? (
                                <button
                                  class={`nav-link`}
                                  id={`nav-${item.grade}-tab`}
                                  data-bs-toggle="tab"
                                  data-bs-target={`#nav-${item.grade}`}
                                  type="button"
                                  role="tab"
                                  aria-controls={`nav-${item.grade}`}
                                  aria-selected="true"
                                >
                                  <img
                                    onClick={() => {
                                      setGrade(item.description);
                                      hasClass(index);
                                    }}
                                    className={`guidline-icon`}
                                    src={grade_A}
                                  />
                                </button>
                              ) : item.grade == "NA" ? (
                                <button
                                  class={`nav-link`}
                                  id={`nav-${item.grade}-tab`}
                                  data-bs-toggle="tab"
                                  data-bs-target={`#nav-${item.grade}`}
                                  type="button"
                                  role="tab"
                                  aria-controls={`nav-${item.grade}`}
                                  aria-selected="true"
                                >
                                  <img
                                    onClick={() => {
                                      setGrade(item.description);
                                      hasClass(index);
                                    }}
                                    className={`guidline-icon`}
                                    src={grade_NA}
                                  />
                                </button>
                              ) : item.grade == "B" ? (
                                <button
                                  class={`nav-link`}
                                  id={`nav-${item.grade}-tab`}
                                  data-bs-toggle="tab"
                                  data-bs-target={`#nav-${item.grade}`}
                                  type="button"
                                  role="tab"
                                  aria-controls={`nav-${item.grade}`}
                                  aria-selected="true"
                                >
                                  <img
                                    onClick={() => {
                                      setGrade(item.description);
                                      hasClass(index);
                                    }}
                                    className={`guidline-icon`}
                                    src={grade_B}
                                  />
                                </button>
                              ) : (
                                ""
                              );
                            })}
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div className="tab-pane fade" id="watch-video" role="tabpanel" aria-labelledby="watch-video-tab">
                <div className="container-fluid">
                  {YoutubeData?.map((item, index) => {
                    const videoSrc = URLRegex.test(item.url) ? `${item.url}` : `https://www.youtube.com/embed/${item.url}`;
                    return (
                      <div className="post-banner post-card" key={index}>
                        {URLRegex.test(item.url) ?
                          <ReactPlayer
                            url={videoSrc}
                            controls={true}
                            width={"100%"}
                            height="300"
                          />
                          :
                          <iframe
                            className="mobile-video"
                            src={videoSrc}
                            height="300"
                            width="100%"
                            title="Video"
                          >
                          </iframe>
                        }
                        <div className="post-card-content">
                          <h6 className="post-card-heading">{item.title}</h6>
                          <p>{item.description}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="tab-post-sec tab-pane fade show" id="my-quiz" role="tabpanel" aria-labelledby="my-quiz-tab">
                <div className="container-fluid">
                  {(quizHistory?.[0]?.quiz_attempts?.length > 0 && checkBtnState()) &&
                    <div className="quiz-header-btns">
                      <div className="nav nav-tabs" id="myTab" role="tablist">
                        <button
                          className="nav-link active"
                          id="new-attempt-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#new-attempt"
                          type="button"
                          role="tab"
                          aria-controls="new-attempt"
                          aria-selected="true"
                        >
                          New Attempt
                        </button>
                        <button
                          className="nav-link"
                          id="past-attempt-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#past-attempt"
                          type="button"
                          role="tab"
                          aria-controls="past-attempt"
                          aria-selected="false"
                        >
                          Past Attempt
                        </button>
                      </div>
                    </div>
                  }
                  <div className="tab-content quiz-content-outer">
                    <div
                      className={`tab-pane ${checkBtnState() || quizHistory?.[0]?.quiz_attempts?.length === 0 ? 'active' : ''} new-attempt-tab-content`}
                      id="new-attempt"
                      role="tabpanel"
                      aria-labelledby="new-attempt-tab"
                    >
                      <div className="quiz-detail-card-outer-sec">
                        <div className="card quiz-detail-card">
                          <div className="card-body">
                            <h5>Name of the Quiz</h5>
                            <p>{quizHistory?.[0]?.quiz_name}</p>
                          </div>
                        </div>
                        <div className="card quiz-detail-card">
                          <div className="card-body">
                            <h5>Time</h5>
                            <p>{convertTime(quizHistory?.[0]?.quiz_time)}</p>
                          </div>
                        </div>
                        <div className="card quiz-detail-card">
                          <div className="card-body">
                            <h5>No. of questions</h5>
                            <p>{quizHistory?.[0]?.total_quiz_question}</p>
                          </div>
                        </div>
                        <div className="card quiz-detail-card">
                          <div className="card-body">
                            <p dangerouslySetInnerHTML={{ __html: quizHistory?.[0]?.quiz_instructions?.split("\n\n").join("<br>") }}></p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 text-center card-bottom-btn">
                        {quizLocalData?.show_complete_button && <button type="button" onClick={compoleteTest} className="btn quiz-button-pnk-comn">Complete quiz</button>}
                        {quizLocalData?.show_start_button && <button type="button" onClick={startTest} className="btn quiz-button-pnk-comn">Start the quiz</button>}
                        {quizLocalData?.show_retake_button && <button type="button" onClick={startTest} className="btn quiz-button-pnk-comn">Retake quiz</button>}
                      </div>
                    </div>
                    <div className={`tab-pane ${checkBtnState() && quizHistory?.[0]?.quiz_attempts?.length > 0 ? '' : 'active'}`} id="past-attempt" role="tabpanel" aria-labelledby="past-attempt-tab">
                      {quizHistory?.[0]?.quiz_attempts?.length > 1 &&
                        <div className="past-attem-tab-header">
                          <ul className="nav nav-tabs" id="myTab" role="tablist">
                            {quizHistory?.[0]?.quiz_attempts?.map((item, index) => {
                              return (<li key={index} className="nav-item" role="presentation">
                                <button
                                  className={`nav-link ${index == 0 ? 'active' : ''}`}
                                  id={`attempt-${item?.attempt}-tab`}
                                  data-bs-toggle="tab"
                                  data-bs-target={`#attempt-${item?.attempt}`}
                                  type="button"
                                  role="tab"
                                  aria-controls={`attempt-${item?.attempt}`}
                                  aria-selected="true"
                                >
                                  Attempt {item?.attempt}
                                </button>
                              </li>)
                            })}
                          </ul>
                        </div>
                      }
                      {/*past-attempt tab content */}
                      <div className="tab-content  past-attempt-tab-content">
                        {quizHistory?.[0]?.quiz_attempts?.map((item, index) => {
                          return (<div
                            key={index}
                            className={`tab-pane ${index == 0 ? 'active' : ''}`}
                            id={`attempt-${item?.attempt}`}
                            role="tabpanel"
                            aria-labelledby={`attempt-${item?.attempt}-tab`}
                          >
                            <div className="score-title col-12 text-center">
                              <h4>Total Score : {item?.attempt_score}</h4>
                            </div>
                            <div className="quiz-detail-card-outer-sec">
                              <div className="card quiz-detail-card">
                                <div className="card-body">
                                  <h5>Name of the Quiz</h5>
                                  <p>{quizHistory?.[0]?.quiz_name}</p>
                                </div>
                              </div>
                              <div className="card quiz-detail-card">
                                <div className="card-body">
                                  <h5>Time</h5>
                                  <p>{convertTime(quizHistory?.[0]?.quiz_time)}</p>
                                </div>
                              </div>
                              <div className="card quiz-detail-card">
                                <div className="card-body">
                                  <h5>No. of questions</h5>
                                  <p>{quizHistory?.[0]?.total_quiz_question}</p>
                                </div>
                              </div>
                              <div className="card quiz-detail-card">
                                <div className="card-body">
                                  <p dangerouslySetInnerHTML={{ __html: quizHistory?.[0]?.quiz_instructions?.split("\n\n").join("<br>") }}></p>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 text-center card-bottom-btn">
                              <button type="button" onClick={() => viewSummary(item?.attempt_id)} className="btn quiz-button-pnk-comn">View summary</button>
                            </div>
                          </div>)
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="submissions" role="tabpanel" aria-labelledby="submissions-tab">
                {submissionsList?.submission?.length ? (
                  <List
                    data={submissionsList?.submission}
                    submissionAllowed={submissionsList?.is_submission_allowed}
                    setReload={setReload}
                    Activity={activity}
                    kidId={kidId}
                    CompId={eventId}
                  />
                ) : (
                  <div className={`submission-content`}>
                    <div className="container-fluid">
                      <EmptyActivity />
                    </div>
                  </div>
                )}
                <div className="activity-img-uploader add-img">
                  {submissionsList?.is_submission_allowed?.status && !submissionsList?.submission?.length && (
                    <ActivitySubmit
                      setReload={setReload}
                      activity={activity}
                      eventDetails={eventDetails}
                      activityId={activityId}
                      eventId={eventId}
                      kidId={kidId}
                    />)}
                </div>
                <div className={`submission-content ${showData}`}>
                  <div className="container-fluid"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Base>
  );
}

export default ActivityDetails;
