import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import './styles.css';
import { Navigation } from 'swiper/modules';
import { useSelector } from "react-redux";
import { Confirm } from "notiflix";

const ElpSlider = ({ id, events, prCard = false }) => {

  const navigate = useNavigate();

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const kids = useSelector(state => state.dashboard.kids);

  const registerNow = item => {
    if (isAuthenticated) {
      if (kids?.[0]?.name) {
        if (item?.is_quiz_only) {
          navigate(`/programs/${item?.event_code}`, { item })
        } else {
          navigate(`/programs/${item?.event_code}/register/individual`, { item })
        }
      } else {
        Confirm.show(
          'masterNaukri',
          `Please update profile to register.`,
          'Update',
          'Cancel',
          function okCb() {
            navigate('/account');
          },
          function cancelCb() { },
          {
            className: 'notiflix-confirm',
            width: '320px',
            borderRadius: '8px',
          },
        );
      }
    } else {
      if (item?.is_quiz_only) {
        navigate(`/programs/${item?.event_code}`, { item })
      } else {
        navigate(`/programs/${item?.event_code}/register/individual`, { item })
      }
    }
  }

  let crouselPostDataList = '';
  crouselPostDataList = events?.length && events?.map((item, index) => {
    return (
      <SwiperSlide key={index}>
        <div className="competitions-item-outer col-md-12 py-3 px-2">
          <Tooltip id="event-name-tooltip" place="top" offset={10} style={{ fontSize: "16px", zIndex: 9999999 }} />
          <div className="competitions-item">
            <Link to={{ pathname: `/programs/${item?.event_code}`, state: item }}>
              <img className="chmpn-slider-img" src={item?.image_url} alt="activity" />
            </Link>
            {/* {item?.is_quiz_only &&
              <div
                className={`cstm-card-title ${item?.event_name?.length > 26 ? 'cstm-text-wrap' : ''}`}
                data-tooltip-id="event-name-tooltip"
                data-tooltip-content={item?.event_name}
              >
                {item?.event_name}
              </div>
            } */}
            <div className="chmpn-slider-content flex-column gap-2 chmpn-register-select-btn p-2">
              <div className="d-flex justify-content-start align-items-center">
                <Link className="nav-link explore-nav" to={{ pathname: `/programs/${item?.event_code}`, state: item }}>
                  <span className={`${prCard && "pr-text-color"}`}>{item?.event_name}</span>
                </Link>
              </div>
              <div className="d-flex justify-content-between">
                <button onClick={() => registerNow(item)} className={`btn chmpn-pnk-btn w-auto px-2 py-0 my-1 ${prCard && "pr-card-reg"}`}>Register now</button>
                <Link className={`nav-link explore-nav ${prCard && "pr-text-color"}`} style={{ padding: "10px 0px" }} to={{ pathname: `/programs/${item?.event_code}`, state: item }}>Explore more</Link>
              </div>
              <div className="text-start">
                {item?.price == "Free"
                  ? <button type="button" className="btn btn-neon fw-600 rounded-pill py-0">Free</button>
                  : <span className="fw-normal">Starting <span className="fw-600">{item?.price}</span></span>
                }
              </div>
              {/* <RegisterButtons item={item} /> */}
              {/* <Link className="nav-link explore-nav" style={{ padding: "10px 0px" }} to={{ pathname: `/programs/${item?.event_code}`, state: item }}>
                Explore more
              </Link> */}
            </div>
          </div>
        </div>
      </SwiperSlide>
    )
  })

  return (
    <div className={`competitions-content-slider col-12 experiential-cards-list py-2 px-0`}>
      <Swiper
        rewind={true}
        slidesPerView={2.5}
        spaceBetween={9}
        breakpoints={{
          1024: {
            slidesPerView: 2.5,
            spaceBetween: 1
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 1
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 1
          },
          540: {
            slidesPerView: 1,
            spaceBetween: 1
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 2
          },
        }}
        navigation={true}
        modules={[Navigation]}
        className="mySwiper">
        {crouselPostDataList}
      </Swiper>
    </div >
  );
};

export default ElpSlider;
