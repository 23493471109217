import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

import './styles.css';

// import required modules
import { Navigation } from 'swiper/modules';

const Hiring = () => {
  return (
    <Swiper
      rewind={true}
      slidesPerView={3}
      spaceBetween={9}
      breakpoints={{
        1024: {
          slidesPerView: 3,
          spaceBetween: 1
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 1
        },
        640: {
          slidesPerView: 1,
          spaceBetween: 1
        },
        540: {
          slidesPerView: 1,
          spaceBetween: 1
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 2
        },
      }}
      navigation={true}
      modules={[Navigation]}
      className="mySwiper">
      <SwiperSlide>
        <div className="card employe-card my-5">
          <div className="header-outer">
            <div className="header card-header-first">
              <h5 className="fw-bold">Digital Interview</h5>
            </div>
          </div>
          <div className="card employe-card-inr employe-card-inr-first">
            <div className="rounded-card"></div>
            <div className="card content-card">
              <p>
                Uniquely short-video assignments to pre-screen critical
                skills.
              </p>
            </div>
            <div className="circle-card circle-card-first">
              <div className="circle-card-inr">
                <img
                  className="circle-card-icon"
                  src="/images/post-icon4.svg"
                  alt="post4"
                />
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="card employe-card my-5">
          <div className="header-outer">
            <div className="header card-header-second">
              <h5 className="fw-bold">Outsourced Evaluation</h5>
            </div>
          </div>
          <div className="card employe-card-inr employe-card-inr-second">
            <div className="rounded-card"></div>
            <div className="card content-card">
              <p>
                Digital “TA” team with functional competence to grade
                videos.
              </p>
            </div>
            <div className="circle-card circle-card-second">
              <div className="circle-card-inr">
                <img
                  className="circle-card-icon"
                  src="/images/post-icon5.svg"
                  alt="post5"
                />
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="card employe-card my-5">
          <div className="header-outer">
            <div className="header card-header-third">
              <h5 className="fw-bold">Shortlist Summary</h5>
            </div>
          </div>
          <div className="card employe-card-inr employe-card-inr-third">
            <div className="rounded-card"></div>
            <div className="card content-card">
              <p>
                “Qualified” candidates for interviews and quick closure.
              </p>
            </div>
            <div className="circle-card circle-card-third">
              <div className="circle-card-inr">
                <img
                  className="circle-card-icon"
                  src="/images/post-icon6.svg"
                  alt="post6"
                />
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
}

export default Hiring
