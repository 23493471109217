import axios from "axios";
import { USER_DATA } from "./types";
import { APIURL } from "./config";
import { Loading, Notify } from "notiflix";
import { getKidList } from "./dashboard";
import { logoutApp } from "./auth";

export const updateUser = (data, notify = true) => async dispatch => {

   const config = {
      headers: {
         'ContentType': 'application/json',
      }
   }
   try {
      Loading.standard();
      const res = await axios.put(`${APIURL}/api/entity/user`, data, config);
      Loading.remove();
      if (res?.data?.status) {
         const message = res?.data?.message ?? "User information is updated successfully."
         if (notify) Notify.success(message);
         dispatch({
            type: USER_DATA,
            payload: res?.data?.body
         });
         dispatch(getKidList());
      } else if (res?.data?.message) {
         Notify.warning(res?.data?.message);
      } else {
         Notify.warning("something went wrong");
      }

   } catch (err) {
      Loading.remove();
      const message = err?.response?.data?.message ?? "internal server error";
      Notify.failure(message);
   }
}

export const getUser = (cb) => async dispatch => {

   const config = {
      headers: {
         'ContentType': 'application/json',
      },
      data: {}
   }
   try {
      const res = await axios.get(`${APIURL}/api/entity/user`, config);
      if (res?.data?.status) {
         dispatch({
            type: USER_DATA,
            payload: res?.data?.body
         });
         if (cb) cb(res?.data?.body?.user);
      } else if (res?.data?.message) {
         Notify.warning(res?.data?.message);
      } else {
         Notify.warning("something went wrong");
      }

   } catch (err) {
      const message = err?.response?.data?.message ?? "internal server error";
      Notify.failure(message);
   }
}

export const deleteUser = (data, notify = true) => async dispatch => {

   const config = {
      headers: {
         'ContentType': 'application/json',
      }
   }
   try {
      Loading.standard();
      const res = await axios.post(`${APIURL}/api/entity/user/delete`, {}, config);
      Loading.remove();
      if (res?.data?.status) {
         const message = res?.data?.message ?? "Account is deleted successfully."
         if (notify) Notify.success(message);
         dispatch(logoutApp());
      } else if (res?.data?.message) {
         Notify.warning(res?.data?.message);
      } else {
         Notify.warning("something went wrong");
      }

   } catch (err) {
      Loading.remove();
      const message = err?.response?.data?.message ?? "internal server error";
      Notify.failure(message);
   }
}