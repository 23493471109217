import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Notify } from "notiflix";
import { MEDIAPATH } from "../../Redux/Actions/config";
import { validateMobile } from "../../helper";
import { authenticate, handleLoginModal } from "../../Redux/Actions/auth";
import "./form.css";

const SendOTP = ({ setStep, embedded }) => {

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({ mobile: "" });

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = () => {
    const { mobile } = formData;
    if (validateMobile(mobile)) {
      dispatch(authenticate({ mobile }, () => { setStep(2) }));
    } else {
      Notify.warning("Kindly enter valid 10 digit number");
    }
  };

  const handleClose = () => {
    dispatch(handleLoginModal(false));
  }

  return (
    <div id="login-form" className="layout-row">
      <div className="modal-body">
        {!embedded &&
          <span className="close-login-model position-absolute">
            <button onClick={handleClose} type="button" className="btn-close"></button>
          </span>
        }
        <div className="row multi-step-kidex-login">
          <div className="col-md-12">
            <div className="d-grid vertical-middle px-2">
              {!embedded &&
                <div className="d-flex justify-content-end indialogo-image ">
                  <img src={`${MEDIAPATH}/skin/images/login/indiaLogo.png`} alt="india logo" />
                </div>
              }
              <h4 className="d-flex justify-content-center align-items-center gap-2">
                <span className="loginForm-main-heading theme-text-color">We are</span>
                <img src={`/images/logo.svg`} alt="kidex" />
              </h4>
              <h3 className="text-center login-text pb-2 fw-500">Log In</h3>
              <div className="user_form_field kidex-login ">
                <div className="input-group mb-3">
                  <span className="input-group-text bg-white" id="basic-addon1">+91</span>
                  <input
                    className="form-control shadow-none"
                    placeholder="Enter 10 digit mobile number"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    type="tel"
                    name="mobile"
                    value={formData?.mobile}
                    autoFocus
                    onChange={(e) => onChange(e)}
                    pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                    maxlength="10"
                  />
                </div>
                <div className="d-grid gap-2">
                  <button onClick={() => onSubmit()} className="multiform-submit-button text-center shadow-none">Continue</button>
                </div>
              </div>
              <p className="loginForm-footer-title text-center pt-1">
                By continuing, you agree to our <a href={"/tnc"} onClick={handleClose}> Terms</a> and <a href={"/ppc"} onClick={handleClose}> Privacy policy</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SendOTP;
