import { AUTH_ERROR, LOGIN_MODAL, LOGIN_SUCCESS, LOGOUT, OTP_SENT, QUIZ_FORM_DATA, USER_DATA, USER_LOADED } from "../Actions/types";

const initialState = {
  token: null,
  isAuthenticated: null,
  loading: true,
  user: {},
  mobile: null,
  showLoginModal: false,
  quizFormData: {}
};

function auth(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_MODAL:
      return { ...state, showLoginModal: payload }
    case USER_LOADED:
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: payload,
        token: payload?.token,
        isAuthenticated: true,
        loading: false
      };
    case OTP_SENT:
      return {
        ...state,
        isAuthenticated: false,
        mobile: payload,
      };
    case AUTH_ERROR:
    case LOGOUT:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null
      };
    case USER_DATA:
      return { ...state, user: { ...state?.user, ...payload } }
    case QUIZ_FORM_DATA:
      return { ...state, quizFormData: payload }
    default:
      return state;
  }
}

export default auth;