import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from 'react-date-picker';
import moment from "moment";
import { getRegistrationPermission, registerKid } from "../../Redux/Actions/champions";
import { getUser, updateUser } from "../../Redux/Actions/user";
import { getCityList } from "../../Redux/Actions/dashboard";
import { useNavigate } from "react-router-dom";

const trialStyle = {
  color: "red",
  textAlign: "center",
};

const ChildDetailForm = (props) => {

  const stepHistory = props?.stepHistory?.indexOf(11) > -1 ? [...props.stepHistory] : [...props.stepHistory, 11]

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(state => state.auth.user);
  const kids = useSelector(state => state.dashboard.kids);
  const citites = useSelector(state => state.dashboard.cities);

  const [formData, setFormData] = useState();
  const [failMsg, setFailMsg] = useState("");


  useEffect(() => {
    dispatch(getCityList());
    dispatch(getUser(data => {
      setFormData({
        name: data?.name,
        city: data?.city,
        emailId: data?.emailId,
        dob: data?.dob
      })
    }))
  }, [])

  const handleInputChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, city, emailId, dob } = formData;
    if (name && city && emailId && dob) {
      dispatch(updateUser(formData, false));
      dispatch(getRegistrationPermission({ competition_id: props?.competitionId, kid_id: kids?.[0]?.id }, (res) => {
        if (res == "Participant already registered for the competition") {
          navigate(`/programs/${props?.event_code}`);
        } else {
          handleNextForm(res);
        }
      }))
    } else {
      setFailMsg("All fields are required!")
      setTimeout(() => { setFailMsg("") }, 3000);
    }
  }

  const handleNextForm = (data) => {
    const { open, paid } = data;
    if (open) {
      const postData = {
        competition_id: props?.competitionId,
        kid_id: kids?.[0]?.id,
        email_id: user?.emailId,
        city: kids?.[0]?.city,
        registration_type: "OPEN",
      }
      dispatch(registerKid(postData, () => {
        props.onComplete({ step: 9, formData: {}, stepHistory })
      }))
    } else if (paid) {
      props.onComplete({ step: 11, formData: {}, stepHistory })
    } else {
      setFailMsg("Registration is not allowed");
      setTimeout(() => { setFailMsg("") }, 8000);
    }
  }

  const handleDateChange = date => {
    setFormData({ ...formData, dob: date })
  }

  return (
    <div className="formSection">
      <div className="head"><div className="title">Basic details</div></div>
      <form onSubmit={handleSubmit} className="form stepThree">
        <div className="formGroup">
          <label>Name<span className="text-danger">*</span></label>
          <input type="text" name="name" value={formData?.name} onChange={handleInputChange} className="form-control" placeholder="Your name" />
        </div>
        <div className="formGroup">
          <label>City  of residence<span className="text-danger">*</span></label>
          <select name="city" value={formData?.city} onChange={handleInputChange} className="form-select">
            <option selected>Select your city of residence</option>
            {citites?.map((item) => <option key={item.id} value={item.appString}>{item.appString}</option>)}
          </select>
        </div>
        <div className="formGroup">
          <label>Email Id<span className="text-danger">*</span></label>
          <input type="text" name="emailId" value={formData?.emailId} onChange={handleInputChange} className="form-control" placeholder="Your email ID" />
        </div>
        <div className="formGroup">
          <label>Date of birth<span className="text-danger">*</span></label>
          <DatePicker
            className="form-control"
            name="dob"
            onChange={handleDateChange}
            value={formData?.dob && moment(formData?.dob).format("YYYY-MM-DD")}
            defaultValue={new Date(moment().subtract(18, 'years'))}
            maxDate={new Date(moment().subtract(18, 'years'))}
            format="dd/MM/y"
          />
        </div>
        {failMsg && <div className="msg error" style={trialStyle}>{failMsg}</div>}
        <div className="submitMe mt-3"><button disabled={!formData?.name || !formData?.city || !formData?.emailId || !formData?.dob}>Continue</button></div>
        <div className="pagenation">
          <ul>
            {props?.stepHistory?.map(item => <li className="current cursor-pointer" onClick={() => props.goBack(item)}></li>)}
            <li className="current"></li>
            <li></li>
          </ul>
        </div>
      </form>
    </div>
  );
};

export default ChildDetailForm;
