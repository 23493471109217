import axios from "axios";
import { LOGIN_MODAL, LOGIN_SUCCESS, LOGOUT, OTP_SENT, QUIZ_FORM_DATA } from "./types"
import { Loading, Notify } from "notiflix";
import { APIURL } from "./config";
import { getKidList } from "./dashboard";
import { setGlobalKidId } from "./champions";


export const authenticate = (data, cb) => async dispatch => {

  const config = {
    headers: {
      'ContentType': 'application/json',
    }
  }
  try {
    Loading.standard();
    const res = await axios.post(`${APIURL}/api/auth/login/authenticate`, data, config);
    Loading.remove();
    if (res?.data?.status) {
      const message = res?.data?.message ?? "OTP sent successfully.";
      Notify.success(message);
      dispatch({
        type: OTP_SENT,
        payload: data?.mobile,
      });
      if (cb) cb();
    } else if (res?.data?.message) {
      Notify.warning(res?.data?.message);
    } else {
      Notify.warning("something went wrong");
    }

  } catch (err) {
    Loading.remove();
    const message = err?.response?.data?.message ?? "internal server error";
    Notify.failure(message);
  }
}

export const validate = (data, cb) => async dispatch => {

  const config = {
    headers: {
      'ContentType': 'application/json',
    }
  };
  try {
    Loading.standard();
    const res = await axios.post(`${APIURL}/api/auth/login/validate`, data, config);
    Loading.remove();
    if (res?.data?.status) {
      const message = res?.data?.message ?? "OTP is verified successfully.";
      Notify.success(message);
      dispatch(getKidList(id => { dispatch(setGlobalKidId(id)) }));
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { ...res?.data?.body?.user, token: res?.headers?.["access-token"] }
      })
      if (cb) cb();
    } else if (res?.data?.message) {
      Notify.warning(res?.data?.message);
    } else {
      Notify.warning("something went wrong");
    }

  } catch (err) {
    Loading.remove();
    const message = err?.response?.data?.message ?? "internal server error";
    Notify.failure(message);
  }
}

export const handleLoginModal = state => async dispatch => {
  dispatch({ type: LOGIN_MODAL, payload: state })
}

export const logoutApp = (cb) => async dispatch => {
  dispatch({ type: LOGOUT })
  if (cb) cb();
}

export const quizFormData = data => async dispatch => {
  dispatch({ type: QUIZ_FORM_DATA, payload: data })
}

export const resetQuizFormData = () => async dispatch => {
  dispatch({ type: QUIZ_FORM_DATA, payload: {} })
}