import axios from "axios";
import { Notify, Loading } from "notiflix";
import { APIURL } from "./config";

export const generatePresignedURL = (cb) => async dispatch => {
   const config = {
      headers: {
         'ContentType': 'application/json',
      },
      data: {}
   };
   try {
      Loading.standard();
      const res = await axios.get(`${APIURL}/upload/get-presigned-url?file_type=Image&upload_type=competition`, config);
      Loading.remove();
      if (res?.status === 200) {
         if (cb) cb(res?.data?.url);
      } else if (res?.data?.message) {
         Notify.warning(res?.data?.message);
      } else {
         Notify.warning("something went wrong");
      }
   } catch (err) {
      Loading.remove();
      console.log("err >>", err);
   }
}

// export const CloudFileUpload = ({ url, file }, cb) => async dispatch => {

//    var headers = new Headers();
//    headers.append("Content-Type", file?.type || "image/png");

//    var options = {
//       method: "PUT",
//       body: file,
//       redirect: "follow",
//       headers,
//    };
//    Loading.standard();
//    fetch(`${url}`, options)
//       .then((response) => response)
//       .then((result) => {
//          console.log("result >> ", result);
//          Loading.remove();
//          if (cb) cb(result?.url?.split("?")?.[0]);
//       })
//       .catch((error) => {
//          Loading.remove();
//          console.log("error", error)
//       });
// }

export const CloudFileUpload = ({ url, file }, cb, onprogress = () => { }, loader = true) => async (dispatch) => {

   const xhr = new XMLHttpRequest();
   xhr.open("PUT", url, true);
   xhr.setRequestHeader("Content-Type", file?.type || "image/png");
   xhr.upload.onprogress = onprogress;

   xhr.onload = () => {
      loader && Loading.remove();
      if (xhr.status >= 200 && xhr.status < 300) {
         if (cb) cb(xhr?.responseURL?.split("?")?.[0]);
      } else {
         console.log("error", xhr.statusText);
      }
   };

   xhr.onerror = () => {
      loader && Loading.remove();
      console.log("error", xhr.statusText);
   };

   loader && Loading.standard();
   xhr.send(file);
};


// export const CloudFileUpload = ({ url, file }, cb) => async dispatch => {

//    const formData = new FormData();
//    formData.append('file', file);

//    const config = {
//       headers: {
//          'Content-Type': file.type || 'application/octet-stream', // Set content type based on file type
//       },
//       onUploadProgress: (progressEvent) => {
//          const percentComplete = Math.round((progressEvent.loaded * 100) / progressEvent.total);
//          console.log(`Upload progress: ${percentComplete}%`);
//          // You can dispatch a progress action here if needed
//          // dispatch({ type: "UPLOAD_PROGRESS", payload: percentComplete });
//       }
//    };

//    try {
//       // Show loading UI
//       Loading.standard();

//       const response = await axios.put(url, formData, config);

//       // Remove loading UI
//       Loading.remove();

//       if (cb) cb(response.data.url.split("?")[0]);
//    } catch (error) {
//       // Remove loading UI in case of an error
//       Loading.remove();
//       console.error("error", error);
//    }
// };


export const generatePresignedURLNew = (params, cb) => async dispatch => {
   const config = {
      headers: {
         'ContentType': 'application/json',
      },
      data: {}
   };
   const queryString = typeof (params) === 'object' ? Object.keys(params).map(key => `${key}=${params[key]}`).join('&') : '';
   try {
      Loading.standard();
      const res = await axios.get(`${APIURL}/upload/get-presigned-url?${queryString}`, config);
      Loading.remove();
      if (res?.status === 200) {
         if (cb) cb(res?.data?.url);
      } else if (res?.data?.message) {
         Notify.warning(res?.data?.message);
      } else {
         Notify.warning("something went wrong");
      }
   } catch (err) {
      Loading.remove();
      console.log("err >>", err);
   }
}