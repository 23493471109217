import React from 'react'

const GlobalSlider = () => {
  return (
    <div className="top-slider">
      <div className="slider-content">
        <div id="slider-banner" className="carousel slide" data-bs-ride="true">
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#slider-banner"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#slider-banner"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#slider-banner"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="/images/slider-1.png"
                className="d-block w-100"
                alt="slide1"
              />
              <div className="crousel-overlay">
                <div className="crousel-overlay-content">
                  <h3>
                    Enabling companies to track & manage communication loop
                    closure.
                  </h3>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src="/images/slider-2.png"
                className="d-block w-100"
                alt="slide2"
              />
              <div className="crousel-overlay">
                <div className="crousel-overlay-content">
                  <h3>
                    Employee training that improves performance &
                    productivity.
                  </h3>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src="/images/slider-3.png"
                className="d-block w-100"
                alt="slide3"
              />
              <div className="crousel-overlay">
                <div className="crousel-overlay-content">
                  <h3>Hire right employee in minimum interview rounds.</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="slider-logo">
            <img src="/images/logo.svg" alt="logo" />
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#slider-banner"
            data-bs-slide="prev"
          >
            <span className="slide-icon" aria-hidden="true">
              <img src="/images/left-arrow-icon.png" alt="arrow" />
            </span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#slider-banner"
            data-bs-slide="next"
          >
            <span className="slide-icon" aria-hidden="true">
              <img src="/images/right-arrow-icon.png" alt="right-arrow" />
            </span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default GlobalSlider