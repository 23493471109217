import React, { useEffect } from 'react'
import { getGuestExperiential } from '../../Redux/Actions/champions';
import logo from "../../Assets/logos/persistent_logo.png"
import "./styles.css";
import { useDispatch, useSelector } from 'react-redux';
import ElpSlider from '../Champions/Components/ElpSlider';
import mentoringImage from "../../Assets/images/kiran_girls_image.svg"

const PersistentSystems = () => {

  const dispatch = useDispatch();
  const eventIds = [87, 88, 89, 90, 91, 92, 93, 94, 95]

  const user = useSelector(state => state.auth.user)
  const expList = useSelector((state) => state.champions.expList);
  const program = expList?.filter(item => eventIds.includes(item?.event_id))


  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [])

  useEffect(() => {
    const data = {
      event_organizer_id: 1,
      event_type: 1,
      id_order_kid_list: true,
      is_quiz_only: false,
      is_trending: false,
    };
    dispatch(getGuestExperiential(data));
  }, [user]);

  return (
    <div>
      <div class="pr-found-banner-section">
        <div class="container">
          <img src={logo} alt="" class="logo-img" />
          <div class="pr-found-banner-content">
            <h1>
              SPARK : Kiran Girls Mentoring Program
            </h1>
            <p>Giving Wings to IT-Career dreams of gifted Indian girls</p>
          </div>
        </div>
      </div>
      <div className="pr-found-body-content">
        <div className="container mt-3 champion-landing-content">
          <h2 className="pr-found-title">About Persistent Foundation</h2>
          <p>Persistent Systems is a leading Indian technology company founded in the year 1990 by Mr. Anand Deshpande, an IIT Kharagpur alumnus. Persistent Systems has been donating 1% of its profits towards social causes since 1995. The Persistent Foundation was established in 2009 to expand the vision and define the scope of Corporate Social Responsibility.</p>
          <p>Persistent Foundation’s vision it to provide sustainable solutions for local communities around Healthcare, Education and Community Development to empower long-lasting impact. Additionally, the Foundation is expanding its efforts to include Wildlife Conservation and Heritage Restoration. These new initiatives will focus on preserving wildlife and restoring museums and heritage sites. The identified projects will be executed in Pune, Nagpur, Bangalore, Hyderabad, Goa, Indore, and Gurugram.</p>
          <div className="pt-4 mt-4 pt-md-5 mt-md-5 bubble2">
            <h2 className="pr-found-title">About Kiran Girls Scholarship and Mentoring Program</h2>
            <p>The Kiran Girls Scholarship and Mentoring Program is the flagship program of Persistent Foundation under which the foundation educates underprivileged girls in information technology and computer science. This program is designed to equip them for job prospects in the industry. Approx. 50 girls are selected every year for scholarships from an applicant pool of 1,000+ promising future Indian stars by a pool of 65 Persistent employees devoting 200+ hours towards the selection process. Mentoring is an essential component of the program, in which every student interacts with the mentor over the course of several sessions to get guidance. Additionally, summer schools & skill bootcamps are organized for the benefit of the students. Most recently, a new initiative to donate laptops to select students on merit has been institutionalized as well.</p>
          </div>
          <div className="bubble3">
            <h2 className="pr-found-title pt-4 mt-4 pt-md-5 mt-md-5 ">About Kiran Girls Mentoring – SPARK Program</h2>
            <p>SPARK program is a new initiative of Persistent Foundation to offer online Experiential Learning opportunities related to various kinds of software jobs to Kiran Scholars & Kiran Buddies to complement their academic curriculum while augmenting their skills to make them most-job ready.
              In this program, multiple projects & assignments would be made available to interested learners to self-learn through online reference material & complete. </p>
            <p>Best reference material would be compiled & made available to the learners to help them be more efficient with their time. Asynchronous mentorship would be provided to learners from multiple subject matter experts to help them get feedback on their interim/WIP output & get feedback.</p>
            <p>The program allows learners to be flexible i.e. the learner must do one project every semester as per their time availability. The complexity of the projects/assignments increase as the student’s academic curriculum moves forward i.e. 2nd year student will have a relatively more complex project/assignment as compared to a 1st year student.</p>
            <p>Separate sub-programs are designed keeping in mind the possible career goals & interests of the learners e.g. QA role, Support role, Front End developer role, Back End developer role etc.</p>
            <p>More details about the sub-programs & associated projects/assignments are mentioned below.</p>
          </div>
          <div className="pt-4 mt-4 pt-md-5 mt-md-5  bubble4">
            <h2 className="pr-found-title">Who are eligible for the Kiran Girls Mentoring – SPARK Program?</h2>
            <p>The Kiran Girls Mentoring – SPARK Program would be launched initially for the Kiran Scholars & Kiran Buddies in the 2nd, 3rd & 4th year of the Kiran Scholarship & Mentoring program. Subsequently, the program would be extended to the 1st year students as well once they have settled well in their respective course curriculum.</p>
          </div>
          <h2 className="pr-found-title pt-4 mt-4 pt-md-5 mt-md-5 ">How to enrol in the program & benefit from the same?</h2>
          <p>Persistent Foundation will communicate about the program through their respective teams to the eligible Kiran Scholars & Kiran Buddies. The eligible students would be registered in the program directly by the Persistent Foundation team. Students can access the complete program details through this webpage (refer section below) & check projects/assignments, watch/access learning material and make interim/final submissions.  The program can be accessed by learners on masterNAUKRI app on <a href="https://play.google.com/store/apps/details?id=com.masternaukri.app&hl=en_US" target="_blank">Android</a> and <a href="https://apps.apple.com/in/app/masternaukri/id6450824258" target="_blank">App Store</a> as well.</p>
          <p>For any doubts related to the website/program, concerned learners can reach out to Persistent System’s delivery partner, masterNAUKRI by KidEx (KidEx Venture Private Limited) at <a href="mailto:info@kid-ex.com">info@kid-ex.com</a>.</p>
          <p>For any doubts related to eligibility/registration in the program, concerned learners can reach out to their respective SPOC (single point of contact) appointed by Persistent Systems.</p>

          {program?.length > 0 &&
            <div className="pr-found-slider">
              <h2 className="pr-found-title pt-4 mt-4 pt-md-5 mt-md-5 ">Programs</h2>
              <ElpSlider id="elp" events={program} prCard={true} />
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default PersistentSystems