import axios from "axios";
import { QUIZ_ACTIVITY, QUIZ_CERTIFICATE, QUIZ_CHECK, QUIZ_DATA, QUIZ_HISTORY, QUIZ_LOCAL_DATA, QUIZ_QUESTION, QUIZ_RESET, QUIZ_SUMMARY } from "./types";
import { Loading, Notify, Report } from "notiflix";
import { APIURL } from "./config";

export const getQuizHistory = (params) => async dispatch => {
   const { kidId } = params;
   delete params["kidId"];
   const config = {
      headers: {
         'ContentType': 'application/json',
      },
      data: {}
   };
   const queryString = typeof (params) === 'object' ? Object.keys(params).map(key => `${key}=${params[key]}`).join('&') : '';
   try {
      Loading.standard();
      const res = await axios.get(`${APIURL}/api/champions/quiz/${kidId}?${queryString}`, config);
      Loading.remove();
      if (res?.data?.status) {
         dispatch({
            type: QUIZ_HISTORY,
            payload: res?.data?.data
         });
         const data = {
            quiz_attempts: res?.data?.data?.[0].quiz_attempts,
            show_complete_button: res?.data?.data?.[0]?.show_complete_button,
            show_start_button: res?.data?.data?.[0]?.show_start_button,
            quiz_time_remaining: res?.data?.data?.[0]?.quiz_time,
            show_retake_button: res?.data?.data?.[0]?.show_retake_button,
            summary_button: res?.data?.data?.[0]?.summary_button,
         }
         dispatch(quizLocalData(data));
      } else if (res?.data?.message) {
         Notify.warning(res?.data?.message);
      } else {
         Notify.warning("something went wrong");
      }

   } catch (err) {
      Loading.remove();
      const message = err?.response?.data?.message ?? 'internal server error'
      Notify.failure(message);
   }
}

export const startQuiz = (params) => async dispatch => {
   const { kidId } = params;
   delete params["kidId"];
   const config = {
      headers: {
         'ContentType': 'application/json',
      }
   };
   const queryString = typeof (params) === 'object' ? Object.keys(params).map(key => `${key}=${params[key]}`).join('&') : '';
   try {
      Loading.standard();
      const res = await axios.post(`${APIURL}/api/champions/quiz/start/${kidId}?${queryString}`, {}, config);
      Loading.remove();
      if (res?.data?.status) {
         dispatch({ type: QUIZ_DATA, payload: res?.data?.data });
      } else if (res?.data?.message == "Oops! Time over for the quiz" && params?.attempt_id) {
         dispatch(quizComplete({ attempt_id: params.attempt_id }));
      } else if (res?.data?.message) {
         dispatch({ type: QUIZ_DATA, payload: { message: res?.data?.message } });
         Notify.warning(res?.data?.message);
      } else {
         Notify.warning("something went wrong");
      }
      const data = {
         summary_button: res?.data?.data?.summary_button,
         attempt_complete_status: res?.data?.data?.attempt_complete_status,
         show_complete_button: false,
         show_retake_button: false,
      }
      dispatch(quizLocalData(data));

   } catch (err) {
      Loading.remove();
      const message = err?.response?.data?.message ?? 'internal server error'
      Notify.failure(message);
   }
}

export const getQuizQuestion = (params) => async dispatch => {

   const { attempt_id, quiz_time_remaining } = params;
   const config = {
      headers: {
         'ContentType': 'application/json',
      }
   };
   const queryString = typeof (params) === 'object' ? Object.keys(params).map(key => `${key}=${params[key]}`).join('&') : '';
   try {
      Loading.standard();
      const res = await axios.post(`${APIURL}/api/champions/quiz/question?${queryString}`, {}, config);
      Loading.remove();
      if (res?.data?.status) {
         if (!res?.data?.data?.summary_button) {
            dispatch({
               type: QUIZ_QUESTION,
               payload: res?.data?.data
            });
         }
         const dataSet = {
            quiz_time_limit: res?.data?.data?.quiz_time_limit,
            skip_button: res?.data?.data?.skip_button,
            check_button: res?.data?.data?.check_button,
            quiz_time_remaining: res?.data?.data?.quiz_time_remaining,
            summary_button: res?.data?.data?.summary_button,
            show_complete_button: res?.data?.data?.quiz_complete_button,
            continue_button: res?.data?.data?.continue_button,
            timestamp: new Date().getTime(),
            correct_options: null
         }
         dispatch(quizLocalData(dataSet));
      } else if (res?.data?.message) {
         Notify.warning(res?.data?.message);
      } else {
         Notify.warning("something went wrong");
      }

   } catch (err) {
      Loading.remove();
      const message = err?.response?.data?.message ?? 'internal server error'
      Notify.failure(message);
   }
}

export const checkQuizQuestion = (data) => async dispatch => {

   const { attempt_id, quiz_time_remaining } = data;
   const config = {
      headers: {
         'ContentType': 'application/json',
      }
   };
   try {
      Loading.standard();
      const res = await axios.post(`${APIURL}/api/champions/quiz/question/check-options`, data, config);
      Loading.remove();
      if (res?.data?.status) {
         dispatch({
            type: QUIZ_CHECK,
            payload: res?.data?.data
         });
         const dataSet = {
            answer_description: res?.data?.data?.answer_description,
            correct_options: res?.data?.data?.correct_options,
            finish_popup_one_button: res?.data?.data?.finish_popup_one_button,
            quiz_time_remaining: res?.data?.data?.quiz_remaining_time,
            summary_button: res?.data?.data?.summary_button,
            continue_button: res?.data?.data?.continue_button,
            check_button: res?.data?.data?.check_button,
            show_complete_button: res?.data?.data?.quiz_complete_button,
            timestamp: new Date().getTime()
         }
         dispatch(quizLocalData(dataSet));
      } else if (res?.data?.message) {
         Notify.warning(res?.data?.message);
      } else {
         Notify.warning("something went wrong");
      }

   } catch (err) {
      Loading.remove();
      const message = err?.response?.data?.message ?? 'internal server error'
      Notify.failure(message);
   }
}

export const getQuizSummary = (params) => async dispatch => {

   const { kidId } = params;
   delete params["kidId"];
   const config = {
      headers: {
         'ContentType': 'application/json',
      },
      data: {}
   };
   const queryString = typeof (params) === 'object' ? Object.keys(params).map(key => `${key}=${params[key]}`).join('&') : '';
   try {
      Loading.standard();
      const res = await axios.get(`${APIURL}/api/champions/quiz/summary/${kidId}?${queryString}`, config);
      Loading.remove();
      if (res?.data?.status) {
         dispatch({
            type: QUIZ_SUMMARY,
            payload: res?.data?.data
         });
      } else if (res?.data?.message) {
         Notify.warning(res?.data?.message);
      } else {
         Notify.warning("something went wrong");
      }

   } catch (err) {
      Loading.remove();
      const message = err?.response?.data?.message ?? 'internal server error'
      Notify.failure(message);
   }
}

export const quizComplete = (params) => async dispatch => {

   const { time_over } = params;
   delete params["time_over"];
   const config = {
      headers: {
         'ContentType': 'application/json',
      },
      data: {}
   };
   const queryString = typeof (params) === 'object' ? Object.keys(params).map(key => `${key}=${params[key]}`).join('&') : '';
   try {
      Loading.standard();
      const res = await axios.get(`${APIURL}/api/champions/quiz/complete?${queryString}`, config);
      Loading.remove();
      if (res?.data?.status) {
         const data = {
            quiz_time_remaining: 1,
            summary_button: res?.data?.data?.summary_button,
            correct_options: res?.data?.data?.correct_options,
            continue_button: res?.data?.data?.continue_button,
            attempt_complete_status: res?.data?.data?.attempt_complete_status,
            timestamp: new Date().getTime()
         }
         dispatch(quizLocalData(data));
         const message = time_over ? "Oops! Time over for this quiz." : "Quiz completed.";
         Report.success(
            message,
            '',
            'Okay',
            function cb() {
               dispatch(quizLocalData({ summary_redirect: true }))
            },
         );
      } else if (res?.data?.message) {
         Notify.warning(res?.data?.message);
      } else {
         Notify.warning("something went wrong");
      }

   } catch (err) {
      Loading.remove();
      const message = err?.response?.data?.message ?? 'internal server error'
      console.log(message);
   }
}

export const getQuizActivity = (params) => async dispatch => {

   const config = {
      headers: {
         'ContentType': 'application/json',
      },
      data: {}
   };
   const queryString = typeof (params) === 'object' ? Object.keys(params).map(key => `${key}=${params[key]}`).join('&') : '';
   try {
      Loading.standard();
      const res = await axios.get(`${APIURL}/api/competition/activities/age?${queryString}`, config);
      Loading.remove();
      if (res?.data?.status) {
         dispatch({
            type: QUIZ_ACTIVITY,
            payload: res?.data?.data
         })
      } else if (res?.data?.message) {
         Notify.warning(res?.data?.message);
      } else {
         Notify.warning("something went wrong");
      }

   } catch (err) {
      Loading.remove();
      const message = err?.response?.data?.message ?? 'internal server error'
      Notify.failure(message);
   }
}

export const quizCertificate = (params) => async dispatch => {

   let { counter } = params;
   delete params["counter"];
   counter = !counter ? 1 : counter;
   const config = {
      headers: {
         'ContentType': 'application/json',
      },
      data: {}
   };
   const queryString = typeof (params) === 'object' ? Object.keys(params).map(key => `${key}=${params[key]}`).join('&') : '';
   try {
      // Loading.standard();
      const res = await axios.get(`${APIURL}/api/certificate/download-certificate-by-attempt?${queryString}`, config);
      // Loading.remove();
      if (res?.data?.status) {
         dispatch({
            type: QUIZ_CERTIFICATE,
            payload: res?.data?.data
         })
      } else {
         if (!counter || counter < 5) {
            counter++;
            setTimeout(() => {
               dispatch(quizCertificate({ ...params, counter }))
            }, 2000);
         }
      }

   } catch (err) {
      // Loading.remove();
      const message = err?.response?.data?.message ?? 'internal server error'
      Notify.failure(message);
   }
}

export const quizLocalData = data => async dispatch => {
   dispatch({
      type: QUIZ_LOCAL_DATA,
      payload: data
   })
}

export const resetQuiz = () => async dispatch => {
   dispatch({ type: QUIZ_RESET })
}