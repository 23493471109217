import React, { useEffect } from 'react';

const PlayStoreRedirect = () => {

  useEffect(() => {
    const playStoreUrl = "https://play.google.com/store/apps/details?id=com.masternaukri.app";
    window.location.href = playStoreUrl;
  }, []);

  return (
    <div>Redirecting to Play Store...</div>
  )
}

export default PlayStoreRedirect;