import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import "./style.css"

const SubmissionModal = ({ show, handleClose, handleSubmit }) => {

  const [consent, setConsent] = useState(true);

  const handleInputChange = (e) => setConsent(e.target.checked)

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body className="p-0">
        <div className="submission-modal-content p-4">
          <h1 className="fw-bold fs-3 mb-3">Terms</h1>
          <p className="fs-5 mb-4">Please read and accept the terms in use.</p>
          <form className="submission-modal-form">
            <div class="form-check mb-4">
              <input class="form-check-input" name="consent" type="checkbox" onChange={handleInputChange} checked={consent} />
              <label class="form-check-label ps-2">I allow masterNaukri to share my submission with public for benefit of others.</label>
            </div>
            <div class="form-check mb-4">
              <input class="form-check-input" type="checkbox" value={true} checked disabled />
              <label class="form-check-label ps-2">I agree to abide by all the rules & regulations as defined & understand that violation of any rules or guideline may result in disqualification from the event.</label>
            </div>
            <div class="form-check mb-4">
              <input class="form-check-input" type="checkbox" value={true} checked disabled />
              <label class="form-check-label ps-2">I give consent to masterNaukri to save my submissions on their servers in India/outside as deemed appropriate by them.</label>
            </div>
          </form>
        </div>
        <div>
          <button className="submission-modal-btn" onClick={() => handleSubmit(consent)}>Accept & Submit</button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default SubmissionModal