import axios from "axios";
import { Loading, Notify } from "notiflix";
import { APIURL } from "./config";
import { getQueryString } from "../../helper";
import { DIGI_FEEDS, DIGI_PIN_SUBMISSION, DIGI_PROFILE, DIGI_PUBLIC_FEEDS, DIGI_PUBLIC_SUBMISSION, DIGI_SUBMISSIONS } from "./types";

export const getDigiProfile = id => async dispatch => {

  const config = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    data: {}
  };
  try {
    Loading.standard();
    const res = await axios.get(`${APIURL}/api/student/profile/${id}`, config);
    Loading.remove();
    if (res?.data?.status) {
      dispatch({ type: DIGI_PROFILE, payload: res?.data?.data })
    }
    // else if (res?.data?.message) {
    //   Notify.warning(res?.data?.message);
    // } else {
    //   Notify.warning("something went wrong");
    // }
  } catch (err) {
    Loading.remove();
    const message = err?.response?.data?.message ?? 'internal server error'
    Notify.failure(message);
  }
}

export const getDigiSubmissions = params => async dispatch => {

  const config = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    data: {}
  };
  const queryString = getQueryString(params)
  try {
    Loading.standard();
    const res = await axios.get(`${APIURL}/api/competition/portfolio/submission?${queryString}`, config);
    Loading.remove();
    if (res?.data?.status) {
      dispatch({
        type: DIGI_SUBMISSIONS,
        type: (params?.isPublic && params?.isPinned) ? DIGI_PIN_SUBMISSION : params?.isPublic ? DIGI_PUBLIC_SUBMISSION : DIGI_SUBMISSIONS,
        payload: res?.data?.data
      })
    } else if (res?.data?.message) {
      Notify.warning(res?.data?.message);
    } else {
      Notify.warning("something went wrong");
    }
  } catch (err) {
    Loading.remove();
    const message = err?.response?.data?.message ?? 'internal server error'
    Notify.failure(message);
  }
}

export const getDigiFeeds = params => async dispatch => {

  const config = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    data: {},
  };
  const queryString = getQueryString(params)
  try {
    Loading.standard();
    const res = await axios.get(`${APIURL}/api/feed/portfolio/uploads?${queryString}`, config);
    Loading.remove();
    if (res?.data?.status) {
      dispatch({
        type: params?.isPublic ? DIGI_PUBLIC_FEEDS : DIGI_FEEDS,
        payload: res?.data?.data
      })
    } else if (res?.data?.message) {
      Notify.warning(res?.data?.message);
    } else {
      Notify.warning("something went wrong");
    }
  } catch (err) {
    Loading.remove();
    const message = err?.response?.data?.message ?? 'internal server error'
    Notify.failure(message);
  }
}

export const submitFeedPost = (data, cb) => async dispatch => {

  const config = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  };
  try {
    Loading.standard();
    const res = await axios.post(`${APIURL}/api/feed`, data, config);
    Loading.remove();
    if (res?.data?.status) {
      const message = res?.data?.message ? res?.data?.message : "File uploaded successfully!"
      Notify.success(message);
      if (cb) cb();
    } else if (res?.data?.message) {
      Notify.warning(res?.data?.message);
    } else {
      Notify.warning("something went wrong");
    }
  } catch (err) {
    Loading.remove();
    const message = err?.response?.data?.message ?? 'internal server error'
    Notify.failure(message);
  }
}

export const toggleDigiSubmission = (params, cb) => async dispatch => {

  const config = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryString = getQueryString(params)
  try {
    Loading.standard();
    const res = await axios.post(`${APIURL}/api/competition/portfolio/togglePublic?${queryString}`, {}, config);
    Loading.remove();
    if (res?.data?.status) {
      const message = res?.data?.message ? res?.data?.message : "Status updated!"
      Notify.success(message);
      if (cb) cb(true)
    } else if (res?.data?.message) {
      Notify.warning(res?.data?.message);
    } else {
      Notify.warning("something went wrong");
    }
  } catch (err) {
    Loading.remove();
    const message = err?.response?.data?.message ?? 'internal server error'
    Notify.failure(message);
    if (cb) cb(false)
  }
}

export const togglePinSubmission = (params, cb) => async dispatch => {

  const config = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryString = getQueryString(params)
  try {
    Loading.standard();
    const res = await axios.post(`${APIURL}/api/competition/portfolio/togglePinSubmission?${queryString}`, {}, config);
    Loading.remove();
    if (res?.data?.status) {
      const message = res?.data?.message ? res?.data?.message : "Status updated!"
      Notify.success(message);
      if (cb) cb(true)
    } else if (res?.data?.message) {
      Notify.warning(res?.data?.message);
    } else {
      Notify.warning("something went wrong");
    }
  } catch (err) {
    Loading.remove();
    const message = err?.response?.data?.message ?? 'internal server error'
    Notify.failure(message);
    if (cb) cb(false)
  }
}

export const toggleDigiFeeds = (params, cb) => async dispatch => {

  const config = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryString = getQueryString(params)
  try {
    Loading.standard();
    const res = await axios.post(`${APIURL}/api/feed/togglePublicFeed?${queryString}`, {}, config);
    Loading.remove();
    if (res?.data?.status) {
      const message = res?.data?.message ? res?.data?.message : "Status updated!"
      Notify.success(message);
      if (cb) cb(true)
    } else if (res?.data?.message) {
      Notify.warning(res?.data?.message);
    } else {
      Notify.warning("something went wrong");
    }
  } catch (err) {
    Loading.remove();
    const message = err?.response?.data?.message ?? 'internal server error'
    Notify.failure(message);
    if (cb) cb(false)
  }
}