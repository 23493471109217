import { DIGI_FEEDS, DIGI_PIN_SUBMISSION, DIGI_PROFILE, DIGI_PUBLIC_FEEDS, DIGI_PUBLIC_SUBMISSION, DIGI_SUBMISSIONS } from "../Actions/types";

const initialState = {
  data: {},
  submissions: {},
  publicSubmission: {},
  pinSubmission: {},
  feeds: {},
  publicFeeds: {}
}


export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case DIGI_PROFILE:
      return { ...state, data: payload }
    case DIGI_PUBLIC_SUBMISSION:
      return { ...state, publicSubmission: payload }
    case DIGI_PIN_SUBMISSION:
      return { ...state, pinSubmission: payload }
    case DIGI_SUBMISSIONS:
      return { ...state, submissions: payload }
    case DIGI_FEEDS:
      return { ...state, feeds: payload }
    case DIGI_PUBLIC_FEEDS:
      return { ...state, publicFeeds: payload }
    default:
      return state;
  }
}