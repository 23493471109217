import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from "react-player";
import PopUp from '../../Components/PopUp';
import "./style.css";
import { useDispatch, useSelector } from 'react-redux';
import { getDigiFeeds, getDigiProfile, getDigiSubmissions, submitFeedPost, toggleDigiFeeds, toggleDigiSubmission, togglePinSubmission } from '../../Redux/Actions/digitalPortfolio';
import { MEDIAPATH } from '../../Redux/Actions/config';
import Slider from '../../Components/slider';
import Pagination from 'react-js-pagination';
import { getFileCategory, isValidString } from '../../helper';
import { CloudFileUpload, generatePresignedURLNew } from '../../Redux/Actions/cloudFileUpload';
import moment from 'moment';
import Base from '../../Components/Layout/Base';
import { Link, NavLink } from 'react-router-dom';

const DigitalPortfolio = () => {

  const initPostFormData = {
    date: "",
    file_url: "",
    is_active: true,
    sharing_circle: "1st Circle",
    file_map: {},
    upload_types: ["feeds"],
    file_type: "",
    meta: { subTitle: "", title: "" },
    kids: [],
    tags: []
  }
  const page_size = 5;
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const contentDivRef = useRef(null);

  const user = useSelector(state => state.auth.user);
  const profile = useSelector(state => state.digiProfile.data);
  const sData = useSelector(state => state.digiProfile.submissions);
  const fData = useSelector(state => state.digiProfile.feeds);
  const kids = useSelector(state => state.dashboard.kids);
  const kidId = useSelector(state => state.champions.globalKidId);

  const [uploadTypeModal, setUploadTypeModal] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState({});
  const [postFormData, setPostFormData] = useState(initPostFormData)
  const [reload, setReload] = useState(0);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
    uploadFile(selectedFiles);
  };

  useEffect(() => {
    if (user?.id) {
      dispatch(getDigiProfile(kidId))
      dispatch(getDigiSubmissions({ kidId: kidId, pageNo: (activePage - 1), pageSize: page_size }))
      dispatch(getDigiFeeds({ kid_id: kidId, page: (activePage - 1), size: page_size }))
    }
  }, [user, reload])

  const handlePageChange = (pageNumber) => {
    scrollToContentDiv();
    const page = pageNumber - 1;
    setActivePage(pageNumber);
    dispatch(getDigiSubmissions({ kidId: kidId, pageNo: page, pageSize: page_size }))
    dispatch(getDigiFeeds({ kid_id: kidId, page, size: page_size }))
  };

  const uploadProgress = (event, file) => {

    if (event.lengthComputable) {
      var percentComplete = Math.round((event.loaded * 100) / event.total);
      setProgress((prevProgress) => ({ ...prevProgress, [file.name]: percentComplete }));
    }

  };

  const uploadFile = (files) => {

    files.forEach(file => {

      const fileType = file.name.split('.').pop();
      const Type = getFileCategory(fileType);
      dispatch(generatePresignedURLNew({ file_type: Type, upload_type: "EVENTS" }, (url) => {
        dispatch(CloudFileUpload(
          { url, file: file },
          fileUrl => {
            setPostFormData({
              ...postFormData,
              date: moment().format("Y-MM-DD"),
              file_type: Type,
              file_url: fileUrl,
              file_map: { [fileUrl]: Type },
              kids: [kids?.[0]?.unique_id]
            })
          },
          (event) => uploadProgress(event, file),
          false
        ))
      }))
    });

    setUploadTypeModal(false);
    setUploadModal(true);
  };

  const handleFeedSubmit = () => {
    dispatch(submitFeedPost(postFormData, () => {
      setUploadModal(false);
      setFiles([])
      setProgress({})
      setPostFormData(initPostFormData);
      setReload(Math.random())
    }));

  }

  const handlePostPrivacy = e => {

    if (e.target.checked) {
      setPostFormData({ ...postFormData, sharing_circle: "4th Circle" })
    } else {
      setPostFormData({ ...postFormData, sharing_circle: "1st Circle" })
    }
  }

  const handlePinVideo = (e, submissionId) => {
    const status = e.target.checked
    e.preventDefault();
    dispatch(togglePinSubmission({ submissionId }, () => {
      e.target.checked = status;
      setReload(Math.random())
    }))
  }

  const handleSubmissionToggle = (e, submissionId) => {
    const status = e.target.checked
    e.preventDefault();
    dispatch(toggleDigiSubmission({ submissionId }, () => {
      e.target.checked = status;
    }))
  }

  const handleFeedToggle = (e, unique_id) => {
    const status = e.target.checked
    e.preventDefault();
    dispatch(toggleDigiFeeds({ unique_id }, () => {
      e.target.checked = status;
    }))
  }

  const handleUploadCloseModal = () => {
    setFiles([])
    setProgress({})
    setPostFormData(initPostFormData);
    fileInputRef.current.value = null;
    setUploadModal(false);
  }

  const scrollToContentDiv = () => {
    if (contentDivRef.current) {
      contentDivRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Base>
      <div className="mb-5 mt-5 mt-md-0">
        <section className='profile-banner'></section>
        <section className='main-profile-content'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-3'>
                <div className='profile-left-area edit-profile'>
                  <div className='profile-user-img'>
                    <img src={isValidString(user?.profile_photo) ? user?.profile_photo : `${MEDIAPATH}/skin/images/profile/profile.jpeg`} alt="avatar" />
                    {/* <div className='profile-img-upload-btn'>
                    <img src='../../images/plus.svg' />
                  </div> */}
                  </div>
                  <div className='profile-user-info'>
                    <ul className='profile-info-list inter'>
                      <li>
                        <label>Name</label>
                        <input type='text' readOnly value={user?.name} placeholder='First Name' className='form-control' />
                      </li>
                      <li>
                        <label>Email</label>
                        <input type='text' readOnly value={user?.emailId} placeholder='user@mail.com' className='form-control' />
                      </li>
                      <li>
                        <label>Contact No.</label>
                        <input type='text' readOnly value={user?.mobile_no} placeholder='Contact No.' className='form-control' />
                      </li>
                      <li>
                        <label>Address</label>
                        <input type='text' readOnly value={user?.city} placeholder='Address' className='form-control' />
                      </li>
                      <li>
                        <label>Gender</label>
                        <input type='text' readOnly value={user?.gender} placeholder='Gender' className='form-control' />
                      </li>
                    </ul>
                    <div className='qr-code mt-0 w-auto text-center'>
                      <p><NavLink to="/account">Update Profile Details</NavLink></p>
                      {/* <img src='../../images/qr.png' /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl-6  mob-order-1'>
                <div ref={contentDivRef} className='profile-main-content-area'>
                  <div className='mb-5'>
                    <h2 className='title-18 inter mb-4 text-center text-md-start'>DIGITAL PORTFOLIO</h2>
                    <div className='media-upload inter' onClick={() => setUploadTypeModal(true)}>
                      <img src='../../images/upload.svg' />
                      Upload files/ media
                    </div>
                    <PopUp show={uploadTypeModal} handleClose={() => setUploadTypeModal(false)}>
                      <div className='modal-header'>
                        <h2 className='popup-title'>Upload</h2>
                      </div>
                      <div className='modal-body'>
                        <div className='upload-file-type'>
                          <div className='upload-file-type-item'>
                            <div className='upload-file-type-item-icon'>
                              <img src='../../images/image.svg' />
                              <input ref={fileInputRef} type="file" onChange={handleFileChange} id="imageUpload" multiple accept="image/*" />
                            </div>
                            <div className='upload-file-type-item-text nunito'>Image</div>
                          </div>
                          <div className='upload-file-type-item'>
                            <div className='upload-file-type-item-icon'>
                              <img src='../../images/pdf.svg' />
                              <input type="file" onChange={handleFileChange} id="pdfUpload" multiple accept="application/pdf" />
                            </div>
                            <div className='upload-file-type-item-text nunito'>PDF</div>
                          </div>
                          <div className='upload-file-type-item'>
                            <div className='upload-file-type-item-icon'>
                              <img src='../../images/video.svg' />
                              <input type="file" onChange={handleFileChange} id="videoUpload" multiple accept="video/*" />
                            </div>
                            <div className='upload-file-type-item-text nunito'>Video</div>
                          </div>
                        </div>
                      </div>
                    </PopUp>
                    <PopUp show={uploadModal} handleClose={() => handleUploadCloseModal(false)}>
                      <div className='modal-header'>
                        <h2 className='popup-title'>Upload</h2>
                      </div>
                      <div className='modal-body'>
                        <div className='uploaded-file-name'>
                          <label className='inter'>Title</label>
                          <input type='text' onChange={e => setPostFormData({ ...postFormData, meta: { title: e.target.value, subTitle: "" } })} value={postFormData?.meta?.title} placeholder='Title' className='form-control' />
                        </div>
                        {files?.map(file => {
                          return (
                            <div className="file-progess inter mb-3 mt-4" key={file.name}>
                              <div className='flex-1 me-3'>
                                <div className="progress">
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{ width: `${progress[file.name] || 0}%` }}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                                <div className="d-flex justify-content-between mt-2">
                                  <div className="progress-text">{file.name}</div>
                                  <div class="switch-checkbox">
                                    <label className="form-check-label inter me-2" for="flexCheckDefault">Add to portfolio</label>
                                    <label class="switch">
                                      <input type="checkbox" onChange={handlePostPrivacy} />
                                      <span class="switch-btn"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {/* <button type='button' className='file-delete'><img src='../../images/file-delete.svg' /></button> */}
                            </div>)
                        })}
                      </div>
                      <div className='modal-footer justify-content-between'>
                        <button onClick={() => setUploadModal(false)} type='button' className='site-btn border-btn px-5'>Cancel</button>
                        <button onClick={handleFeedSubmit} type='button' className='site-btn blue-btn px-5'>Submit</button>
                      </div>
                    </PopUp>
                  </div>
                  {sData?.data?.map(item => {
                    return (
                      <div className='mb-5' key={item?.id}>
                        <div className='d-flex justify-content-between align-items-center my-3'>
                          <h2 className='title-18 inter'>{item?.competitionName} - {item?.activityName}</h2>
                          <button className={`status-btn reviewed`} type='button'>Reviewed</button>
                        </div>
                        <Slider data={item?.fileUrlDtoList} dots={false} />
                        <div className='d-flex justify-content-between mt-3'>
                          <div class="form-checkbox">
                            <input onChange={e => handlePinVideo(e, item?.csnUniqueId)} className="form-check-input" type="checkbox" checked={item?.isPinned} id="flexCheckDefault" />
                            <label className="form-check-label inter" for="flexCheckDefault">Pin Video</label>
                          </div>
                          <div class="switch-checkbox">
                            <label className="form-check-label inter me-2" for="flexCheckDefault">Add to portfolio</label>
                            <label class="switch">
                              <input onChange={e => handleSubmissionToggle(e, item?.csnUniqueId)} type="checkbox" checked={item?.isPublic} />
                              <span class="switch-btn"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                  {fData?.data?.map(item => {
                    return (
                      <div className='mb-5' key={item?.id}>
                        <div className='d-flex justify-content-between align-items-center my-3'>
                          <h2 className='title-18 inter'>{item?.file_meta?.title}</h2>
                          <button className={`status-btn not-reviewed`} type='button'>Not Reviewed</button>
                        </div>
                        <Slider data={item?.file_urls_new} dots={false} />
                        <div className='d-flex justify-content-end mt-3'>
                          {/* <div class="form-checkbox">
                          <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                          <label className="form-check-label inter" for="flexCheckDefault">Pin Video</label>
                        </div> */}
                          <div class="switch-checkbox">
                            <label className="form-check-label inter me-2" for="flexCheckDefault">Add to portfolio</label>
                            <label class="switch">
                              <input type="checkbox" onChange={e => handleFeedToggle(e, item?.unique_id)} checked={item?.sharing_circle === "4th Circle"} />
                              <span class="switch-btn"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
                {sData?.pagination_info?.total_elements > 0 &&
                  <div className="pagination-container justify-content-center d-flex">
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={5}
                      totalItemsCount={sData?.pagination_info?.total_elements}
                      pageRangeDisplayed={5}
                      linkClass="custm_pagination"
                      onChange={handlePageChange}
                      itemClassFirst="page_first"
                      itemClassPrev="page_prev"
                      itemClassNext="page_next"
                      itemClassLast="page_last"
                    />
                  </div>
                }
                {sData?.data?.length === 0 && fData?.data?.length === 0 &&
                  <div className="text-center fs-5 fw-500">No feeds to show...</div>
                }
              </div>
              <div className='col-xl-3'>
                <div className={`profile-right-area text-center mt-4`}>
                  {profile?.college_id && <img src='../../images/iim.png' className='mw-auto object-fit-none' />}
                  <div className='d-flex flex-row flex-md-column mt-3'>
                    <Link to="/portfolio-view" type='button' className='site-btn text-decoration-none border-btn mb-md-2 me-2 me-md-0 w-100'>Preview Portfolio  </Link>
                    {/* <button type='button' className='site-btn blue-btn w-100'>Update </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Base>
  )
}

export default DigitalPortfolio;