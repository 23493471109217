import { Confirm, Report } from "notiflix";
import React, { Fragment, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
// import Slider from "react-slick";
import MultipleChoice from "./MultipleChoice";
import SingleChoice from "./SingleChoice";
import { useNavigate } from "react-router-dom";
import { checkQuizQuestion, getQuizQuestion, quizComplete } from "../../../Redux/Actions/quiz";
import { MEDIAPATH } from "../../../Redux/Actions/config";

const EventQuizQuestion = ({ kidId, event_code, eventId }) => {
   const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      nextArrow: (
         <div className="arrowNext">
            <img className="arrow-icon-img" src={`${MEDIAPATH}/skin/images/quiz/right-arrow.png`} />
         </div>
      ),
      prevArrow: (
         <div className="arrowPrev">
            <img className="arrow-icon-img" src={`${MEDIAPATH}/skin/images/quiz/left-arrow.png`} />
         </div>
      ),
      nav: true,
      slidesToShow: 1,
      slidesToScroll: 1,
   };

   const dispatch = useDispatch();
   const navigate = useNavigate();

   const questionData = useSelector(state => state.quiz.questionData);
   const quizData = useSelector(state => state.quiz.quizData);
   const quizLocalData = useSelector(state => state.quiz.localData);

   const [answer, setAnswer] = useState([]);

   useEffect(() => {
      if (quizLocalData?.summary_redirect) {
         navigate(`/programs/${event_code}/quiz/summary`, {
            state: {
               kidId,
               acm_quiz_id: quizData?.acm_quiz_id,
               attempt_id: quizData?.attempt_id,
               eventId
            }
         });
      }
   }, [quizLocalData?.summary_redirect])

   const handleAnswer = (e, single) => {
      const value = parseInt(e.target.value);
      if (single) {
         setAnswer([value]);
      } else {
         if (answer.indexOf(value) >= 0) {
            const newSet = [...answer]
            newSet.splice(answer.indexOf(value), 1);
            setAnswer(newSet);
         } else {
            setAnswer([...answer, value]);
         }
      }
   }

   const handleCheck = () => {
      if (!answer.length)
         return false

      const time_taken = Math.floor((new Date().getTime() - quizLocalData.timestamp) / 1000);
      const data = {
         attempt_id: quizData?.attempt_id,
         question_id: questionData?.question_id,
         selected_options: answer,
         time_taken,
         quiz_time_remaining: quizLocalData?.quiz_time_remaining
      }
      dispatch(checkQuizQuestion(data));
   }

   const handleContinue = () => {
      const time_taken = Math.floor((new Date().getTime() - quizLocalData.timestamp) / 1000);
      const data = {
         attempt_id: quizData?.attempt_id,
         submit_type: "New",
         time_taken,
         quiz_time_remaining: quizLocalData?.quiz_time_remaining
      }
      setAnswer([]);
      dispatch(getQuizQuestion(data))
   }
   const handleSkip = () => {
      const time_taken = Math.floor((new Date().getTime() - quizLocalData.timestamp) / 1000);
      const data = {
         attempt_id: quizData?.attempt_id,
         question_id: questionData?.question_id,
         submit_type: "Skip",
         time_taken,
         quiz_time_remaining: quizLocalData?.quiz_time_remaining
      }
      setAnswer([]);
      dispatch(getQuizQuestion(data))
   }

   const handleComplete = () => {
      if (quizLocalData?.finish_popup_one_button) {
         Report.success(
            "Kindly click Finish to complete the Quiz",
            '',
            'Finish',
            function cb() {
               dispatch(quizComplete({ attempt_id: quizData?.attempt_id }))
            },
         );
      } else {
         Confirm.show(
            'masterNaukri - Quiz',
            `Kindly click Finish to complete the Quiz`,
            'Finish',
            'Cancel',
            function okCb() {
               dispatch(quizComplete({ attempt_id: quizData?.attempt_id }))
            },
            function cancelCb() { },
            {
               className: 'notiflix-confirm',
               width: '320px',
               borderRadius: '8px',
            },
         );
      }
   }

   return (
      <div className="component-question">
         <div className="question-sec">
            <div className="question-header">
               <div className="container">
                  <div className={`question`}>
                     <h5>{questionData?.current_question_number}. {questionData?.question_text}</h5>
                  </div>
                  {questionData?.image_urls || questionData?.video_urls ?
                     <div className="quiz-question-slider">
                        <div className="quiz-question-slider-inner">
                           {/* <Slider {...settings}>
                                 {questionData?.video_urls && questionData?.video_urls?.map((val, index) => {
                                    return (
                                       <div key={index}>
                                          <div className="question-video-player">
                                             <ReactPlayer
                                                url={val}
                                                controls={true}
                                             />
                                          </div>
                                       </div>
                                    );
                                 })
                                 }
                                 {questionData?.image_urls && questionData?.image_urls?.map((val, index) => {
                                    return (
                                       <div key={index}>
                                          <img className="ques-slider-img" src={val} alt="loading..." />
                                       </div>
                                    );
                                 })
                                 }
                              </Slider> */}
                        </div>
                     </div>
                     : ''}
               </div>
            </div>
            <div className="question-answers-content">
               <div className="container">
                  <div className="answers-type-outer">
                     {questionData?.is_single_choice ?
                        <Fragment>
                           <p>Choose the correct alternative (single)</p>
                           <SingleChoice questionData={questionData} quizLocalData={quizLocalData} handleAnswer={handleAnswer} answer={answer} setAnswer={setAnswer} />
                        </Fragment>
                        :
                        <Fragment>
                           <p>Choose the correct alternative (multiple)</p>
                           <MultipleChoice questionData={questionData} quizLocalData={quizLocalData} handleAnswer={handleAnswer} answer={answer} />
                        </Fragment>
                     }

                  </div>
               </div>
            </div>
         </div>
         {(quizLocalData?.correct_options != null && (quizLocalData?.continue_button || quizLocalData?.show_complete_button)) && !questionData?.is_single_choice &&
            <div className={`answer-${quizLocalData?.correct_options ? 'correct' : 'incorrect'}`}>
               <div className="container">
                  <div className={`correct-content text-${quizLocalData?.correct_options ? 'correct' : 'incorrect'}`}>
                     <p>
                        <img className="incorrect-icon" src={`${MEDIAPATH}/skin/images/quiz/${quizLocalData?.correct_options ? 'correct' : 'incorrect'}-icon.png`} alt={`${quizLocalData?.correct_options ? 'correct' : 'incorrect'}`} />
                        {quizLocalData?.correct_options ? 'You are correct' : 'That’s incorrect'}
                     </p>
                     {!quizLocalData?.correct_options &&
                        <div className="hint-answer-content question-sec">
                           <p>Correct answer:</p>
                           <div className="card answers-type-card answer-video-card">
                              <label className="btn">
                                 {quizLocalData?.answer_description?.answer_description && quizLocalData?.answer_description?.answer_description}
                                 {quizLocalData?.answer_description?.description_image_urls?.length > 0 && <img className="text-ans-img" src={quizLocalData?.answer_description?.description_image_urls?.[0]} />}
                                 {quizLocalData?.answer_description?.description_video_urls?.length > 0 && <div className="answer-video-player">
                                    <ReactPlayer
                                       url={quizLocalData?.answer_description?.description_video_urls?.[0]}
                                       controls={true}
                                    />
                                 </div>
                                 }
                              </label>
                           </div>
                        </div>
                     }
                     {quizLocalData?.continue_button && <button onClick={handleContinue} className="btn quiz-button-pnk-comn mx-5">Continue</button>}
                  </div>
               </div>
            </div>
         }
         <div className="container question-btn gap-4">
            {quizLocalData?.continue_button && questionData?.is_single_choice && <button onClick={handleContinue} className="btn quiz-button-pnk-comn">Continue</button>}
            {quizLocalData?.show_complete_button && !quizLocalData?.attempt_complete_status && <button button onClick={handleComplete} className="btn quiz-button-pnk-comn">Complete</button>}
            {quizLocalData?.skip_button && !quizLocalData?.summary_button && !quizLocalData?.continue_button && <button onClick={handleSkip} className="btn quiz-button-out-link-pnk-comn"> Skip</button>}
            {quizLocalData?.check_button && !quizLocalData?.summary_button && !quizLocalData?.continue_button && <button onClick={handleCheck} className="btn quiz-button-pnk-comn" style={{ opacity: `${answer.length ? '1' : '0.7'}` }}>Check</button>}
         </div>
      </div>
   );
};

export default EventQuizQuestion;
