import React from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import './styles.css';
import { Navigation } from 'swiper/modules';
import { useSelector } from "react-redux";
import { Confirm, Notify } from "notiflix";

const EventSlider = ({ events }) => {

  const navigate = useNavigate();

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const kids = useSelector(state => state.dashboard.kids);
  const myEvents = useSelector(state => state.champions.myEvents);

  const registerNow = item => {
    if (isAuthenticated) {
      if (kids?.[0]?.name) {
        navigate(`/programs/${item?.event_code}/register/individual`, { item })
      } else {
        Confirm.show(
          'masterNaukri',
          `Please update profile to register.`,
          'Update',
          'Cancel',
          function okCb() {
            navigate('/account');
          },
          function cancelCb() { },
          {
            className: 'notiflix-confirm',
            width: '320px',
            borderRadius: '8px',
          },
        );
      }
    } else {
      navigate(`/programs/${item?.event_code}/register/individual`, { item })
    }
  }

  const RegisterBottons = ({ item }) => {
    if (myEvents?.length) {
      if (myEvents?.filter(evt => evt?.event_id == item?.event_id)?.length == 0) {
        return <button onClick={() => registerNow(item)} className="btn chmpn-pnk-btn w-auto px-2 py-0 my-1">Register now</button>
      } else {
        return null
      }
    } else {
      return <button onClick={() => registerNow(item)} className="btn chmpn-pnk-btn w-auto px-2 py-0 my-1">Register now</button>
    }
  }

  let crouselPostDataList = ''
  crouselPostDataList = events?.length && events?.map((item, index) => {
    const isLive = ((moment().diff(item?.start_date, "seconds") >= 0 || moment().diff(item?.registration_start_date, "seconds") >= 0) && moment().diff(item?.end_date, "seconds") <= 0) ? true : false;
    return (
      <SwiperSlide key={index}>
        <div className="competitions-item-outer col-md-12 py-3 px-2">
          <div className="competitions-item">
            <Link to={{ pathname: `/programs/${item?.event_code}`, state: item }}>
              <img className="chmpn-slider-img" src={item?.image_url} alt="activity" />
            </Link>
            {isLive && (<div className="chmpn-live-bdg"> <span>live</span></div>)}
            <div className="chmpn-slider-content flex-column gap-2 chmpn-register-select-btn p-2">
              <div className="d-flex justify-content-start align-items-center">
                <Link className="nav-link explore-nav" to={{ pathname: `/programs/${item?.event_code}`, state: item }}>
                  <span>{item?.event_name}</span>
                </Link>
              </div>
              <div className="d-flex justify-content-between">
                {item?.register_as_individual &&
                  <RegisterBottons item={item} />
                }
                <Link className="nav-link explore-nav" style={{ padding: "10px 0px" }} to={{ pathname: `/programs/${item?.event_code}`, state: item }}>Explore more</Link>
              </div>
              <div className="text-start">
                {item?.price == "Free"
                  ? <button type="button" className="btn btn-neon fw-600 rounded-pill py-0">Free</button>
                  : <span className="fw-normal">Starting <span className="fw-600">{item?.price}</span></span>
                }
              </div>
            </div>

          </div>
        </div>
      </SwiperSlide>
    )
  })

  return (
    <div className="competitions-content-slider col-12 py-3 px-0">
      <Swiper
        rewind={true}
        slidesPerView={2.5}
        spaceBetween={9}
        breakpoints={{
          1024: {
            slidesPerView: 2.5,
            spaceBetween: 1
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 1
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 1
          },
          540: {
            slidesPerView: 1,
            spaceBetween: 1
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 2
          },
        }}
        navigation={true}
        modules={[Navigation]}
        className="mySwiper">
        {crouselPostDataList}
      </Swiper>
    </div>
  );
};

export default EventSlider;
