import React from "react";
import submissionEmpty_img from "../../Assets/images/sbms-emp-bnr.png";

const EmptyActivity = (props) => {
  return (
    <div className={props.className}>
      <div className="sub-emp-content text-center">
        <img className="sub-emp-img" src={submissionEmpty_img} />
        <p>{props?.title ? props?.title : "It’s empty in here. Submit your activities."}</p>
      </div>
      <div className="sub-emp-content-btn col-sm-12 text-center my-4">
      </div>
    </div>
  );
};
export default EmptyActivity;
