import React, { Fragment, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import Base from '../../Components/Layout/Base';
import Teams from './Components/Teams';
import Hiring from './Components/Hiring';
import Training from './Components/Training';
import "./style.css";
import GlobalSlider from '../../Components/GlobalSlider';

const Home = () => {

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const fragment = location.hash.slice(1);
      const targetElement = document.getElementById(fragment);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <Base>
      <Fragment>
        <GlobalSlider />
        <div className="c-loop">
          <div className="container">
            <div className="heading text-center">
              <h3 className="fw-bold">
                Enabling companies to track & manage communication loop closure.
              </h3>
            </div>
            <div className="row">
              <div className="col-12 col-sm-9 col-md-8 col-lg-9 mx-auto px-0">
                <div className="c-detail text-center">
                  <p className="mb-4">
                    How can companies ensure employees read & understand org
                    communication fully?
                  </p>
                  <h6>
                    masterNAUKRI platform offers multiple features for an
                    organization to track the reading coverage of org
                    communications & ascertain the level of understanding of the
                    message by the recipient. This is 100% digital and results in
                    at least 30% higher effectiveness in terms of on-ground
                    execution timeliness and quality.
                  </h6>
                </div>
                <div className="group mx-auto">
                  <img src="/images/pointer.svg" alt="pointer" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="training" className="training-sec">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-9 col-md-9 col-lg-9 mx-auto">
                <div className="hdng text-center">
                  <h3 className="fw-bold">
                    Employee training that improves performance & productivity.
                  </h3>
                </div>
                <div className="content text-center">
                  <p className="pb-2">
                    How can companies improve the effectiveness of corporate
                    training programs?
                  </p>
                  <h6>
                    Globally, the effectiveness of corporate training programs to
                    drive improvements is reported as below 30%. The key issue
                    highlighted is that programs achieve knowledge dissemination,
                    but the gap between knowledge & delivery remains. masterNAUKRI
                    solves this through a “Learning by Doing” Experiential
                    Learning platform.
                  </h6>
                </div>
              </div>
            </div>
            <div className="cards">
              <Training />
            </div>
          </div>
        </div>
        <div className="Interview" id="hiring">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-9 col-md-9 col-lg-9 mx-auto">
                <div className="hdng text-center">
                  <h3 className="fw-bold">
                    Hire right employee in minimum interview rounds.
                  </h3>
                </div>
                <div className="content text-center">
                  <p className="pb-2">
                    Why is hiring considered an overhead work at many companies?
                  </p>
                  <h6>
                    Hiring a candidate involves 3-8 rounds of interviews. More
                    than 70% of 1st round interviews are less than 15 minutes
                    long. These are big productivity blows for any company.
                    masterNAUKRI enables companies to pre-screen candidates
                    digitally & select the right candidate in 1 to 2 interviews
                    only.
                  </h6>
                </div>
              </div>
            </div>
            <div className="cards">
              <Hiring />
            </div>
          </div>
        </div>
        <div className="team" id="team">
          <div className="container">
            <div className="hdng text-center mb-4">
              <h3 className="fw-bold">Our Team</h3>
            </div>
            <div className="mb-5">
              <Teams />
            </div>
          </div>
        </div>
      </Fragment>
    </Base>
  )
}

export default Home