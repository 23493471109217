import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Base from "../../Components/Layout/Base";
import GlobalSlider from "../../Components/GlobalSlider";
import EventSlider from "./Components/EventSlider";
import ElpSlider from "./Components/ElpSlider";
import MyEventSlider from "./Components/MyEventSlider";
import { getGuestEvents, getGuestExperiential, getGuestQuizes, getRegisteredEvents } from "../../Redux/Actions/champions";
import "../../css/narc-landing.css";
import "./style.css";

const Champions = () => {
  const dispatch = useDispatch();

  const user = useSelector(state => state.auth.user)
  const myEvents = useSelector(state => state.champions.myEvents);
  const eventsList = useSelector((state) => state.champions.eventsList);
  const expList = useSelector((state) => state.champions.expList);
  const quizList = useSelector((state) => state.champions.quizList);


  useEffect(() => {
    if (user?.id) {
      dispatch(getRegisteredEvents({ user_id: user?.id, status: 1 }));
    }
  }, [user]);

  useEffect(() => {
    const data = {
      event_organizer_id: 1,
      event_type: 0,
      id_order_kid_list: true,
    };
    dispatch(getGuestEvents(data));
  }, [user]);

  useEffect(() => {
    const data = {
      event_organizer_id: 1,
      event_type: 1,
      id_order_kid_list: true,
      is_quiz_only: false,
      is_trending: false,
    };
    dispatch(getGuestExperiential(data));
  }, [user]);

  useEffect(() => {
    const data = {
      event_organizer_id: 1,
      id_order_kid_list: true,
      is_quiz_only: true,
      is_trending: false,
    };
    dispatch(getGuestQuizes(data));
  }, [user]);

  return (
    <Base>
      <GlobalSlider />
      <div className="container mt-5 champion-landing-content">
        {myEvents?.length > 0 &&
          <div className="competitions-content">
            <div className="chmpn-container">
              <div className="chmpn-main-hdng text-center">
                <h3 id="competitions" className="main-hdng-title">Your booked Items</h3>
              </div>
              <div className="chmp-post-slider chmp-post-slider-height-comp">
                <MyEventSlider events={myEvents} />
              </div>
            </div>
          </div>
        }
        {eventsList?.length > 0 &&
          <div className="competitions-content">
            <div className="chmpn-container">
              <div className="chmpn-main-hdng text-center">
                <h3 id="competitions" className="main-hdng-title">Jobs & internships</h3>
              </div>
              <div className="chmp-post-slider chmp-post-slider-height-comp">
                <EventSlider events={eventsList} />
              </div>
            </div>
          </div>
        }
        {expList?.length > 0 &&
          <div className="competitions-content">
            <div className="chmpn-container">
              <div className="chmpn-main-hdng text-center">
                <h1 id="experiential" className="main-hdng-title">Experiential learning</h1>
              </div>
              <div className="chmp-post-slider chmp-post-slider-height-exp">
                <ElpSlider id="elp" events={expList} />
              </div>
            </div>
          </div>
        }
        {quizList?.length > 0 &&
          <div className="competitions-content">
            <div className="chmpn-container">
              <div className="chmpn-main-hdng text-center">
                <h3 id="quizzes" className="main-hdng-title">Skill quizzes</h3>
              </div>
              <div className="chmp-post-slider chmp-post-slider-height-exp">
                <ElpSlider id="quiz" events={quizList} />
              </div>
            </div>
          </div>
        }
        {/* <div className="competitions-content my-5 py-0">
          <div className="chmpn-container">
            <div className="chmp-pricipate-flow context_narchome py-0">
              <div className="chmpn-main-hdng text-center">
                <h3 className="main-hdng-title" id="chmp-participate">
                  How does it work ?
                </h3>
              </div>
              <HowItWork />
            </div>
          </div>
        </div> */}
      </div>
    </Base>
  )
}

export default Champions;
