import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { authenticate } from "../../../Redux/Actions/auth";

const waitStyle = {
  color: "green",
  marginBottom: "24px",
  textAlign: "center",
};

const RegisterStepOne = ({ onComplete, event }) => {

  const dispatch = useDispatch();

  const [email, setState] = useState("");
  const [mobile_number, setMobile] = useState("");
  const [guardians_name, setGaurdians] = useState("");

  const [success, setSuccess] = useState("");
  const [successMsg, setMsgSuccess] = useState("");
  const [waitMsg, setWaitMsg] = useState("");

  const registerEmailHandler = (event) => {
    setState(event.target.value);
  };

  const registerGardianNameHndler = (event) => {
    setGaurdians(event.target.value);
  };

  const registerMobileHandler = (event) => {
    setMobile(event.target.value);
  };

  const narcSubmitOne = (e) => {
    e.preventDefault();

    // const check = email.includes(".");
    // const valid = email.includes("@");
    // const checkEmailCount = (email.match(new RegExp("@", "g")) || []).length;

    if (mobile_number === "" || mobile_number === undefined) {
      setMsgSuccess("Please enter mobile number");
    }
    // if (guardians_name === "" || guardians_name === undefined) {
    //   setMsgSuccess("Please enter guardian’s name");
    // }
    if (mobile_number === "" || mobile_number === undefined) {
      setMsgSuccess("Please enter mobile number");
    } else if (
      mobile_number === "" &&
      validateMobile(mobile_number) === false
    ) {
      setMsgSuccess("Please enter valid mobile number");
    } else if (
      mobile_number !== "" &&
      validateMobile(mobile_number) === false
    ) {
      setMsgSuccess("Please enter valid mobile number");
    } else {
      setMsgSuccess("");
    }

    // if (email === "" && email !== undefined) {
    //   setSuccess("Please enter email");
    // } else if (
    //   email !== "" &&
    //   isEmail(email) === false &&
    //   (check === false || valid === false)
    // ) {
    //   setSuccess("Please enter valid email id");
    // } else if (email !== "" && checkEmailCount !== 1) {
    //   setSuccess("Please enter valid email id");
    // } else if (email !== "" && isEmail(email) === false) {
    //   setSuccess("Please enter valid email id");
    // } else {
    //   setSuccess("");
    // }

    if (
      validateMobile(mobile_number) === true &&
      // isEmail(email) === true &&
      // checkEmailCount === 1 &&
      // email !== "" &&
      // email !== undefined &&
      // check === true &&
      // valid === true &&
      mobile_number !== "" &&
      mobile_number !== undefined
    ) {
      dispatch(authenticate({ mobile: mobile_number }, () => {
        onComplete({ formData: { number: mobile_number } })
      }))
    }
  };

  function isEmail(val) {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var isValid = regEmail.test(val);
    return isValid;
  }

  function validateMobile(mobile) {
    var isValid = false;
    if (mobile === 0) {
      return isValid;
    }
    if (mobile.length < 6) {
      return isValid;
    }
    var regex = /^[0-9\.\-\+\(\) ]*$/;
    isValid = regex.test(mobile);
    return isValid;
  }

  return (
    <div className="register-new-child card p-4">
      <div className="card-body">
        <h4><strong>Enter credentials for {event?.event_name}</strong></h4>
        <form className="form stepOne mt-3">
          {/* <div className="form-group mb-3">
            <label>Name<span className="text-danger">*</span></label>
            {successMsg && <div className="msg error">{successMsg}</div>}
            <input
              className="form-control mt-1"
              type="text"
              id="guardians_name"
              name="guardians_name"
              value={guardians_name}
              onChange={registerGardianNameHndler}
              placeholder="Enter your name"
            />
          </div> */}
          <div className="form-group mb-3">
            <label>Mobile Number<span className="text-danger">*</span></label>
            {successMsg && <div className="msg error">{successMsg}</div>}
            <input
              className="form-control mt-1"
              type="tel"
              id="mobile_number"
              name="mobile_number"
              value={mobile_number}
              onChange={registerMobileHandler}
              placeholder="Enter your mobile number"
            />
          </div>
          {/* <div className="form-group mb-3">
            <label>Email address<span className="text-danger">*</span></label>
            {success && <div className="msg error">{success}</div>}
            <input
              className="form-control mt-1"
              type="text"
              name="email"
              id="email"
              value={email}
              onChange={registerEmailHandler}
              placeholder="Enter your email"
            />
          </div> */}
          <div className="submitMe" style={{ marginTop: "0px" }}>
            {waitMsg && <div className="msg error" style={waitStyle}>{waitMsg}</div>}
            <button className="btn start-btn" type="button" onClick={narcSubmitOne} disabled={!mobile_number}>
              Send OTP
            </button>
            <div className="info">A six digit OTP will be sent to your contact details</div>
          </div>
          <div className="form-pagination mt-2 text-center">
            <ul>
              <li className="active"></li>
              <li></li>
            </ul>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegisterStepOne;
