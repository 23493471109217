import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ReactPlayer from "react-player";
import TabData from './Components/TabData';
import PopUp from "../../Components/PopUp";
import { getDigiProfile, getDigiSubmissions } from '../../Redux/Actions/digitalPortfolio';
import moment from 'moment';
import "./style.css";
import Slider from '../../Components/slider';
import { getFirstVideoUrl, isValidString } from '../../helper';
import { MEDIAPATH } from '../../Redux/Actions/config';
import Base from '../../Components/Layout/Base';
import { useNavigate } from 'react-router-dom';

const ProtfolioDetails = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(state => state.auth.user);
  const profile = useSelector(state => state.digiProfile.data);
  const pData = useSelector(state => state.digiProfile.pinSubmission)
  const kids = useSelector(state => state.dashboard.kids);
  const kidId = useSelector(state => state.champions.globalKidId);

  const [ShorllistModal, setShorllistModal] = useState(false);

  useEffect(() => {
    if (kidId) {
      dispatch(getDigiProfile(kidId))
      dispatch(getDigiSubmissions({ kidId: kidId, isPinned: true, isPublic: true, pageNo: 0, pageSize: 1 }))
    }
  }, [kidId])

  const VideoBox = (data) => {
    if (data?.length > 0) {
      const url = getFirstVideoUrl(data?.[0]?.fileUrlDtoList);
      if (url) {
        return (<ReactPlayer
          url={url}
          controls={true}
          height={"350px"}
          width={"100%"}
          className='summary-video'
        />)
      }
    }
  }

  return (
    <Base>
      <div className="mt-5 mt-md-0">
        <section className='profile-banner'></section>
        <section className='main-profile-content'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-3 position-relative'>
                <div className='profile-left-area'>
                  <button onClick={() => navigate(-1)} className='back d-none d-xl-block border-0 bg-transparent p-0'>
                    <img src="../../images/back.svg" />
                  </button>
                  <div className='profile-user-img avatar-bg'>
                    <img src={isValidString(user?.kid?.profileUrl) ? user?.kid?.profileUrl : `${MEDIAPATH}/skin/images/profile/profile.jpeg`} />
                  </div>
                  <div className='profile-user-info'>
                    <h3 className='profile-user-name inter mt-2 mt-xl-3'>{user?.name} <img src={`../../images/${profile?.profile_verified ? 'verify' : 'unverify'}-tag.png`} /></h3>
                    <ul className='profile-info-list inter'>
                      <li><img src="../../images/email.png" /> <span>Email: </span> {user?.emailId}</li>
                      <li><img src='../../images/phone.png' /> <span>Contact No: </span>{user?.mobile_no}</li>
                      <li><img src='../../images/dob.png' /> <span>Gender: </span>{kids?.[0]?.gender}</li>
                      <li><img src='../../images/location.png' /> <span>Address: </span>{user?.city}</li>
                      <li><img src='../../images/dob.png' /> <span>DOB: </span>{kids?.[0]?.date_of_birth && moment(kids?.[0]?.date_of_birth).format("DD MMMM Y")}</li>
                    </ul>
                    {isValidString(profile?.csp_qr_url) &&
                      <div className='qr-code'>
                        <img src={profile?.csp_qr_url} alt="QR code image" />
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div className='col-xl-6 mob-order-1'>
                <div className='profile-main-content-area'>
                  {VideoBox(pData?.data)}
                  <div className='mt-5 my-3'>
                    <TabData />
                  </div>
                </div>
              </div>
              <div className='col-xl-3'>
                <div className='profile-right-area text-center'>
                  {profile?.college_id === 1 && <img src='../../images/iim.png' className='mw-auto object-fit-none' />}
                  <PopUp show={ShorllistModal}>
                    <div className='modal-header'>
                      <h2 className='popup-title'>Shortlist Candidate</h2>
                    </div>
                    <div className='modal-body'>
                      <p className='modal-para'>Are you sure you want to shortlist this candidate?</p>
                    </div>
                    <div className='modal-footer justify-content-between'>
                      <button type='button' className='site-btn border-btn px-5' onClick={() => setShorllistModal(false)}>No</button>
                      <button type='button' className='site-btn blue-btn px-5'>Yes</button>

                    </div>
                  </PopUp>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Base>
  )
}

export default ProtfolioDetails;