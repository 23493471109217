import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import './styles.css';
import { Navigation } from 'swiper/modules';

const MyEventSlider = ({ events }) => {

  let crouselPostDataList = ''
  crouselPostDataList = events?.length && events?.map((item, index) => {
    const isLive = ((moment().diff(item?.start_date, "seconds") >= 0 || moment().diff(item?.registration_start_date, "seconds") >= 0) && moment().diff(item?.end_date, "seconds") <= 0) ? true : false;
    return (
      <SwiperSlide key={index}>
        <div className="competitions-item-outer col-md-12 py-3 px-2">
          <div className="competitions-item">
            <Link to={{ pathname: `/programs/${item?.event_code}`, state: item }}>
              <img className="chmpn-slider-img" src={item?.image_url} alt="activity" />
            </Link>
            <div className="chmpn-slider-content chmpn-register-select-btn px-2 py-3">
              <Link className="nav-link explore-nav" to={{ pathname: `/programs/${item?.event_code}`, state: { ...item, tab: "activities" } }} state={{ ...item, tab: "activities" }}>
                <span>{item?.event_name}</span>
              </Link>
            </div>
          </div>
        </div>
      </SwiperSlide >
    )
  })

  return (
    <div className="competitions-content-slider col-12 py-3 px-0">
      <Swiper
        rewind={true}
        slidesPerView={2.5}
        spaceBetween={9}
        breakpoints={{
          1024: {
            slidesPerView: 2.5,
            spaceBetween: 1
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 1
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 1
          },
          540: {
            slidesPerView: 1,
            spaceBetween: 1
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 2
          },
        }}
        navigation={true}
        modules={[Navigation]}
        className="mySwiper">
        {crouselPostDataList}
      </Swiper>
    </div>
  );
};

export default MyEventSlider;
