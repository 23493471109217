const Modal = (props) => {
  return (
    <div
      className={props.className}
      id={props.modalId}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">{props.children}</div>
      </div>
    </div>
  );
};

export default Modal;
