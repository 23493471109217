import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import { thunk } from 'redux-thunk';
import storage from 'redux-persist/lib/storage/session'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import rootReducer from './reducers';

const initialState = {};
const middleware = [thunk];

const persistConfig = {
  key: 'master-naukri',
  storage,
  stateReconciler: hardSet,
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(persistedReducer, initialState, applyMiddleware(...middleware))
const persistor = persistStore(store)

export { persistor }
export default store;