import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Base from '../../Components/Layout/Base';
import { getQuizSummary, quizCertificate } from '../../Redux/Actions/quiz';
import { APIURL, MEDIAPATH } from '../../Redux/Actions/config';
import './ns-quiz.css'


const EventSummary = () => {

   const dispatch = useDispatch();
   const location = useLocation();

   const kidId = location?.state?.kidId;
   const acm_quiz_id = location?.state?.acm_quiz_id;
   const attempt_id = location?.state?.attempt_id;
   const eventId = location?.state?.eventId;

   const quizSummary = useSelector(state => state.quiz.quizSummary);
   const certificate = useSelector(state => state.quiz.certificate);

   useEffect(() => {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      const data = {
         kidId,
         acm_quiz_id,
         attempt_id
      }
      dispatch(getQuizSummary(data));
      dispatch(quizCertificate({ kidId, competitionId: eventId, certificateType: 3, quizAttemptId: attempt_id }));
   }, [])

   const timeConvert = sec => {
      const min = Math.floor(sec / 60);
      if (min > 1)
         return `${min} min`;
      else
         return `${sec} sec`;
   }

   const getCertificateName = certificateUrl => {
      const name = certificateUrl?.split("/")?.pop()?.split(".")?.shift();
      return name;
   }

   const TextBlock = ({ title, value, type }) => {
      return (<div class="row mb-3">
         <div class="card shadow-sm rounded border-0 p-2">
            <div class="card-body nsquiz-summary">
               <div className="title">
                  <span>{title}</span><span className={`pull-right kidex-text-${type}`}>{value}</span>
               </div>
            </div>
         </div>
      </div>)
   }


   return (
      <Base>
         <div id='ns-quiz-summary-page' className="atl-team-reg atl-tinker-register ns-quiz-summary-con">
            <div className="bannerImg">
               <img id='ns-quiz-summary-banner' style={{ width: "100%" }} src={`${MEDIAPATH}/skin/images/quiz/quiz_summary.jpg`} alt="narc register" />
            </div>
            <div className="container my-5 ns-quiz kidex-theme-font summary-content">
               <TextBlock title="No of questions attempted." value={`${quizSummary?.attempt_question}/${quizSummary?.total_quiz_question}`} type="warning" />
               <TextBlock title="No of correct answers." value={quizSummary?.correct_question} type="success" />
               <TextBlock title="No of incorrect answers." value={quizSummary?.incorrect_question} type="danger" />
               <TextBlock title="Time taken to complete the quiz." value={`${timeConvert(quizSummary?.time_taken)}`} />
               <TextBlock title="Average time taken for each question." value={timeConvert(quizSummary?.average_time_question)} />
               <div className="row my-5">
                  <div className="text-center">
                     <p className="fs-2 fw-bold theme-text-color">Total Score : {quizSummary?.attempt_score}</p>
                  </div>
               </div>
               {certificate?.certificateImageUrl &&
                  <>
                     <div className="fs-5">Congratulations on completing the quiz. Here's your certificate. You can access this certificate anytime in your personal Trophy room on the masterNaukri app. <a className='cstm-link' href="https://play.google.com/store/apps/details?id=com.kidex.app">Check it out here!</a></div>
                     <div className="row mt-5">
                        <img src={`${certificate?.certificateImageUrl}`} class="img-fluid border border-dark p-0 m-0" alt="certificate" />
                     </div>
                  </>
               }
               <iframe style={{ visibility: "hidden", height: "10px" }} src={`${APIURL}/api/certificate/${getCertificateName(certificate?.certificateImageUrl)}`} title="Quiz Certificate"></iframe>
            </div>
         </div>
      </Base>
   )
}

export default EventSummary