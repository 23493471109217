import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Base from "../Components/Layout/Base";
import EventQuestion from "./Components/quiz/EventQuestion";
import { getQuizQuestion, quizComplete, resetQuiz } from "../Redux/Actions/quiz";
import "../css/quiz.css";

const StartEventQuiz = () => {

   const dispatch = useDispatch();
   const navigate = useNavigate();
   const location = useLocation();
   const timerRef = useRef();

   const kidId = location?.state?.kidId;

   const quizHistory = useSelector(state => state.quiz.history);
   const quizData = useSelector(state => state.quiz.quizData);
   const questionData = useSelector(state => state.quiz.questionData);
   const quizLocalData = useSelector(state => state.quiz.localData);

   const [timer, setTimer] = useState(10);

   useEffect(() => {
      if (!quizHistory?.length) {
         navigate(-1);
      }
   }, [quizHistory])

   useEffect(() => {
      const scrollTimer = setTimeout(() => { window.scrollTo(0, 400) }, 1000)
      timerRef.current = setInterval(() => {
         if (timer > 0) setTimer(prev => prev - 1);
      }, 1000);

      return () => {
         clearInterval(timerRef.current);
         clearTimeout(scrollTimer);
      }
   }, []);

   useEffect(() => {
      if (timer < 1) {
         clearInterval(timerRef.current);
      }
      if (timer < 1 && !quizLocalData?.attempt_complete_status) {
         setTimer(0);
         dispatch(quizComplete({ attempt_id: quizData?.attempt_id, time_taken: quizLocalData?.quiz_time_remaining, time_over: true }))
      }
   }, [timer])

   useEffect(() => {
      if (quizLocalData?.quiz_time_remaining) {
         setTimer(parseInt(quizLocalData?.quiz_time_remaining));
      }
   }, [quizLocalData])

   useEffect(() => {
      if (quizData?.attempt_id)
         dispatch(getQuizQuestion({ attempt_id: quizData?.attempt_id, submit_type: "New", quiz_time_remaining: quizLocalData?.quiz_time_remaining }))
      return () => dispatch(resetQuiz());
   }, [quizData])

   const getPercentage = (value, total) => {
      return Math.floor((value / total) * 100);
   }

   const covertTime = (timer) => {
      if (timer > 0) {
         let min = Math.floor(timer / 60);
         let sec = Math.floor(timer % 60);
         if (min < 10) {
            min = `0${min}`
         }
         if (sec < 10) {
            sec = `0${sec}`
         }
         return `${min}:${sec} min`;
      } else {
         return `00:00 min`
      }
   }

   return (
      <Base>
         <div className="start-quiz-wrapper">
            <div className="start-quiz-content-sec wrapper">
               {quizData?.attempt_id ?
                  <>
                     <div className="container quiz-detail-header">
                        <div className="quiz-detail-sec">
                           <div className="quiz-title">
                              <h5>Name of the Quiz</h5>
                              <p>{quizData?.quiz_name}</p>
                           </div>
                           <div className="quiz-title">
                              <h5>Time remaining</h5>
                              <p>{covertTime(timer)}</p>
                           </div>
                        </div>
                     </div>
                     <div className="quiz-progress-bar-sec col-12 text-center">
                        <div className="container">
                           <h6>
                              Quiz level Attempts : <span>{quizData?.attempt}/{quizData?.total_attempt}</span>
                           </h6>
                           <div className="quiz-progress-bar">
                              <div className="progress">
                                 <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{ width: `${getPercentage(questionData?.current_question_number, questionData?.total_quiz_question)}%` }}
                                    aria-valuenow={`${getPercentage(questionData?.current_question_number, questionData?.total_quiz_question)}`}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                 ></div>
                              </div>
                              <span>{questionData?.current_question_number}/{questionData?.total_quiz_question} complete</span>
                           </div>
                        </div>
                     </div>
                     <div className="quiz-questions">
                        <EventQuestion kidId={kidId} />
                     </div>
                  </>
                  : <div className="fs-3 my-5 text-center">{quizData?.message ? quizData?.message : 'Loading...'}</div>
               }
            </div>
         </div>
      </Base>
   );
};

export default StartEventQuiz;
