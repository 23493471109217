import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getQuizActivity } from "../../../../Redux/Actions/quiz";
import "./style.css";

const RegisterUser = ({ event, handleKidChange }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const Activity = useSelector(state => state.quiz.activity);
  const kids = useSelector(state => state.dashboard.kids);



  useEffect(() => {
    dispatch(getQuizActivity({ competition_id: event?.event_id, kid_id: kids?.[0]?.id }))
  }, [])

  const goToQuiz = () => {
    navigate(`/programs/${event?.event_code}/quiz`, { state: { event_code: event?.event_code, eventId: event?.event_id, kidId: kids?.[0]?.id, activityId: Activity?.[0]?.id } });
  }
  return (
    <div className="register-new-child card">
      <div className="card-body">
        <div className="rgs-child-hdng pb-3">Test Your Knowledge Now!</div>
        <button className="btn start-btn" disabled={!kids?.[0]?.id} onClick={goToQuiz}>Start the quiz</button>
      </div>
    </div>
  );
};

export default RegisterUser;
