import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

//pages
import Home from './Pages/Home';
// import Profile from "./Pages/Profile";
// import Eprofile from "./Pages/Eprofile";
import Ppc from "./Pages/PrivacyPolicy";
import Tnc from "./Pages/TermCondition";
import PlayStoreRedirect from "./Pages/PlayStoreRedirect";
import Champions from "./Pages/Champions";
import EventDetailsPage from "./Pages/EventDetailsPage";
import ActivityDetails from "./Pages/ActivityDetails";
import ChampionRegister from "./Pages/Champions/ChampionRegister";
import Account from "./Pages/Account/Account";
import EventQuiz from "./Pages/EventQuiz";
import EventStartQuiz from "./Pages/EventQuiz/EventStartQuiz";
import EventSummary from "./Pages/EventQuiz/EventSummary";
import Login from "./Pages/Login";
import JobFair from "./Pages/JobFair";
import StartEventQuiz from "./Pages/StartEventQuiz";
import EventQuizSummary from "./Pages/EventQuizSummary";
import PersistentSystems from "./Pages/PersistentSystems";
import MasterLogin from "./Pages/MasterLogin/login";
import Summary from "./Pages/ListSummary/summary";
import DigitalPortfolio from "./Pages/DigitalProfile";
import ProtfolioDetails from "./Pages/DigitalProfile/View";

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ppc" element={<Ppc />} />
        <Route path="/tnc" element={<Tnc />} />
        {/* <Route path="/profile" element={<Profile />} /> */}
        {/* <Route path="/eprofile" element={<Eprofile />} /> */}
        <Route path="/myprofile" element={<PlayStoreRedirect />} />
        <Route path="/myaccount" element={<PlayStoreRedirect />} />
        <Route path="/account" element={<Account />} />
        <Route path="/login" element={<Login />} />
        {/* <Route path="/nsdc-job-fair" element={<JobFair />} /> */}
        <Route path="/quiz/start" element={<StartEventQuiz />} />
        <Route path="/quiz/summary" element={<EventQuizSummary />} />
        <Route path="/programs" element={<Champions />} />
        <Route path="/programs/:event_code" element={<EventDetailsPage />} />
        <Route path="/programs/:event_code/activities/:activity_code" element={<ActivityDetails />} />
        <Route path="/programs/:event_code/register/individual" element={<ChampionRegister />} />
        <Route path="/programs/:event_code/quiz" element={<EventQuiz />} />
        <Route path="/programs/:event_code/quiz/start" element={<EventStartQuiz />} />
        <Route path="/programs/:event_code/quiz/summary" element={<EventSummary />} />
        <Route path="/persistent-foundation" element={<PersistentSystems />} />
        <Route path="/portfolio-view" element={<ProtfolioDetails />} />
        <Route path="/digital-portfolio" element={<DigitalPortfolio />} />
        <Route path="/master-login" element={<MasterLogin />} />
        <Route path="/summary" element={<Summary />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
