import React, { Fragment } from 'react';
import ReactPlayer from 'react-player/lazy'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import './style.css';

export default function ({ data, dots = true }) {

   const totalSlides = data?.length ?? 1;

   return (
      <Fragment>
         <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={125}
            totalSlides={totalSlides}
         >
            {dots && <DotGroup className="dotGroup" />}
            <Slider>
               {data?.map((item, index) => {
                  return (
                     item?.fileType?.toLowerCase() === "image" ?
                        <Slide index={index}>
                           <Image
                              src={item?.fileUrl}
                              alt="activity"
                              className="custom-slider-img"
                           />
                        </Slide>
                        : item?.fileType?.toLowerCase() === "video" ?
                           <Slide index={index}>
                              <ReactPlayer
                                 url={item?.fileUrl}
                                 controls={true}
                                 width={"100%"}
                                 height={"100%"}
                              />
                           </Slide>
                           : item?.fileType?.toLowerCase() === "pdf" ?
                              <Slide index={index}>
                                 <div className='pdf-button'>
                                    <a href={item?.fileUrl} target="_blank" rel="noreferrer">
                                       <button className='btn btn-primary p-3'>Click here to View PDF</button>
                                    </a>
                                 </div>
                                 {item?.thumbUrl &&
                                    <Image
                                       src={item?.thumbUrl}
                                       alt="activity"
                                       className="custom-slider-img"
                                    />
                                 }
                              </Slide>
                              : null
                  )
               })}
            </Slider>
            {totalSlides > 1 ?
               <Fragment>
                  <ButtonBack className="buttonBack"><label for="img-4" className="slidePrev">&#x2039;</label></ButtonBack>
                  <ButtonNext className="buttonNext"><label for="img-6" className="slideNext">&#x203a;</label></ButtonNext>
               </Fragment>
               : null}
         </CarouselProvider>
      </Fragment >
   );
}