import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Slider from '../../../Components/slider';
import { getDigiFeeds, getDigiSubmissions } from '../../../Redux/Actions/digitalPortfolio';
import Pagination from 'react-js-pagination';


const DigitalPortfolio = () => {

  const page_size = 5;
  const dispatch = useDispatch();
  const contentDivRef = useRef(null);

  const kidId = useSelector(state => state.champions.globalKidId);
  const sData = useSelector(state => state.digiProfile.publicSubmission);
  const fData = useSelector(state => state.digiProfile.publicFeeds);

  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    if (kidId) {
      dispatch(getDigiSubmissions({ kidId: kidId, isPublic: true, isPinned: false, pageNo: (activePage - 1), pageSize: page_size }))
      dispatch(getDigiFeeds({ kid_id: kidId, isPublic: true, page: (activePage - 1), size: page_size }))
    }
  }, [kidId])

  const handlePageChange = (pageNumber) => {
    scrollToContentDiv();
    const page = pageNumber - 1;
    setActivePage(pageNumber);
    dispatch(getDigiSubmissions({ kidId: kidId, isPublic: true, isPinned: false, pageNo: page, pageSize: page_size }))
    dispatch(getDigiFeeds({ kid_id: kidId, isPublic: true, page, size: page_size }))
  };

  const scrollToContentDiv = () => {
    if (contentDivRef.current) {
      contentDivRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div ref={contentDivRef}>
      {sData?.data?.map(item => {
        return (
          <div className='mb-5'>
            <div className='d-flex justify-content-between align-items-center my-3'>
              <h2 className='title-18 inter'>{item?.competitionName} - {item?.activityName}</h2>
              <button className={`status-btn reviewed`} type='button'>Reviewed</button>
            </div>
            <Slider data={item?.fileUrlDtoList} dots={false} />
          </div>
        )
      })}
      {fData?.data?.map(item => {
        return (
          <div className='mb-5'>
            <div className='d-flex justify-content-between align-items-center my-3'>
              <h2 className='title-18 inter'>{item?.file_meta?.title}</h2>
              <button className={`status-btn not-reviewed`} type='button'>Not Reviewed</button>
            </div>
            <Slider data={item?.file_urls_new} dots={false} />
          </div>
        )
      })}
      {sData?.pagination_info?.total_elements > 0 &&
        <div className="pagination-container justify-content-center d-flex">
          <Pagination
            activePage={activePage}
            itemsCountPerPage={5}
            totalItemsCount={sData?.pagination_info?.total_elements}
            pageRangeDisplayed={5}
            linkClass="custm_pagination"
            onChange={handlePageChange}
            itemClassFirst="page_first"
            itemClassPrev="page_prev"
            itemClassNext="page_next"
            itemClassLast="page_last"
          />
        </div>
      }
      {sData?.data === null && sData?.data?.length === 0 &&
        <div className="text-center fs-5 fw-500">No feeds to show...</div>
      }
    </div>
  )
}

export default DigitalPortfolio