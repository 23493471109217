import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BasicDetails from "./BasicDetails";
import Base from "../../Components/Layout/Base";
import { MEDIAPATH } from "../../Redux/Actions/config";
import "./style.css";
import { CloudFileUpload, generatePresignedURL } from "../../Redux/Actions/cloudFileUpload";
import { deleteUser, updateUser } from "../../Redux/Actions/user";
import { getCityList } from "../../Redux/Actions/dashboard";
import { Confirm } from "notiflix";
import PopUp from "../../Components/PopUp";
import MultiForm from "../../Components/Authentication/Signin";
const initialState = { alt: "loading...", src: null };

const Account = () => {

  const dispatch = useDispatch();

  const user = useSelector(state => state.auth.user);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  const [{ alt, src }, setPreview] = useState(initialState);
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    dispatch(getCityList());
  }, [])

  const fileHandler = (event) => {
    const { files } = event.target;
    if (files?.length) {
      setPreview({ src: URL.createObjectURL(files[0]), alt: files[0].name });
      dispatch(generatePresignedURL((url) => {
        dispatch(CloudFileUpload({ url, file: files[0] }, imageUrl => {
          dispatch(updateUser({ profile_photo: imageUrl }));
        }))
      }))
    }
  }

  return (
    <Base>
      {!isAuthenticated
        ? <div className="col-md-4 offset-md-4 mt-md-0 mt-5"><MultiForm embedded={true} /></div>
        : <div className="wrapper">
          <div className="banner-sec"><img src={`${MEDIAPATH}/skin/images/profile/banner.png`} /></div>
          <div className={`content-sec`}>
            <div className="col-5 col-sm-4 col-md-4 col-lg-4 col-xl-3 side-bar-sec">
              <div className="sider-bar-profile-outer">
                <div className="sidebar-profile rounded-circle">
                  <div className="image-upload" style={{ height: "100%", width: "100%" }}>
                    <label for="file-input" style={{ height: "100%", width: "100%" }} className="profilepic">
                      <img className="preview rounded-circle profilepic__image" src={src ?? user?.profile_photo ?? `${MEDIAPATH}/skin/images/profile/profile.jpeg`} alt={alt} />
                      <div class="profilepic__content"><span className="profilepic__icon"><i className="fas fa-camera"></i></span></div>
                    </label>
                    <input id="file-input" accept="image/*" type="file" onChange={fileHandler} />
                  </div>
                </div>
              </div>
              <div className={`side-bar-menu-sec`}>
                <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  <div
                    className="sidebar-profile-details active tab-btn"
                    id="v-pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-profile"
                    aria-selected="true"
                    style={{ background: "transparent !important", border: "none !important" }}
                  >
                    <h5>{user?.name ?? "Username"}</h5>
                    {user?.city && <p>{`${user?.city ?? ""} ${user?.country ?? ""}`}</p>}
                    {user?.mobile_no && <span>{user?.mobile_no}</span>}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-7 col-sm-8 col-md-8 col-lg-8 col-xl-9 right-content-sec">
              <div className="tab-content" id="v-pills-tabContent">
                <div className={`tab-pane fade show active`} id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profiles-tab">
                  <BasicDetails />
                  <div className="text-center mb-4">
                    <button className="btn btn-secondary rounded-pill px-3" onClick={() => setDeleteModal(true)}>Delete account</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PopUp show={deleteModal} >
            <div className="d-flex flex-column p-3">
              <p className="lh-base">All data associated with your account will be permanently deleted. The deleted information cannot be recovered once deleted. Should you like to proceed with complete deletion of your profile & associated data, please click delete.</p>
              <div className="d-flex justify-content-end align-items-center gap-3">
                <button onClick={() => setDeleteModal(false)} className="btn btn-text-theme fw-600">Cancel</button>
                <button onClick={() => dispatch(deleteUser())} className="btn btn-theme rounded-pill px-4 py-1 fw-600">Delete</button>
              </div>
            </div>
          </PopUp>
        </div>
      }
    </Base>
  )
}

export default Account;
