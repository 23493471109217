import { USER_DATA } from "../Actions/types";

const initialState = {
  userData: {},
}


export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case USER_DATA:
      return { ...state, userData: payload }
    default:
      return state;
  }
}