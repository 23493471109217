import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

import './styles.css';

// import required modules
import { Navigation } from 'swiper/modules';

const Training = () => {
  return (
    <Swiper
      rewind={true}
      slidesPerView={3}
      spaceBetween={9}
      breakpoints={{
        1024: {
          slidesPerView: 3,
          spaceBetween: 1
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 1
        },
        640: {
          slidesPerView: 1,
          spaceBetween: 1
        },
        540: {
          slidesPerView: 1,
          spaceBetween: 1
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 2
        },
      }}
      navigation={true}
      modules={[Navigation]}
      className="mySwiper">
      <SwiperSlide>
        <div className="card employe-card employe-card-first my-5">
          <div className="header-outer">
            <div className="header card-header-first">
              <h5 className="fw-bold">Learning by Doing</h5>
            </div>
          </div>
          <div className="card employe-card-inr employe-card-inr-first">
            <div className="rounded-card"></div>
            <div className="card content-card">
              <p>
                Uniquely short-video assignments to practice skill
                delivery.
              </p>
            </div>
            <div className="circle-card circle-card-first">
              <div className="circle-card-inr">
                <img
                  className="circle-card-icon"
                  src="./images/post-icon1.svg"
                  alt="post1"
                />
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="card employe-card my-5">
          <div className="header-outer">
            <div className="header card-header-second">
              <h5 className="fw-bold">Skill Assessment</h5>
            </div>
          </div>
          <div className="card employe-card-inr employe-card-inr-second">
            <div className="rounded-card"></div>
            <div className="card content-card">
              <p>
                Central ‘expert’ team to grade videos & share feedback.
              </p>
            </div>
            <div className="circle-card circle-card-second">
              <div className="circle-card-inr">
                <img
                  className="circle-card-icon"
                  src="/images/post-icon2.svg"
                  alt="post2"
                />
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="card employe-card my-5">
          <div className="header-outer">
            <div className="header card-header-third">
              <h5 className="fw-bold">Skill Certification</h5>
            </div>
          </div>
          <div className="card employe-card-inr employe-card-inr-third">
            <div className="rounded-card"></div>
            <div className="card content-card">
              <p>Digital certificates to recognize mastering NAUKRI.</p>
            </div>
            <div className="circle-card circle-card-third">
              <div className="circle-card-inr">
                <img
                  className="circle-card-icon"
                  src="/images/post-icon3.svg"
                  alt="post3"
                />
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
}

export default Training
