import axios from "axios";
import { Loading, Notify } from "notiflix";
import { ACTIVITY_DETAILS, ACTIVITY_LIST, CATEGORY_LIST, COMPETITION_RULE, EVENTS_LIST, EVENT_DETAILS, EVENT_KID_ID, EVENT_PROPS, EXP_LIST, GLOBAL_KID_ID, MY_EVENTS, PLAN_DATA, QUIZ_LIST, REG_PERMISSION, SUBMISSION_LIST } from "./types";
import { APIURL, currentEventCode } from "./config";

export const getRegisteredEvents = params => async dispatch => {

   const config = {
      headers: {
         'ContentType': 'application/json',
      },
      data: {}
   };
   const queryString = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
   try {
      Loading.standard();
      const res = await axios.get(`${APIURL}/api/competition/registered/kids?${queryString}`, config);
      Loading.remove();
      if (res?.data?.status) {
         dispatch({ type: MY_EVENTS, payload: res?.data?.data });
      } else if (res?.data?.message) {
         dispatch({ type: MY_EVENTS, payload: [] });
      } else {
         dispatch({ type: MY_EVENTS, payload: [] });
         Notify.warning("something went wrong");
      }

   } catch (err) {
      Loading.remove();
      console.log("err >>", err);
   }
}
// export const getEvents = data => async dispatch => {
//    const { event_status } = data;
//    const { token } = JSON.parse(localStorage.getItem("user"));
//    const config = {
//       headers: {
//          'ContentType': 'application/json',
//          'access-token': token
//       },
//       data: {}
//    };
//    try {
//       Loading.standard();
//       const res = await axios.post(`${APIURL}/api/champions/event/open`, data, config);
//       Loading.remove();
//       if (res?.data?.status) {
//          dispatch({
//             type: event_status === 0 ? OPEN_EVENTS_LIST : event_status === 1 ? BOOKED_EVENTS_LIST : event_status === 2 ? CLOSED_EVENTS_LIST : EVENTS_LIST,
//             payload: res?.data?.data
//          });
//       } else if (res?.data?.message) {
//          dispatch({
//             type: event_status === 0 ? OPEN_EVENTS_LIST : event_status === 1 ? BOOKED_EVENTS_LIST : event_status === 2 ? CLOSED_EVENTS_LIST : EVENTS_LIST,
//             payload: []
//          });
//       } else {
//          dispatch({
//             type: event_status === 0 ? OPEN_EVENTS_LIST : event_status === 1 ? BOOKED_EVENTS_LIST : event_status === 2 ? CLOSED_EVENTS_LIST : EVENTS_LIST,
//             payload: []
//          });
//          Notify.warning("something went wrong");
//       }

//    } catch (err) {
//       Loading.remove();
//       console.log("err >>", err);
//    }
// }

export const getGuestEvents = data => async dispatch => {

   const config = {
      headers: {
         'ContentType': 'application/json',
      }
   };
   try {
      Loading.standard();
      const res = await axios.post(`${APIURL}/api/champions/event/open`, data, config);
      Loading.remove();
      if (res?.data?.status) {
         dispatch({ type: EVENTS_LIST, payload: res?.data?.data });
      } else if (res?.data?.message) {
         dispatch({ type: EVENTS_LIST, payload: [] });
      } else {
         dispatch({ type: EVENTS_LIST, payload: [] });
         Notify.warning("something went wrong");
      }

   } catch (err) {
      Loading.remove();
      console.log("err >>", err);
   }
}

// export const getExperiential = data => async dispatch => {
//    const { event_status } = data;
//    const { token } = JSON.parse(localStorage.getItem("user"));
//    const config = {
//       headers: {
//          'ContentType': 'application/json',
//          'access-token': token
//       },
//       data: {}
//    };
//    try {
//       Loading.standard();
//       const res = await axios.post(`${APIURL}/api/champions/event/open`, data, config);
//       Loading.remove();
//       if (res?.data?.status) {
//          dispatch({
//             type: event_status === 0 ? OPEN_EXP_LIST : event_status === 1 ? BOOKED_EXP_LIST : event_status === 2 ? CLOSED_EXP_LIST : EXP_LIST,
//             payload: res?.data?.data
//          });
//       } else if (res?.data?.message) {
//          dispatch({
//             type: event_status === 0 ? OPEN_EXP_LIST : event_status === 1 ? BOOKED_EXP_LIST : event_status === 2 ? CLOSED_EXP_LIST : EXP_LIST,
//             payload: []
//          });
//       } else {
//          dispatch({
//             type: event_status === 0 ? OPEN_EXP_LIST : event_status === 1 ? BOOKED_EXP_LIST : event_status === 2 ? CLOSED_EXP_LIST : EXP_LIST,
//             payload: []
//          });
//          Notify.warning("something went wrong");
//       }

//    } catch (err) {
//       Loading.remove();
//       console.log("err >>", err);
//    }
// }

export const getGuestExperiential = data => async dispatch => {

   const config = {
      headers: {
         'ContentType': 'application/json',
      }
   };
   try {
      Loading.standard();
      const res = await axios.post(`${APIURL}/api/champions/event/open`, data, config);
      Loading.remove();
      if (res?.data?.status) {
         dispatch({ type: EXP_LIST, payload: res?.data?.data });
      } else if (res?.data?.message) {
         dispatch({ type: EXP_LIST, payload: [] });
      } else {
         dispatch({ type: EXP_LIST, payload: [] });
         Notify.warning("something went wrong");
      }

   } catch (err) {
      Loading.remove();
      console.log("err >>", err);
   }
}

// export const getQuizzes = data => async dispatch => {
//    const { event_status } = data;
//    const { token } = JSON.parse(localStorage.getItem("user"));
//    const config = {
//       headers: {
//          'ContentType': 'application/json',
//          'access-token': token
//       },
//       data: {}
//    };
//    try {
//       Loading.standard();
//       const res = await axios.post(`${APIURL}/api/champions/event/open`, data, config);
//       Loading.remove();
//       if (res?.data?.status) {
//          dispatch({
//             type: event_status === 0 ? OPEN_QUIZ_LIST : event_status === 1 ? BOOKED_QUIZ_LIST : event_status === 2 ? CLOSED_QUIZ_LIST : QUIZ_LIST,
//             payload: res?.data?.data
//          });
//       } else if (res?.data?.message) {
//          dispatch({
//             type: event_status === 0 ? OPEN_QUIZ_LIST : event_status === 1 ? BOOKED_QUIZ_LIST : event_status === 2 ? CLOSED_QUIZ_LIST : QUIZ_LIST,
//             payload: []
//          });
//       } else {
//          dispatch({
//             type: event_status === 0 ? OPEN_QUIZ_LIST : event_status === 1 ? BOOKED_QUIZ_LIST : event_status === 2 ? CLOSED_QUIZ_LIST : QUIZ_LIST,
//             payload: []
//          });
//          Notify.warning("something went wrong");
//       }

//    } catch (err) {
//       Loading.remove();
//       console.log("err >>", err);
//    }
// }

export const getGuestQuizes = data => async dispatch => {
   const config = {
      headers: {
         'ContentType': 'application/json',
      }
   };
   try {
      Loading.standard();
      const res = await axios.post(`${APIURL}/api/champions/event/open`, data, config);
      Loading.remove();
      if (res?.data?.status) {
         dispatch({ type: QUIZ_LIST, payload: res?.data?.data });
      } else if (res?.data?.message) {
         dispatch({ type: QUIZ_LIST, payload: [] });
      } else {
         dispatch({ type: QUIZ_LIST, payload: [] });
         Notify.warning("something went wrong");
      }

   } catch (err) {
      Loading.remove();
      console.log("err >>", err);
   }
}

// export const getCategories = data => async dispatch => {
//    const { eventId, kidId, max_age, min_age } = data;
//    const { token } = JSON.parse(localStorage.getItem("user"));
//    const config = {
//       headers: {
//          'ContentType': 'application/json',
//          'access-token': token
//       },
//       data: {}
//    };
//    try {
//       Loading.standard();
//       const res = await axios.get(`${APIURL}/api/competition/categories?competition_id=${eventId}&page_number=0&page_size=100`, config);
//       Loading.remove();
//       if (res?.data?.status) {
//          dispatch({
//             type: CATEGORY_LIST,
//             payload: res?.data?.data
//          });
//          const params = {
//             category_id: res?.data?.data[0]?.category_id,
//             competition_id: eventId,
//             max_age,
//             min_age
//          }
//          if (kidId) params["kid_id"] = kidId;
//          dispatch(getActivities(params))
//       } else if (res?.data?.message) {
//          Notify.warning(res?.data?.message);
//       } else {
//          Notify.warning("something went wrong");
//       }

//    } catch (err) {
//       Loading.remove();
//       console.log("err >>", err);
//    }
// }

export const getActivities = params => async dispatch => {

   const config = {
      headers: {
         'ContentType': 'application/json',
      },
      data: {}
   };
   const queryString = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
   try {
      Loading.standard();
      const res = await axios.get(`${APIURL}/api/competition/activities/age?${queryString}`, config);
      Loading.remove();
      if (res?.data?.status) {
         dispatch({
            type: ACTIVITY_LIST,
            payload: res?.data?.data
         });
      } else if (res?.data?.message) {
         Notify.warning(res?.data?.message);
      } else {
         Notify.warning("something went wrong");
      }

   } catch (err) {
      Loading.remove();
      console.log("err >>", err);
   }
}

export const getCompetitionRule = data => async dispatch => {

   const config = {
      headers: {
         'ContentType': 'application/json',
      }
   };
   try {
      Loading.standard();
      const res = await axios.post(`${APIURL}/api/competition/rule`, data, config);
      Loading.remove();
      if (res?.data?.status) {
         dispatch({
            type: COMPETITION_RULE,
            payload: res?.data?.data
         });
      } else if (res?.data?.message) {
         Notify.warning(res?.data?.message);
      } else {
         Notify.warning("something went wrong");
      }

   } catch (err) {
      Loading.remove();
      console.log("err >>", err);
   }
}

export const getPlans = params => async dispatch => {

   const config = {
      headers: {
         'ContentType': 'application/json',
      },
      data: {}
   };
   const queryString = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
   try {
      Loading.standard();
      const res = await axios.get(`${APIURL}/api/register/get/registration/plan?${queryString}`, config);
      Loading.remove();
      if (res?.data?.status) {
         dispatch({
            type: PLAN_DATA,
            payload: res?.data?.data
         });
      } else if (res?.data?.message) {
         Notify.warning(res?.data?.message);
      } else {
         Notify.warning("something went wrong");
      }

   } catch (err) {
      Loading.remove();
      console.log("err >>", err);
   }
}

export const getEventByName = params => async dispatch => {
   const config = {
      headers: {
         'ContentType': 'application/json',
      },
      data: {}
   };
   const queryString = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
   try {
      Loading.standard();
      const res = await axios.get(`${APIURL}/api/champions/event/code?${queryString}`, config);
      Loading.remove();
      if (res?.data?.status) {
         dispatch({
            type: EVENT_DETAILS,
            payload: res?.data?.data
         });
      } else if (res?.data?.message == "Not Found" && params?.eventCode != currentEventCode) {
         dispatch({ type: EVENT_DETAILS, payload: { status: false } });
         dispatch(getEventByName({ eventCode: currentEventCode }))
      } else if (res?.data?.message) {
         Notify.warning(res?.data?.message);
      } else {
         Notify.warning("something went wrong");
      }

   } catch (err) {
      if (!err?.response?.data?.status) {
         dispatch({ type: EVENT_DETAILS, payload: { status: false } });
         if (params?.eventCode != currentEventCode)
            dispatch(getEventByName({ eventCode: currentEventCode }))
      }
      Loading.remove();
      console.log("err >>", err.response);
   }
}

export const activityByName = (params, cb) => async dispatch => {

   const config = {
      headers: { 'ContentType': 'application/json' },
      data: {}
   };
   const queryString = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
   try {
      Loading.standard();
      const res = await axios.get(`${APIURL}/api/competition/activity/code?${queryString}`, config);
      Loading.remove();
      if (res?.data?.status) {
         dispatch({
            type: ACTIVITY_DETAILS,
            payload: res?.data?.data
         });
         if (cb) cb();
      } else if (res?.data?.message) {
         Notify.warning(res?.data?.message);
      } else {
         Notify.warning("something went wrong");
      }

   } catch (err) {
      Loading.remove();
      console.log("err >>", err);
   }
}

// export const saveEventAge = age => async dispatch => {
//    dispatch({ type: EVENT_AGE, payload: age });
// }
// export const saveExpAge = age => async dispatch => {
//    dispatch({ type: EXP_AGE, payload: age });
// }
// export const saveQuizAge = age => async dispatch => {
//    dispatch({ type: QUIZ_AGE, payload: age });
// }

export const resetEventDetails = () => async dispatch => {
   dispatch({ type: EVENT_DETAILS, payload: [] })
}

// export const resetEventData = () => async dispatch => {
//    dispatch({ type: EVENT_DATA, payload: [] })
// }

export const eventProps = params => async dispatch => {
   const config = {
      headers: { 'ContentType': 'application/json' },
      data: {}
   };
   const queryString = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
   try {
      Loading.standard();
      const res = await axios.get(`${APIURL}/api/champions/event/properties?${queryString}`, config);
      Loading.remove();
      if (res?.data?.status) {
         dispatch({
            type: EVENT_PROPS,
            payload: res?.data?.data
         });
      } else if (res?.data?.message) {
         Notify.warning(res?.data?.message);
      } else {
         Notify.warning("something went wrong");
      }

   } catch (err) {
      Loading.remove();
      console.log("err >>", err.response);
   }
}

export const resetEventProps = () => async dispatch => {
   dispatch({ type: EVENT_PROPS, payload: {} })
}

// export const eventPrizes = data => async dispatch => {
//    const config = {
//       headers: { 'ContentType': 'application/json' }
//    };
//    try {
//       Loading.standard();
//       const res = await axios.post(`${APIURL}/api/competition/rule`, data, config);
//       Loading.remove();
//       if (res?.data?.status) {
//          if (res?.data?.data) {
//             dispatch({
//                type: EVENT_PRIZES,
//                payload: res?.data?.data
//             });
//          } else {
//             dispatch({ type: EVENT_PRIZES, payload: { status: false } });
//          }
//       } else if (res?.data?.message) {
//          dispatch({ type: EVENT_PRIZES, payload: { status: false } });
//          Notify.warning(res?.data?.message);
//       } else {
//          dispatch({ type: EVENT_PRIZES, payload: { status: false } });
//          Notify.warning("something went wrong");
//       }

//    } catch (err) {
//       Loading.remove();
//       console.log("err >>", err.response);
//    }
// }

export const setEventKidId = id => async dispatch => {
   dispatch({
      type: EVENT_KID_ID,
      payload: id
   });
}

export const setGlobalKidId = id => async dispatch => {
   dispatch({
      type: GLOBAL_KID_ID,
      payload: id
   });
}

export const getRegPermission = (params, cb, notify = true) => async dispatch => {

   const config = {
      headers: {
         'ContentType': 'application/json',
      }
   };
   const queryString = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
   try {
      Loading.standard();
      const res = await axios.post(`${APIURL}/api/register/kid/permission?${queryString}`, {}, config);
      Loading.remove();
      if (res?.data?.status) {
         dispatch({ type: REG_PERMISSION, payload: res?.data?.data });
         if (cb) cb(res?.data?.data);
      } else if (res?.data?.message) {
         if (notify) Notify.warning(res?.data?.message);
         dispatch({ type: REG_PERMISSION, payload: { message: res?.data?.message } });
      } else {
         if (notify) Notify.warning("something went wrong");
         dispatch({ type: REG_PERMISSION, payload: {} });
      }

   } catch (err) {
      Loading.remove();
      console.log("err >>", err);
   }
}

export const getRegistrationPermission = (params, cb) => async dispatch => {

   const config = {
      headers: {
         'ContentType': 'application/json',
      }
   };
   const queryString = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
   try {
      Loading.standard();
      const res = await axios.post(`${APIURL}/api/register/kid/permission?${queryString}`, {}, config);
      Loading.remove();
      if (res?.data?.status) {
         dispatch({ type: REG_PERMISSION, payload: res?.data?.data });
         if (cb) cb(res?.data?.data);
      } else if (res?.data?.message) {
         if (cb) cb(res?.data?.message);
         dispatch({ type: REG_PERMISSION, payload: { message: res?.data?.message } });
      } else {
         Notify.warning("something went wrong");
         dispatch({ type: REG_PERMISSION, payload: {} });
      }

   } catch (err) {
      Loading.remove();
      console.log("err >>", err);
   }
}

export const registerKid = (data, cb) => async dispatch => {

   const config = {
      headers: {
         'ContentType': 'application/json',
      }
   };
   try {
      Loading.standard();
      const res = await axios.post(`${APIURL}/api/register/kid/register/free`, data, config);
      Loading.remove();
      if (res?.data?.status) {
         const message = res?.data?.message ?? "Kid registered succfully!"
         Notify.success(message);
         if (cb) cb();
      } else if (res?.data?.message) {
         Notify.warning(res?.data?.message);
      } else {
         Notify.warning("something went wrong");
      }

   } catch (err) {
      Loading.remove();
      console.log("err >>", err);
   }
}

// export const createTeam = (data, cb) => async dispatch => {

//    const config = {
//       headers: {
//          'ContentType': 'application/json',
//       }
//    };
//    try {
//       Loading.standard();
//       const res = await axios.post(`${APIURL}/api/register/create-team`, data, config);
//       Loading.remove();
//       if (cb) cb(res?.data);
//       // if (res?.data?.status) {
//       //    const message = res?.data?.message ?? "Team created succfully!"
//       //    Notify.success(message);
//       //    if (cb) cb();
//       // } else if (res?.data?.message) {
//       //    Notify.warning(res?.data?.message);
//       // } else {
//       //    Notify.warning("something went wrong");
//       // }

//    } catch (err) {
//       Loading.remove();
//       console.log("err >>", err);
//    }
// }

// export const registerKidForEvent = (data, cb) => async dispatch => {

//    const config = {
//       headers: {
//          'ContentType': 'application/json',
//       }
//    }
//    try {
//       Loading.standard();
//       const res = await axios.post(`${APIURL}/api/entity/kid/logged-in/do-get`, data, config);
//       Loading.remove();
//       if (res?.data?.status) {
//          const message = res?.data?.message ?? "Kid saved."
//          Notify.success(message);
//          dispatch(getKidList());
//          if (cb) cb(res?.data?.body)
//       } else if (res?.data?.message) {
//          Notify.warning(res?.data?.message);
//       } else {
//          Notify.warning("something went wrong");
//       }

//    } catch (err) {
//       Loading.remove();
//       const message = err?.response?.data?.message ?? "internal server error";
//       Notify.failure(message);
//    }
// }

export const getCateoryList = (params, cb) => async dispatch => {
   const config = {
      headers: {
         'ContentType': 'application/json',
      },
      data: {}
   };
   const queryString = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
   try {
      Loading.standard();
      const res = await axios.get(`${APIURL}/api/competition/categories?${queryString}`, config);
      Loading.remove();
      if (res?.data?.status) {
         dispatch({
            type: CATEGORY_LIST,
            payload: res?.data?.data
         });
         if (cb) cb(res?.data?.data?.[0]?.category_id);
      } else if (res?.data?.message) {
         Notify.warning(res?.data?.message);
      } else {
         Notify.warning("something went wrong");
      }

   } catch (err) {
      Loading.remove();
      console.log("err >>", err.response);
   }
}

// export const getActivityList = (params, cb) => async dispatch => {
//    const config = {
//       headers: {
//          'ContentType': 'application/json',
//       },
//       data: {}
//    };
//    const queryString = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
//    try {
//       Loading.standard();
//       const res = await axios.get(`${APIURL}/api/competition/categories?${queryString}`, config);
//       Loading.remove();
//       if (res?.data?.status) {
//          dispatch({
//             type: CATEGORY_LIST,
//             payload: res?.data?.data
//          });
//          if (cb) cb(res?.data?.data?.[0]?.category_id);
//       } else if (res?.data?.message) {
//          Notify.warning(res?.data?.message);
//       } else {
//          Notify.warning("something went wrong");
//       }

//    } catch (err) {
//       Loading.remove();
//       console.log("err >>", err.response);
//    }
// }

// export const getAnnouncement = params => async dispatch => {

//    const config = {
//       headers: {
//          'ContentType': 'application/json',
//       },
//       data: {}
//    };
//    const queryString = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
//    try {
//       Loading.standard();
//       const res = await axios.get(`${APIURL}/api/organizer/messages?${queryString}`, config);
//       Loading.remove();
//       if (res?.data?.status) {
//          dispatch({
//             type: ANNOUNCEMENT_DATA,
//             payload: res?.data?.data
//          });
//       } else if (!res?.data?.status) {
//          dispatch({
//             type: ANNOUNCEMENT_DATA,
//             payload: []
//          });
//       } else {
//          Notify.warning("something went wrong");
//       }

//    } catch (err) {
//       Loading.remove();
//       console.log("err >>", err);
//    }
// }

// export const resetAnnouncement = () => async dispatch => {
//    dispatch({ type: ANNOUNCEMENT_DATA, payload: [] })
// }

export const getKidSubmission = params => async dispatch => {
   const { kidId } = params;
   delete params["kidId"];
   const config = {
      headers: {
         'ContentType': 'application/json',
      },
      data: {}
   };
   const queryString = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
   try {
      Loading.standard();
      const res = await axios.get(`${APIURL}/api/competition/submission/kid/${kidId}?${queryString}`, config);
      Loading.remove();
      if (res?.data?.status) {
         dispatch({
            type: SUBMISSION_LIST,
            payload: res?.data?.data
         });
      } else if (!res?.data?.status) {
         dispatch({
            type: SUBMISSION_LIST,
            payload: {}
         });
      } else {
         Notify.warning("something went wrong");
      }

   } catch (err) {
      Loading.remove();
      console.log("err >>", err);
   }
}

export const resetKidSubmission = () => async dispatch => {
   dispatch({ type: SUBMISSION_LIST, payload: {} })
}