import React from "react";
import Base from "../../Components/Layout/Base";
import MultiForm from "../../Components/Authentication/Signin";

const Login = () => {

  return (
    <Base>
      <div className="col-md-4 offset-md-4 mt-md-0 mt-5"><MultiForm embedded={true} /></div>
    </Base>
  )
}

export default Login;
