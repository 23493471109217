import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className="footer" id="contact">
      <div className="container">
        <div className="row">
          <div className="left-sec col-12 col-sm-6 col-md-4 col-lg-4 mt-5">
            <div className="part-1">
              <div className="logo">
                <img src="/images/logo.svg" alt="logo" />
              </div>
              <div className="dscptn">
                <h6>A KidEx Venture Private Limited company</h6>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-3 col-lg-4 footer-contact-us">
            <div className="part-2">
              <ul className="ps-0">
                <li className="fw-bold mb-2">Contact us</li>
                <li className="mb-2">
                  <a href="mailto:info@kid-ex.com">
                    <span
                    ><i
                      className="fa fa-envelope-o pe-2"
                      aria-hidden="true"
                    ></i></span
                    >info@kid-ex.com</a
                  >
                </li>
                <li>
                  <span><i className="fa fa-whatsapp fa-lg pe-2" aria-hidden="true"></i></span>+91 73031 50200
                </li>
              </ul>
            </div>
          </div>
          <div className="right-sec col-12 col-sm-12 col-md-5 col-lg-4 mt-5">
            <ul>
              <li>
                <a
                  href="https://www.google.com/maps/place/108,+Sector+44,+Gurugram,+Haryana+122022/@28.452097,77.07253,15z/data=!4m5!3m4!1s0x390d18ec6134ea0b:0x6d2dbdd755359b45!8m2!3d28.452097!4d77.07253?hl=en"
                  target="_blank"
                  rel="noreferrer"
                ><span
                ><i
                  className="fa fa-map-marker me-2 mb-3"
                  aria-hidden="true"
                ></i></span
                  >Locate us on Google</a
                >
              </li>
              <li>
                <div className="map master-naukri-map">
                  <iframe
                    title='map'
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.9110725138407!2d77.07034131470199!3d28.452096982489337!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d18ec6134ea0b%3A0x6d2dbdd755359b45!2s108%2C%20Sector%2044%2C%20Gurugram%2C%20Haryana%20122022!5e0!3m2!1sen!2sin!4v1659262449767!5m2!1sen!2sin"
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </li>
              <li className="mt-4">
                <h6>Plot 108, Sector 44, Gurgaon, Haryana</h6>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="terms-conditions">
        <div className="container">
          <p className="copy-right-content">
            <span>©</span> 2022 by KidEx Venture Pvt. Ltd.
          </p>
          <p className="terms-con-link">
            <Link to="/tnc">Terms and Conditions</Link>
            <Link to="/ppc">Privacy Policy</Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer