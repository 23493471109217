
import React, { useEffect } from "react";
import { Confirm } from "notiflix";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { convertTime, convertTimeStamp, isValidString } from "../../helper";
import { getQuizHistory, startQuiz } from "../../Redux/Actions/quiz";
import "./style.css";
import "./responsive.css"
import Base from "../../Components/Layout/Base";

function EventQuiz() {

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const eventId = location.state?.eventId;
  const event_code = location.state?.event_code;
  const activityId = location.state?.activityId;
  const kidId = location.state?.kidId;

  const quizHistory = useSelector(state => state.quiz.history);
  const quizData = useSelector(state => state.quiz.quizData);
  const quizLocalData = useSelector(state => state.quiz.localData);
  const quizQuestionData = useSelector(state => state.quiz.questionData);


  useEffect(() => {
    dispatch(getQuizHistory({ kidId, activity_id: activityId, competition_id: eventId }))
  }, []);

  useEffect(() => {
    if (quizData?.acm_quiz_id && !quizData?.attempt_complete_status && !quizQuestionData?.question_id)
      navigate(`/programs/${event_code}/quiz/start`, { state: { kidId, eventId } });
  }, [quizData])

  const viewSummary = (attempt_id = null) => {
    attempt_id = attempt_id ?? quizHistory?.[0]?.quiz_attempts?.[0]?.attempt_id
    navigate(`/programs/${event_code}/quiz/summary`, {
      state: {
        kidId,
        acm_quiz_id: quizHistory?.[0]?.acm_quiz_id,
        attempt_id: attempt_id,
        eventId,
      }
    });
  }

  const completeTest = () => {
    Confirm.show(
      'masterNaukri - Quiz',
      `Are you ready to complete the quiz ?`,
      'Yes',
      'No',
      function okCb() {
        dispatch(startQuiz({ kidId, acm_quiz_id: quizHistory?.[0]?.acm_quiz_id, attempt_id: quizHistory?.[0]?.incomplete_attempt_id }));
      },
      function cancelCb() { },
      {
        className: 'notiflix-confirm',
        width: '320px',
        borderRadius: '8px',
      },
    );

  }

  const startTest = () => {
    Confirm.show(
      'masterNaukri - Quiz',
      `You have ${Math.floor(quizHistory?.[0]?.quiz_time / 60)} mins to complete the quiz. Good luck!`,
      'Proceed',
      'Cancel',
      function okCb() {
        dispatch(startQuiz({ kidId, acm_quiz_id: quizHistory?.[0]?.acm_quiz_id }));
      },
      function cancelCb() { },
      {
        className: 'notiflix-confirm',
        width: '320px',
        borderRadius: '8px',
      },
    );
  }

  const checkBtnState = () => {
    if (!quizLocalData?.show_complete_button && !quizLocalData?.show_start_button && !quizLocalData?.show_retake_button) {
      return false
    }
    return true
  }

  return (
    <Base>
      <div className="activity-details" id="act-details">
        <div className="wrapper" id="eventQuiz">
          <div className="top-banner-sec"></div>
          <div className="content-sec d-block container mt-5">
            <div className="tab-post-sec tab-pane fade show" id="my-quiz" role="tabpanel" aria-labelledby="my-quiz-tab">
              <div className="container-fluid">
                {quizHistory?.[0]?.quiz_attempts?.length > 0 && checkBtnState() &&
                  <div className="quiz-header-btns">
                    <div className="nav nav-tabs" id="myTab" role="tablist">
                      <button
                        className="nav-link active"
                        id="new-attempt-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#new-attempt"
                        type="button"
                        role="tab"
                        aria-controls="new-attempt"
                        aria-selected="true"
                      >
                        New Attempt
                      </button>
                      <button
                        className="nav-link"
                        id="past-attempt-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#past-attempt"
                        type="button"
                        role="tab"
                        aria-controls="past-attempt"
                        aria-selected="false"
                      >
                        Past Attempt
                      </button>
                    </div>
                  </div>
                }
                <div className="tab-content quiz-content-outer">
                  <div className={`tab-pane ${checkBtnState() || quizHistory?.[0]?.quiz_attempts?.length === 0 ? 'active' : ''} new-attempt-tab-content`} id="new-attempt" role="tabpanel" aria-labelledby="new-attempt-tab">
                    <div className="quiz-detail-card-outer-sec">
                      <div className="card quiz-detail-card">
                        <div className="card-body">
                          <h5>Name of the Quiz</h5>
                          <p>{quizHistory?.[0]?.quiz_name}</p>
                        </div>
                      </div>
                      <div className="card quiz-detail-card">
                        <div className="card-body">
                          <h5>Time</h5>
                          <p>{convertTime(quizHistory?.[0]?.quiz_time)}</p>
                        </div>
                      </div>
                      <div className="card quiz-detail-card">
                        <div className="card-body">
                          <h5>No. of questions</h5>
                          <p>{quizHistory?.[0]?.total_quiz_question}</p>
                        </div>
                      </div>
                      <div className="card quiz-detail-card">
                        <div className="card-body">
                          <p dangerouslySetInnerHTML={{ __html: quizHistory?.[0]?.quiz_instructions?.split("\n\n").join("<br>") }}></p>
                        </div>
                      </div>
                    </div>
                    {isValidString(quizHistory[0]?.message) &&
                      <div className="quiz-end-msg text-center py-2 fw-600">{quizHistory[0]?.message}</div>
                    }
                    <div className="col-12 text-center card-bottom-btn">
                      {quizLocalData?.show_complete_button && <button type="button" onClick={completeTest} className="btn quiz-button-pnk-comn">Complete quiz</button>}
                      {quizLocalData?.show_start_button && <button type="button" onClick={startTest} className="btn quiz-button-pnk-comn">Start the quiz</button>}
                      {quizLocalData?.show_retake_button && <button type="button" onClick={startTest} className="btn quiz-button-pnk-comn">Retake quiz</button>}
                    </div>
                  </div>
                  <div className={`tab-pane ${checkBtnState() && quizHistory?.[0]?.quiz_attempts?.length > 0 ? '' : 'active'}`} id="past-attempt" role="tabpanel" aria-labelledby="past-attempt-tab">
                    {quizHistory?.[0]?.quiz_attempts?.length > 1 &&
                      <div className="past-attem-tab-header">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                          {quizHistory?.[0]?.quiz_attempts?.map((item, index) => {
                            return (<li className="nav-item" role="presentation" key={index}>
                              <button
                                className={`nav-link ${index == 0 ? 'active' : ''}`}
                                id={`attempt-${item?.attempt}-tab`}
                                data-bs-toggle="tab"
                                data-bs-target={`#attempt-${item?.attempt}`}
                                type="button"
                                role="tab"
                                aria-controls={`attempt-${item?.attempt}`}
                                aria-selected="true"
                              >
                                Attempt {item?.attempt}
                              </button>
                            </li>)
                          })}
                        </ul>
                      </div>
                    }
                    <div className="tab-content  past-attempt-tab-content">
                      {quizHistory?.[0]?.quiz_attempts?.map((item, index) => {
                        return (<div
                          key={index}
                          className={`tab-pane ${index == 0 ? 'active' : ''}`}
                          id={`attempt-${item?.attempt}`}
                          role="tabpanel"
                          aria-labelledby={`attempt-${item?.attempt}-tab`}
                        >
                          <div className="score-title col-12 text-center">
                            <h4>Total Score : {item?.attempt_score}</h4>
                          </div>
                          <div className="quiz-detail-card-outer-sec">
                            <div className="card quiz-detail-card">
                              <div className="card-body">
                                <h5>Name of the Quiz</h5>
                                <p>{quizHistory?.[0]?.quiz_name}</p>
                              </div>
                            </div>
                            <div className="card quiz-detail-card">
                              <div className="card-body">
                                <h5>Time</h5>
                                <p>{convertTimeStamp(item?.time_taken)}</p>
                              </div>
                            </div>
                            <div className="card quiz-detail-card">
                              <div className="card-body">
                                <h5>No. of questions</h5>
                                <p>{quizHistory?.[0]?.total_quiz_question}</p>
                              </div>
                            </div>
                            <div className="card quiz-detail-card">
                              <div className="card-body">
                                <p dangerouslySetInnerHTML={{ __html: quizHistory?.[0]?.quiz_instructions?.split("\n\n").join("<br>") }}></p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 text-center card-bottom-btn">
                            <button type="button" onClick={() => viewSummary(item?.attempt_id)} className="btn quiz-button-pnk-comn">View summary</button>
                          </div>
                        </div>)
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Base>
  )
}

export default EventQuiz;
