import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getQuizActivity } from "../../../Redux/Actions/quiz";
import { MEDIAPATH } from "../../../Redux/Actions/config";

const RegisterStepSix = (props) => {

  const dispatch = useDispatch();
  const { competitionId, event_code } = props;
  const { kidId } = props?.data

  const Activity = useSelector(state => state.quiz.activity);

  useEffect(() => {
    if (kidId)
      dispatch(getQuizActivity({ competition_id: competitionId, kid_id: kidId }))
  }, [kidId])

  return (
    <div className="register-new-child card p-4">
      <div className="card-body">
        <div className="head text-center mb-3">
          <div className="icon">
            <img src={`${MEDIAPATH}/skin/images/celebration.png`} alt="Hurray" />
          </div>
          <h4><strong>Hurray!</strong></h4>
          <div className="subtext" style={{ lineHeight: "19px" }}>Your registration is completed. Look forward to your active participation.</div>
        </div>
        <div className="form stepSix">
          <div className="submitMe">
            <Link className="btn start-btn" to={`/programs/${event_code}/quiz`} state={{ event_code: event_code, eventId: competitionId, kidId, activityId: Activity?.[0]?.id }}>Continue</Link>
          </div>
          <div className="form-pagination mt-2 text-center">
            <ul>
              <li className="active"></li>
              <li className="active"></li>
            </ul>
          </div>
        </div>
      </div>
    </div >
  );
};

export default RegisterStepSix;
