import React from "react";
import Modal from 'react-bootstrap/Modal';
import MultiForm from "./Signin";
import { useDispatch, useSelector } from "react-redux";
import { handleLoginModal } from "../../Redux/Actions/auth";

const LoginModel = () => {

  const dispatch = useDispatch();
  const showLoginModal = useSelector(state => state.auth.showLoginModal)

  const handleClose = () => {
    dispatch(handleLoginModal(false));
  }

  return (
    <Modal
      show={showLoginModal}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
    >
      <Modal.Body className="p-0">
        <MultiForm />
      </Modal.Body>
    </Modal>
  )
}

export default LoginModel;
