import React, { useState, useEffect } from "react";
import OTPInput from "otp-input-react";
// import * as HC from "../../../helper/config";
import { useDispatch } from "react-redux";
// import { authenticate, validate } from "../../../redux/actions/auth";
// import { getKidList } from "../../../redux/actions/dashboard";
import { authenticate, validate } from "../../Redux/Actions/auth";
import { APIURL, TENANT_KEY } from "../../Redux/Actions/config";
import { getKidList } from "../../Redux/Actions/dashboard";

const successStyle = {
  color: "green",
  textAlign: "left",
};

const ValidateForm = (props) => {
  // const APIURL = HC.APIURL;

  const dispatch = useDispatch();
  //Get email and mobile number from regisetr step one
  const { number } = props.data
  // Get otp for data
  const [success, setSuccess] = useState("");
  const [resendOTPMsg, setResendOTPMsg] = useState("");
  const [OTP, setOTP] = useState();

  const [cityData, setCityData] = useState({});
  useEffect(() => {
    getClassDataWithFetch();
  }, []);

  const getClassDataWithFetch = async () => {

    fetch(`${APIURL}/api/city/`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Headers": "Content-Type",
        "Content-Type": "application/json",
        "kid-ex-tenant-id": TENANT_KEY
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === true) {
          setCityData(res);
        }
      });
  };

  var cityInfo = "";
  if (cityData !== "") {
    cityInfo = cityData.data;
  }

  //Call Validate mobile number API
  const narcSubmitOne = (e) => {
    e.preventDefault();
    setResendOTPMsg("");
    if (!OTP) {
      setSuccess("Please enter Otp");
    } else if (OTP.length < 6) {
      setSuccess("Please enter valid Otp");
    }
    if (OTP !== "" && OTP !== undefined) {
      dispatch(validate({ mobile: number, secret: OTP }, () => {
        dispatch(getKidList())
        props.onComplete({
          formData: { number }
        });
      }))
    }
  }

  //generate Otp API
  function resendOTP() {
    dispatch(authenticate({ mobile: number }));
  }

  return (
    <div className="formSection">
      <div className="head"><div className="title">Enter verification code</div></div>
      <form onSubmit={narcSubmitOne.bind(this)} className="form stepTwo">
        <div className="verificationTo">
          We have just sent a verification code <br /> to your provided contact
          details.
        </div>
        <div className="formGroup">
          <div className="input digit-group" data-group-name="digits">
            {success && <div className="msg error">{success}</div>}
            <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false} secure />
          </div>
        </div>
        <div className="otpUpdate my-2">
          <div className="resend" onClick={resendOTP}>
            Send the code again
          </div>
        </div>
        {resendOTPMsg && (
          <div className="msg error" style={successStyle}>
            {resendOTPMsg}
          </div>
        )}
        <div className="submitMe my-3">
          <button disabled={OTP?.length != 6}>Enter OTP</button>
        </div>
        <div className="pagenation">
          <ul>
            <li className="current cursor-pointer" onClick={() => props.onComplete({ step: 1 })}></li>
            <li className="current"></li>
          </ul>
        </div>
      </form>
    </div>
  );
};

export default ValidateForm;
