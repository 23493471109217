import React, { Fragment, useState } from "react";
import { Confirm } from "notiflix";
import axios from "axios";
// import { APIURL, MEDIAPATH } from "../../../helper/config";
import img_upld_icon from "../../Assets/images/img-upload-icon.png";
import "./responsive.css";
import SubmissionModal from "../../Components/SubmissionModal";
import { APIURL, MEDIAPATH } from "../../Redux/Actions/config";
const formData = {
    relationship_name: "",
    fileName: "",
};
const arrayOfFile = [];

const ActivitySubmit = ({ setReload, activity, eventDetails, kidId }) => {

    const user = JSON.parse(localStorage.getItem("user"));

    const [relation, setRelation] = useState(formData);
    const [error, setError] = useState("");
    const [fileerror, setFileError] = useState("");
    const [fileArray, setFileArray] = useState([]);
    const [fileData, setFileData] = useState("");
    const [compressing, setCompressing] = useState(false);
    const [msg, setMsg] = useState("");
    const [language, setLanguage] = useState("English");
    const [uploadProgress, setUploadProgress] = useState(0);
    const [show, setShow] = useState(false);


    const onFileChange = (e) => {
        let filesArray = e.target.files;
        let fileDuplicate = "";
        for (let i = 0; i < filesArray.length; i++) {
            let fileObj = {
                name: filesArray[i].name,
                fileSize: filesArray[i].size,
                fileContentType: filesArray[i].type,
                file: filesArray[i].result,
            };
            fileDuplicate = duplicateFileValidation(filesArray[i]);
            if (fileDuplicate === undefined && arrayOfFile.length < 5) {
                arrayOfFile.push(filesArray[i]);
            }
        }
        setFileArray(arrayOfFile);
        setFileData({ uploadedFiles: arrayOfFile });
    };
    //for restriction to upload duplicate files
    const duplicateFileValidation = (meta) => {
        let checkDuplicateFile = arrayOfFile;
        if (checkDuplicateFile.length > 0) {
            return checkDuplicateFile.find(
                (e) =>
                    e.name === meta.name && e.size === meta.size && e.type === meta.type
            );
        }
    };
    const onFileDelete = (arrayIndex) => {
        const newFiles = [...fileArray];
        newFiles.splice(arrayIndex, 1);
        setFileArray(newFiles);
        arrayOfFile.length = 0;
        for (let i = 0; i < newFiles.length; i++) {
            arrayOfFile.push(newFiles[i]);
        }
    };

    const InitiateSubmission = (e) => {
        e.preventDefault();
        const kidName = eventDetails?.kids?.filter(item => item?.kid_id == kidId)?.[0]?.kid_name
        Confirm.show(
            'masterNaukri - Submission',
            `You are submitting for ${kidName} in ${eventDetails?.event_name} under the activity ${activity?.name}`,
            'Yes',
            'No',
            function okCb() {
                setShow(true);
            },
            function cancelCb() { },
            {
                width: '320px',
                borderRadius: '8px',
            },
        );
    }

    const SubmitActivity = consent => {

        // file validation
        const filenew = fileArray.map((index, filenew) =>
            index.name.replace("C:\\fakepath\\", " ")
        );
        //File Size validtion
        const size = fileArray.map((index, file) =>
            parseInt(index.size.toString().slice(0, -3))
        );
        const fileSizeArray = size.reduce((a, v) => (a = a + v), 0);
        const fileSize = fileSizeArray / 1024;
        // file extension array
        var extension = fileArray.map((index, file) =>
            index.name.substr(index.name.lastIndexOf(".") + 1).toLowerCase()
        );
        var defineAllowedExtensions = [
            "pdf",
            "doc",
            "docx",
            "ppt",
            "pptx",
            "zip",
            "jpg",
            "jpeg",
            "png",
            "gif",
            "avi",
            "mp4",
            "wma",
            "mpg",
            "flv",
            "zip",
            "mp3",
            "mov",
            "mpeg",
            "wmv",
            "qt",
            "flv",
            "swf",
            "avchd",
            "webm",
            "mp2",
            "mpe",
            "mpv",
            "ogg",
            "m4p",
            "m4v",
        ];
        var allowedExtensions = defineAllowedExtensions.map((index, file) =>
            index.toLowerCase()
        );
        var extensionResult = extension.every(function (val) {
            return allowedExtensions.indexOf(val) >= 0;
        });

        if (filenew === "" || filenew === undefined) {
            setFileError("Please upload file");
        } else if (filenew !== "" && extensionResult === false) {
            setFileError("Invalid file type");
        } else if (filenew !== "" && fileSize > 1024) {
            setFileError("File size should be <= 1 GB");
        } else {
            setFileError("");
        }

        if (
            fileSize <= 1024 &&
            filenew !== "" &&
            filenew !== undefined &&
            extensionResult === true
        ) {
            const zip = require("jszip")();
            let files = fileArray;
            for (let file = 0; file < fileArray.length; file++) {
                // Zip file with the file name.
                zip.file(files[file].name, files[file]);
            }
            setCompressing(true);
            setMsg("Please wait files are proccessing");
            zip.generateAsync({ type: "blob", compression: 'DEFLATE', compressionOptions: { level: 9 } }).then((content) => {
                setMsg("Please wait files are getting uploaded");
                const formData = new FormData();
                formData.append("file", content);
                formData.append("consent", consent);
                var config = {
                    method: 'post',
                    url: `${APIURL}/api/competition?activityId=${activity?.id}&competitionId=${eventDetails?.event_id}&kidId=${kidId}`,
                    headers: {
                        'accept': 'application/json',
                        'Content-Type': 'multipart/form-data',
                    },
                    data: formData,
                    onUploadProgress: (progressEvent) => {
                        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        setUploadProgress(progress);
                        if (progress === 100) {
                            setMsg("Please wait until server side processing of file is complete.");
                        }
                    },
                };

                axios(config)
                    .then(function (response) {
                        setFileArray([]);
                        arrayOfFile.splice(0, arrayOfFile.length);
                        const msg = response?.data?.status ? "Submission Successful" : response?.data?.message;
                        setMsg(msg);
                        setReload(Math.floor(1000 + Math.random() * 9000))
                        setTimeout(() => { setMsg("") }, 5000);
                    })
                    .catch(function (error) {
                        setFileArray([]);
                        setMsg("internal server error!");
                        setUploadProgress(0);
                        setCompressing(false);
                        arrayOfFile = []
                        setTimeout(() => { setMsg("") }, 5000);
                    });
            });
        } else {
            setMsg("File size is greater than the limit (1GB).");
            setTimeout(() => { setMsg("") }, 5000);
        }
    };

    const handleClose = () => {
        setShow(false);
    }

    const handleSubmit = consent => {
        handleClose();
        SubmitActivity(consent);
    }

    return (
        <Fragment>
            <div className="formfram">
                <div className="formSection">
                    <div className="head">
                        <div className="title">Make your Submission here</div>
                    </div>
                    {error && <div className="msg error">{error}</div>}
                    <form onSubmit={InitiateSubmission} className="form stepTwo mt-0">
                        {/* <div className="formGroup mb-0">
                            <p class="lead fw-bold">Select language</p>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" onChange={e => setLanguage(e.target.value)} id="English" type="radio" name="language" value="English" checked={language === "English"} />
                                <label class="form-check-label" for="English">English</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" onChange={e => setLanguage(e.target.value)} id="Hindi" type="radio" name="language" value="Hindi" checked={language === "Hindi"} />
                                <label class="form-check-label" for="Hindi">Hindi</label>
                            </div>
                        </div> */}
                        <div className="formGroup">
                            <label>File upload (Max 5 files; 1 GB)*</label>
                            <div className="input file-input">
                                <input
                                    type="file"
                                    name="fileName"
                                    id="file-input"
                                    value={relation.fileName}
                                    onChange={onFileChange}
                                    placeholder="Search"
                                    multiple
                                    disabled={fileArray.length > 4}
                                />
                                <label className="file-img" for="file-input">
                                    <img
                                        src={`${MEDIAPATH}/skin/images/upload.png`}
                                        alt="Upload"
                                    />
                                </label>
                                <div className="upload-img-act">
                                    <img className="upload-img" src={img_upld_icon} />
                                </div>
                                <div className="input-label">
                                    <div className="text">
                                        {relation.fileName.replace("C:\\fakepath\\", " ")}
                                    </div>
                                    <div className="text">Drag and Drop here</div>
                                    <div className="text or">or</div>
                                    <div className="text browse">Browse files</div>
                                </div>
                                {fileerror && <div className="msg error">{fileerror}</div>}
                            </div>
                        </div>
                        <div className="formGroup">
                            <ul id="ul">
                                {fileArray.map((content, index) =>
                                    content.name.length > 30 ? (
                                        <li key={index}>
                                            {content.name.slice(0, 30)}...
                                            <svg onClick={() => onFileDelete(index)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="hero-icon cursor-pointer">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </li>
                                    ) : (
                                        <li key={index}>
                                            {content.name}
                                            <svg onClick={() => onFileDelete(index)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="hero-icon cursor-pointer">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </li>
                                    )
                                )}
                            </ul>
                        </div>
                        {msg && <div className="text-danger">{msg}</div>}
                        {uploadProgress > 0 &&
                            <div class="progress mt-1">
                                <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{ width: `${uploadProgress}%` }}>{`${uploadProgress}%`}</div>
                            </div>
                        }
                        <div className="submitMe mt-3">
                            <button type="submit" disabled={fileArray.length < 1 || compressing}> Submit </button>
                        </div>
                    </form>
                </div>
            </div >
            <SubmissionModal show={show} handleClose={handleClose} handleSubmit={handleSubmit} />
        </Fragment >
    );
};

export default ActivitySubmit;
