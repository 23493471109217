import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

import './styles.css';

// import required modules
import { Navigation } from 'swiper/modules';

const Teams = () => {
  return (
    <Swiper
      rewind={true}
      slidesPerView={3}
      spaceBetween={9}
      breakpoints={{
        1024: {
          slidesPerView: 3,
          spaceBetween: 1
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 1
        },
        640: {
          slidesPerView: 1,
          spaceBetween: 1
        },
        540: {
          slidesPerView: 1,
          spaceBetween: 1
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 2
        },
      }}
      navigation={true}
      modules={[Navigation]}
      className="mySwiper">
      <SwiperSlide>
        <div className="card my-1">
          <img
            className="card-img-top"
            src="/images/kapish-saraf.png"
            alt="kapish"
          />
          <div className="card-body text-center">
            <h6 className="card-title fw-bold">Kapish Saraf</h6>
            <p className="card-text">Founder & Director</p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="card my-1">
          <img
            className="card-img-top"
            src="/images/amritanshu-kumar.png"
            alt="amritanshu"
          />
          <div className="card-body text-center">
            <h6 className="card-title fw-bold">Amritanshu Kumar</h6>
            <p className="card-text">Founder & Director</p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="card my-1">
          <img
            className="card-img-top"
            src="/images/gaurav.jpeg"
            alt="gaurav"
          />
          <div className="card-body text-center">
            <h6 className="card-title fw-bold">Gaurav Aggarwal</h6>
            <p className="card-text">Engineering</p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="card my-1">
          <img
            className="card-img-top"
            src="/images/rashi-nigam.jpeg"
            alt="rashi"
          />
          <div className="card-body text-center">
            <h6 className="card-title fw-bold">Rashi Nigam</h6>
            <p className="card-text">Pedagogy</p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="card my-1">
          <img
            className="card-img-top"
            src="/images/jaideep-choudhary.png"
            alt="jaideep"
          />
          <div className="card-body text-center">
            <h6 className="card-title fw-bold">Jaideep Choudhary</h6>
            <p className="card-text">Sales & Partnerships</p>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
}

export default Teams
