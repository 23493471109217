import React, { useState } from "react";
import OTPInput from "otp-input-react";
import { useDispatch, useSelector } from "react-redux";
import { authenticate, handleLoginModal, validate } from "../../Redux/Actions/auth";
import "./form.css"

const ValidateOTP = ({ setStep, embedded }) => {

  const dispatch = useDispatch();

  const [OTP, setOTP] = useState();

  const mobile = useSelector(state => state.auth.mobile);

  const resendOTP = () => {
    dispatch(authenticate({ mobile }))
  }

  const onSubmit = () => {
    dispatch(validate({ mobile, secret: OTP }, () => {
      dispatch(handleLoginModal(false));
      if (embedded) window.location.href = "/programs";
    }))
  }

  return (
    <div className="layout-row">
      <div className="modal-body">
        <div className="row multi-step-kidex-login">
          <div className="col-md-12">
            <div className="modal-header p-0" style={{ justifyContent: "unset" }}>
              <a onClick={() => setStep(1)} style={{ cursor: "pointer", fontSize: "22px", padding: "6px", float: "left" }}>
                <b><i class="fa fa-angle-left" aria-hidden="true"></i></b>
              </a>
              <h4 style={{ width: "100%", textAlign: "center" }}><b>Confirm your number</b></h4>
            </div>
            <p className="otp-description">Enter the OTP we've sent via SMS to <b>{mobile && `+91 ${mobile}`}</b></p>
            <div className="kidex-login user_form_field multi-step">
              <div className="user_form_input">
                <div className="mb-3">
                  <div className="otp-input-section d-flex flex-row justify-content-center">
                    <OTPInput
                      value={OTP}
                      onChange={setOTP}
                      autoFocus
                      OTPLength={6}
                      otpType="number"
                      disabled={false}
                    />
                  </div>
                </div>
                <div className="d-grid gap-2">
                  <button className="multiform-submit-button text-center shadow-none" onClick={onSubmit}>Sign In</button>
                  <p className="resend-otp text-center">Haven’t received an OTP?{" "}
                    <a onClick={resendOTP} style={{ textDecoration: "none", cursor: "pointer" }}>
                      <b style={{ color: "#285caa" }}>Resend</b>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ValidateOTP;
