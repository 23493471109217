import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Base from "../../Components/Layout/Base";
//Forms
import AuthForm from "../../Components/EventRegistrationForms/AuthForm";
import ValidateForm from "../../Components/EventRegistrationForms/ValidateForm";
import ChildDetailForm from "../../Components/EventRegistrationForms/ChildDetailForm";
import SuccessForm from "../../Components/EventRegistrationForms/SuccessForm";
import RegisterStepPayment from "../../Components/EventRegistrationForms/RegisterStepPayment";

import { currentEventCode } from "../../Redux/Actions/config";
import { getCompetitionRule, getEventByName, resetEventDetails } from "../../Redux/Actions/champions";
import banner from "../../Assets/banner/registration.svg"
import "./style.css";


const componentsMap = {
  1: AuthForm,
  2: ValidateForm,
  3: ChildDetailForm,
  9: SuccessForm,
  11: RegisterStepPayment,
};

const ChampionRegister = (props) => {

  const data = props.history?.location?.state || {};
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { event_code } = useParams();

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const CompRule = useSelector(state => state.champions.competition_rule);
  const eventDetails = useSelector(state => state.champions.event_details);

  const [event_id, setEvent_id] = useState(location?.state?.event_id);
  const [event_title, setEvent_title] = useState(location?.state?.event_name)
  const [registration_start_date, setReg_start_date] = useState(location?.state?.registration_start_date)
  const [registration_end_date, setReg_end_date] = useState(location?.state?.registration_end_date)
  const [regAllow, setRegAllow] = useState(null)

  const [state, setState] = useState({
    step: isAuthenticated ? 3 : 1,
    formData: {},
    stepHistory: [],
    ...data,
  });


  const Comp = componentsMap[state.step];

  useEffect(() => {
    return () => {
      dispatch(resetEventDetails())
    }
  }, [isAuthenticated])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    if (eventDetails?.event_id) {
      const isRegAllowed = moment().diff(registration_end_date, "seconds") < 0 && moment().diff(registration_start_date, "seconds") > 0 ? true : false;
      setRegAllow(isRegAllowed);
      dispatch(getCompetitionRule({ competition_id: event_id, property_id: 14 }))
    } else {
      dispatch(getEventByName({ eventCode: event_code }));
    }
  }, [event_id])

  useEffect(() => {
    if (eventDetails?.hasOwnProperty("status") && !eventDetails?.status) {
      navigate(`/champions/${currentEventCode}/register/individual`);
    } else if (eventDetails?.event_id) {
      const isRegAllowed = moment().diff(eventDetails?.registration_end_date, "seconds") < 0 && moment().diff(eventDetails?.registration_start_date, "seconds") > 0 ? true : false;
      setRegAllow(isRegAllowed);
      setEvent_id(eventDetails?.event_id);
      setEvent_title(eventDetails?.event_name);
      setReg_start_date(eventDetails?.registration_start_date);
      setReg_end_date(eventDetails?.registration_end_date);
    }
  }, [eventDetails])

  const handleGoBack = step => {
    const stepHistory = [...state?.stepHistory];
    handleCompNavigation({ step, stepHistory: stepHistory.slice(0, stepHistory.indexOf(step)) })
  }
  const handleCompNavigation = ({ formData = {}, step = 0, stepHistory = [] } = {}) => {
    setState({
      ...state,
      step: step || state?.step + 1,
      stepHistory: [...stepHistory],
      formData: { ...state?.formData, ...formData }
    });
  };

  return (
    <Base>
      <div className="wrapper">
        <div className="chmpn-register-content champion-landing-content">
          <div className={`champion-register-banner chmpn-individual-register-bnnr ${(regAllow !== null && regAllow) ? 'mb-sm-29' : ''}`}>
            <div className="narcreg-formBanner">
              {regAllow !== null && regAllow &&
                <div className="form-frame">
                  <Comp onComplete={handleCompNavigation} data={state?.formData} event_code={event_code} competitionId={event_id} event_title={event_title} stepHistory={state?.stepHistory} goBack={handleGoBack} />
                </div>
              }
              {regAllow !== null && !regAllow &&
                <div className="form-frame">
                  <div className="formSection" style={{ minHeight: "68px", height: "68px", width: "auto" }}>
                    <div className="head">
                      <div className="title">Registration not allowed!</div>
                    </div>
                  </div>
                </div>
              }
            </div>
            <div className="chmpn-landing-bnnr-block">
              <img
                className="chmp-register-bnnr"
                src={`${banner}`}
                alt="champions register"
              />
            </div>
          </div>
          {CompRule?.property4?.body?.length > 0 &&
            <div className="competitions-content">
              <div className="chmpn-container">
                <div className="chmp-pricipate-flow context_narchome">
                  <div className="chmpn-main-hdng text-center">
                    <h3 className="main-hdng-title">How does it work ?</h3>
                  </div>
                  <div className="fs-6 text-center">{CompRule?.property4?.body[0]?.answer?.[0]?.title}</div>
                  <div className="text-center how-it-work">
                    {CompRule?.property4?.body?.map((item, idx) => {
                      return <div><img className="how-it-work-img" src={item?.answer?.[0]?.image} alt="how it work" /></div>
                    })}
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </Base>
  );
};

export default ChampionRegister;
