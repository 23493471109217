import { ACTIVITY_DETAILS, ACTIVITY_LIST, CATEGORY_LIST, COMPETITION_RULE, EVENTS_LIST, EVENT_AGE, EVENT_DETAILS, EXP_AGE, EXP_LIST, GLOBAL_KID_ID, MY_EVENTS, PLAN_DATA, QUIZ_AGE, QUIZ_LIST, REG_PERMISSION, SUBMISSION_LIST } from "../Actions/types";

const initialState = {
  event_details: [],
  eventsList: [],
  activityList: [],
  categoryList: [],
  submissionsList: [],
  activity_details: {},
  globalKidId: "",
  myEvents: [],
  expList: [],
  quizList: [],
  plan_data: {},
  reg_permission: {},
  competition_rule: {},
  event_age: "5",
  exp_age: "5",
  quiz_age: "6",
}


export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case EVENT_DETAILS:
      return { ...state, event_details: payload }
    case ACTIVITY_LIST:
      return { ...state, activityList: payload }
    case CATEGORY_LIST:
      return { ...state, categoryList: payload }
    case ACTIVITY_DETAILS:
      return { ...state, activity_details: payload }
    case EVENT_AGE:
      return { ...state, event_age: payload }
    case EXP_AGE:
      return { ...state, exp_age: payload }
    case QUIZ_AGE:
      return { ...state, quiz_age: payload }
    case EVENTS_LIST:
      return { ...state, eventsList: payload }
    case EXP_LIST:
      return { ...state, expList: payload }
    case QUIZ_LIST:
      return { ...state, quizList: payload }
    case SUBMISSION_LIST:
      return { ...state, submissionsList: payload }
    case GLOBAL_KID_ID:
      return { ...state, globalKidId: payload }
    case COMPETITION_RULE:
      return { ...state, competition_rule: payload }
    case PLAN_DATA:
      return { ...state, plan_data: payload }
    case REG_PERMISSION:
      return { ...state, reg_permission: payload }
    case MY_EVENTS:
      return { ...state, myEvents: payload }
    default:
      return state;
  }
}