import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getActivities, getCateoryList, getCompetitionRule, resetEventDetails, setEventKidId } from "../../Redux/Actions/champions";
import { getAgeByDOB, getGrade, sliceString } from "../../helper";
import { resetQuiz } from "../../Redux/Actions/quiz";
import { MEDIAPATH } from "../../Redux/Actions/config";
import Base from "../../Components/Layout/Base";
import "../../css/narc21-22.css"
import "../../css/narc-submission.css";
import "./eventStyles.css";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import './Components/EventSlider/styles.css'
import { Navigation } from 'swiper/modules';
import banner from "../../Assets/banner/event.svg"
import bannerMb from "../../Assets/banner/event-mb.svg"
import { Tab, Tabs } from "react-bootstrap";


const EventDetails = ({ defaultTab }) => {

  const dispatch = useDispatch();
  const event_code = useParams()?.["event_code"]

  const user = useSelector(state => state.auth.user);
  const globalKidId = useSelector(state => state.champions.globalKidId);
  const eventDetails = useSelector(state => state.champions.event_details);
  const categoryList = useSelector(state => state.champions.categoryList)
  const activityList = useSelector(state => state.champions.activityList)
  const CompRule = useSelector(state => state.champions.competition_rule);

  const [categoryId, setCategoryId] = useState();

  useEffect(() => {
    dispatch(resetQuiz());
    dispatch(getCateoryList({ competition_id: eventDetails?.event_id, page_number: 0, page_size: 100 }, (id) => {
      const cid = id;
      setCategoryId(cid)
    }))
    dispatch(getCompetitionRule({ competition_id: eventDetails?.event_id, property_id: 14 }))
    return () => { dispatch(resetEventDetails()) }
  }, [])

  useEffect(() => {
    if (categoryId && globalKidId) {
      dispatch(getActivities({ category_id: categoryId, competition_id: eventDetails?.event_id, kid_id: globalKidId }))
    } else if (categoryId) {
      dispatch(getActivities({ category_id: categoryId, competition_id: eventDetails?.event_id }))
    }
  }, [categoryId, globalKidId])

  const AllActivitiesSliderData = activityList?.map((item, index) => {
    const grade = getGrade(item?.grade);
    return (
      <SwiperSlide key={index}>
        <div className="col-md-12 px-2">
          <div className="activities-section">
            <Link to={`/programs/${event_code}/activities/${item?.activity_code}`} state={{ event: eventDetails, activity: item, kidId: globalKidId }}>
              <img src={item?.activityDetails?.mediaList[0]?.url} alt="activity" />
              <div className="activities-bottom d-flex justify-content-between align-items-center">
                <h4><span>{(item.name.length > 14 && grade?.grade?.length > 12) ? `${sliceString(item?.name, 14)}` : item.name.length > 23 ? `${sliceString(item?.name, 23)}` : item.name}</span></h4>
                {grade?.grade
                  ? <span className={`pull-right grade-status ${grade?.color} fw-600 fs-15`}>{grade?.resubmission && <span class="grade-resubmit">R</span>} {grade?.grade}</span>
                  : <span className="details">View Details</span>
                }
              </div>
            </Link>
          </div>
        </div>
      </SwiperSlide>
    )
  })

  const checkUserRegisterd = () => {
    if (eventDetails?.kids?.length) {
      return eventDetails?.kids?.[0]?.kid_id !== globalKidId
    } else {
      return true;
    }
  }

  return (
    <Base>
      <div className="allbooking">
        <div className="narcreg-formBanner">
          <div className="bannerImg formtop-banner">
            <img src={`${banner}`} alt="narc register" />
            <img className="mb" src={`${bannerMb}`} alt="narc register" />
            <div className="text-overlay">{eventDetails?.event_name}</div>
          </div>
        </div>
        <div class="main-content context-narchome all-booking my-3">
          <Tabs
            defaultActiveKey={defaultTab ?? "howitwork"}
            id="uncontrolled-tab-example"
            className="mn-cstm-tab d-flex justify-content-center gap-4 my-3"
            variant="pills"
          >
            <Tab className="px-3" eventKey="howitwork" title="How does it work?">
              {CompRule?.property4?.body?.length > 0 &&
                <div className="competitions-content p-0">
                  <div className="chmpn-container">
                    <div className="chmp-pricipate-flow p-0">
                      <div className="fs-6 text-center fw-500 my-md-5 my-4 theme-text-color">{CompRule?.property4?.body[0]?.answer?.[0]?.title}</div>
                      <div className="text-center how-it-work">
                        {CompRule?.property4?.body?.map((item, idx) => {
                          return <div><img className="how-it-work-img" src={item?.answer?.[0]?.image} alt="how it work" /></div>
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              }
            </Tab>
            <Tab eventKey="activities" title="List of activities">
              {categoryList?.length > 0 &&
                <div class="container">
                  <div className="fs-6 text-center fw-500 my-md-5 my-4 theme-text-color">Click on the cards below to view the activity details.</div>
                  <nav class="tab-main2 rounded-0">
                    <div class="nav nav-tabs maxContent" id="nav-tab" role="tablist">
                      {categoryList?.map((item, index) => {
                        return (
                          <button
                            key={index}
                            class={`nav-link ${item.category_id == categoryId ? "active" : ""}`}
                            id={`nav-${item.category_id}-tab`}
                            data-bs-toggle="tab"
                            data-bs-target={`#nav-${item.category_id}`}
                            type="button"
                            role="tab"
                            aria-controls={`nav-${item.category_id}`}
                            aria-selected="true"
                            onClick={() => { setCategoryId(item.category_id) }}
                          >
                            {item.category_name}
                          </button>
                        );
                      })}
                    </div>
                  </nav>
                  <div class="tab-content mt-5" id="nav-tabContent">
                    {categoryList?.map((item, index) => {
                      return (
                        <div
                          class={`tab-pane fade ${item.category_id == categoryId ? "show active" : ""}`}
                          id={`nav-${item.category_id}`}
                          role="tabpanel"
                          key={index}
                          aria-labelledby={`nav-${item.category_id}-tab`}
                        >
                          <Swiper
                            rewind={true}
                            slidesPerView={3}
                            spaceBetween={9}
                            breakpoints={{
                              1024: {
                                slidesPerView: 3,
                                spaceBetween: 1
                              },
                              768: {
                                slidesPerView: 2,
                                spaceBetween: 1
                              },
                              640: {
                                slidesPerView: 1,
                                spaceBetween: 1
                              },
                              540: {
                                slidesPerView: 1,
                                spaceBetween: 1
                              },
                              320: {
                                slidesPerView: 1,
                                spaceBetween: 2
                              },
                            }}
                            navigation={true}
                            modules={[Navigation]}
                            className="mySwiper">
                            {AllActivitiesSliderData}
                          </Swiper>
                          {activityList?.length == 0 && <h3 style={{ textAlign: "center", margin: "40px 0px" }}>No activities found!</h3>}
                        </div>
                      )
                    })}
                  </div>
                </div>}
            </Tab>
          </Tabs>
          {eventDetails?.register_as_individual && checkUserRegisterd() &&
            <div class="narc-btn-plot my-4">
              <Link to={`/programs/${event_code}/register/individual`}>
                <button className="mt-0">Register now</button>
              </Link>
            </div>
          }
        </div>
      </div>
    </Base>
  )
}

export default EventDetails;
