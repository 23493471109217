import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import Moment from 'react-moment';
// import { Icon } from "semantic-ui-react";
import check_icon from "../../Assets/images/check-icon.png";
import delete_icon from "../../Assets/images/delet-icon.png";
import review_icon from "../../Assets/images/review_icon.png";
import review_img from "../../Assets/images/review-img.png";
import img_upld_icon from "../../Assets/images/img-upload-icon.png";
// import { HiDotsVertical } from "@react-icons/all-files/hi/HiDotsVertical";
// import { MEDIAPATH } from '../../../helper/config';
// import Modal from "../Modal";
// import Slider from '../slider';
import SubmissionModal from '../../Components/SubmissionModal';
import { getKidSubmission } from '../../Redux/Actions/champions';
import { getGrade } from '../../helper';
import Slider from '../../Components/slider';
import Modal from '../../Components/Modal';
import { APIURL, MEDIAPATH } from '../../Redux/Actions/config';
import ElipsisVerticalIcon from '../../Components/svg/ElipsisVertical';
import PopUp from '../../Components/PopUp';

const formData = {
  fileName: "",
};
const arrayOfFile = [];

const List = ({ data, submissionAllowed, setReload, Activity, CompId, kidId }) => {

  const initModals = { actionModal: false, confirmModal: false, deleteModal: false, reviewModal: false, submitReviewModal: false }
  const ActId = Activity.id
  const dispatch = useDispatch();

  const token = useSelector(state => state.auth.token);

  const [relation, setRelation] = useState(formData);
  const [submissionId, setSubmissionId] = useState();
  const [resubmitRes, setResubmitRes] = useState();
  const [deleteRes, setDeleteRes] = useState();
  const [responseMsg, setResponseMsg] = useState();
  const [fileData, setFileData] = useState("");
  const [showData, setShowData] = useState("d-none");
  const [resubmit, setResubmit] = useState(false)
  const [fileArray, setFileArray] = useState([]);
  const [currentSubmission, setCurrentSubmission] = useState();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState();
  const [fileSize, setFileSize] = useState(0);
  const [show, setShow] = useState(false);
  const [language, setLanguage] = useState("English");
  const [showModal, setShowModal] = useState(initModals)

  useEffect(() => {
    if (submissionId) {
      const [sData] = data?.filter(item => item.id == submissionId)
      setCurrentSubmission(sData);
    }
  }, [submissionId])

  const submitReview = () => {
    setShowModal({ ...initModals, reviewModal: false })
    setShowModal({ ...initModals, submitReviewModal: true })
    const submissionData = { submissionId }
    var headers = new Headers();
    headers.append("accept", "application/json");
    headers.append("Content-Type", "application/json");
    headers.append("access-token", token);
    headers.append("kid-ex-client", "website");
    const body = JSON.stringify(submissionData)

    var requestOptions = {
      method: "POST",
      headers: headers,
      redirect: "follow",
      body
    };

    fetch(`${APIURL}/api/assesment/review`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.message) {
          // setResponseMsg(result.message);
          setResponseMsg("Review submitted successfully.");
          dispatch(getKidSubmission({ activityId: ActId, competitionId: CompId, isActive: true, kidId }));
        } else if (result.status === false) {
          setResponseMsg(result.data.message);
        }
      })
      .catch((error) => console.log("error", error));
  }

  const deleteSubmission = () => {
    setShowModal({ ...initModals, confirmModal: false })
    setShowModal({ ...initModals, deleteModal: true })

    const submissionData = { submissionId, isActive: false }
    var headers = new Headers();
    headers.append("accept", "application/json");
    headers.append("Content-Type", "application/json");
    headers.append("access-token", token);
    headers.append("kid-ex-client", "website");
    const body = JSON.stringify(submissionData)

    var requestOptions = {
      method: "POST",
      headers: headers,
      redirect: "follow",
      body
    };

    fetch(`${APIURL}/api/competition/submission`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.message)
          setDeleteRes(result.message);
      })
      .catch((error) => console.log("error", error));
  }

  const resubmitActivity = consent => {
    // file validation
    const filenew = fileArray.map((index, filenew) =>
      index.name.replace("C:\\fakepath\\", " ")
    );
    //File Size validtion
    const size = fileArray.map((index, file) =>
      parseInt(index.size.toString().slice(0, -3))
    );
    const fileSizeArray = size.reduce((a, v) => (a = a + v), 0);
    const fileSize = fileSizeArray / 1024;
    // file extension array
    var extension = fileArray.map((index, file) =>
      index.name.substr(index.name.lastIndexOf(".") + 1).toLowerCase()
    );
    var defineAllowedExtensions = [
      "pdf",
      "doc",
      "docx",
      "ppt",
      "pptx",
      "zip",
      "jpg",
      "jpeg",
      "png",
      "gif",
      "avi",
      "mp4",
      "wma",
      "mpg",
      "flv",
      "zip",
      "mp3",
      "mov",
      "mpeg",
      "wmv",
      "qt",
      "flv",
      "swf",
      "avchd",
      "webm",
      "mp2",
      "mpe",
      "mpv",
      "ogg",
      "m4p",
      "m4v",
    ];
    var allowedExtensions = defineAllowedExtensions.map((index, file) =>
      index.toLowerCase()
    );
    var extensionResult = extension.every(function (val) {
      return allowedExtensions.indexOf(val) >= 0;
    });

    const [sData] = data.filter(item => item.id == submissionId);
    const zip = require("jszip")();
    let files = fileArray;
    if (
      (fileSize < 1024 || fileSize === 1024) &&
      filenew !== "" &&
      filenew !== undefined &&
      extensionResult === true
    ) {
      for (let file = 0; file < fileArray.length; file++) {
        // Zip file with the file name.
        zip.file(files[file].name, files[file]);
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        const formData = new FormData();
        formData.append("file", content);
        formData.append("consent", consent);
        // formData.append("language", language);
        var config = {
          method: 'post',
          url: `${APIURL}/api/competition?activityId=${sData.activityId}&competitionId=${sData.competitionId}&kidId=${kidId}`,
          headers: {
            'accept': 'application/json',
            'Content-Type': 'multipart/form-data',
          },
          data: formData,
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(progress);
          },
        };
        axios(config)
          .then(function (resData) {
            setFileArray([]);
            arrayOfFile.splice(0, arrayOfFile.length);
            const msg = resData.status ? "Submitted Successfully!" : resData.message;
            setResubmitRes(msg);
            dispatch(getKidSubmission({ activityId: ActId, competitionId: CompId, isActive: true, kidId }));
          })
          .catch(function (error) {
            setFileArray([]);
            arrayOfFile.splice(0, arrayOfFile.length);
            setResubmitRes("internal server error!");
            console.log("error", error)
          });
      });
    } else {
      setResubmitRes("File size is greater than the limit (1GB).");
      setTimeout(() => { setError("") }, 5000);
    }
  }

  const resubmitSub = () => {
    setResubmit(true);
    setUploadProgress(0);
    setResubmitRes();
    setSubmissionId(data[0].id)
  }

  const onFileChange = (e) => {
    let filesArray = e.target.files;
    let fileDuplicate = "";
    for (let i = 0; i < filesArray.length; i++) {
      let fileObj = {
        name: filesArray[i].name,
        fileSize: filesArray[i].size,
        fileContentType: filesArray[i].type,
        file: filesArray[i].result,
      };
      fileDuplicate = duplicateFileValidation(filesArray[i]);
      if (fileDuplicate === undefined && arrayOfFile.length < 5) {
        arrayOfFile.push(filesArray[i]);
      }
    }
    setFileArray(arrayOfFile);
    setFileData({ uploadedFiles: arrayOfFile });
    checkSetFileSize()
  };
  //for restriction to upload duplicate files
  const duplicateFileValidation = (meta) => {
    let checkDuplicateFile = arrayOfFile;
    if (checkDuplicateFile.length > 0) {
      return checkDuplicateFile.find(
        (e) =>
          e.name === meta.name && e.size === meta.size && e.type === meta.type
      );
    }
  };

  const checkSetFileSize = () => {
    const size = arrayOfFile.map((index, file) => parseInt(index.size.toString().slice(0, -3)));
    const fileSizeArray = size.reduce((a, v) => (a = a + v), 0);
    const fileSize = fileSizeArray / 1024;
    setFileSize(fileSize);
  }

  const onFileDelete = (arrayIndex) => {
    const newFiles = [...fileArray];
    newFiles.splice(arrayIndex, 1);
    setFileArray(newFiles);
    arrayOfFile.length = 0;
    for (let i = 0; i < newFiles.length; i++) {
      arrayOfFile.push(newFiles[i]);
    }
    checkSetFileSize();
  };

  const handleClose = () => {
    setShow(false);
  }

  const handleSubmit = consent => {
    handleClose();
    resubmitActivity(consent)
  }

  const handleActionModal = id => {
    setSubmissionId(id);
    setShowModal({ ...initModals, actionModal: true })
  }

  const handleCloseModal = name => {
    setShowModal({ ...initModals, [name]: false })
  }

  const handleShowModal = name => {
    setShowModal({ ...initModals, [name]: true })
  }

  return (
    <div className="submission-content">
      {data?.map((item, index) => {
        const grade = getGrade(item?.grade);
        return (<div className="submissin-poster" key={index}>
          <div className="container-fluid">
            <div className="submissin-post-heading">
              <h5>Activity : {Activity.name}</h5>
              <span className={`grade-status ${grade?.color}`}>
                {grade?.resubmission && <span class="grade-resubmit">R</span>} {grade?.grade}
              </span>
            </div>
            <div className="submissin-poster-post" >
              <Slider data={item?.fileUrlDtoList} />
            </div>
            {item?.reviewComment &&
              <div className="mt-5">
                <div className="name-title">Assessor remark</div>
                <p>{item?.reviewComment}</p>
              </div>
            }
            <div className="submission-post-footer py-4 d-flex justify-content-between">
              <div className="submission-post-profile d-flex">
                <img src={`${MEDIAPATH}/skin/images/profile/profile.jpeg`}></img>
                <div className="submission-post-details px-3">
                  <h6>{item.userName} <span className="sub-upload-time"><Moment fromNow>{new Date(item.createdAt)}</Moment></span></h6>
                  <span>{item.mobileNo}</span>
                </div>
              </div>
              {(item?.reviewAllowed?.status || item?.deleteAllowed?.status) &&
                <span onClick={() => handleActionModal(item.id)} style={{ width: "40px" }}>
                  <ElipsisVerticalIcon />
                </span>
              }
            </div>
          </div>
        </div>)
      })}
      {submissionAllowed?.status && data?.length > 0 && resubmit == false &&
        <div className="sbmsn-poster-re-submit text-center pt-3 pb-3">
          <p> Not happy with your entry? <span onClick={() => resubmitSub()}>Resubmit</span></p>
        </div>
      }
      {/* <Modal className={"modal fade "} modalId={"submit-review"}>
        <div className="modal-body px-5">
          <div className="mdl-header text-center pb-3">
            <div className="submit-img py-4">
              <img src={check_icon} />
            </div>
            <h5 className="modal-title" id="staticBackdropLabel">{responseMsg ?? "Processing..."}</h5>
          </div>
        </div>
        <div className="mdl-footer pb-3">
          <button
            type="button"
            className="btn activity-pink-btn col-5  col-sm-4"
            data-bs-dismiss="modal"
          >
            Okay
          </button>
        </div>
      </Modal> */}
      {resubmit &&
        <div className="row" id="drop-area">
          <div className="uploader">
            <input
              type="file"
              name="fileName"
              id="fileElem"
              value={relation.fileName}
              onChange={onFileChange}
              placeholder="Search"
              disabled={fileArray.length > 4}
            />
            <div className="input-label">
              <div className="text">
                {relation.fileName.replace("C:\\fakepath\\", " ")}
              </div>
            </div>

          </div>
          <div className="col-sm-6 col-md-6 col-lg-6 uploader-outer">
            <label className="upload-label" htmlFor="fileElem">
              <div className="upload-text">
                <img src={img_upld_icon} />
              </div>
            </label>
          </div>
          {fileSize > 1024 && <p className="text-danger">File size is greater than the limit (1GB).</p>}
          <div className="formGroup">
            <ul id="ul">
              {fileArray.map((content, index) =>
                content.name.length > 30 ? (
                  <li key={index}>
                    {content.name.slice(0, 30)}...
                    <span>
                      {/* <Icon name="close" onClick={() => onFileDelete(index)} /> */}
                    </span>
                  </li>
                ) : (
                  <li key={index}>
                    {content.name}
                    <span>
                      {/* <Icon name="close" onClick={() => onFileDelete(index)} /> */}
                    </span>
                  </li>
                )
              )}
            </ul>
          </div>

          {fileSize <= 1024 && <div className="col-sm-12 text-center my-4">
            <button
              type="button"
              className="activity-pink-btn  btn col-6  col-sm-6 col-md-4 col-lg-2 "
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            >
              Done
            </button>
          </div>
          }
        </div>
      }
      <Modal className={"modal fade "} modalId={"staticBackdrop"}>
        <div className="modal-body px-5">
          <div className="mdl-header text-center pb-3">
            <h5 className="modal-title" id="staticBackdropLabel">
              Confirm Submission
            </h5>
          </div>
          <p>You are submitting for {currentSubmission?.kidName} in {currentSubmission?.competitionName} under the activity {Activity.name}</p>
        </div>
        <div className="mdl-footer pb-3">
          <button
            type="button"
            className="btn modal-close-btn col-5  col-sm-4"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button
            className="btn activity-pink-btn col-5 col-sm-4"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
            // data-bs-target="#languages"
            data-bs-target="#submit-sbmsn"
            onClick={() => setShow(true)}
          >
            Continue
          </button>
        </div>
      </Modal>
      <Modal className={"modal fade "} modalId={"languages"}>
        <div className="modal-body px-5">
          <div className="mdl-header text-center pb-3">
            <h5 className="modal-title" id="staticBackdropLabel">
              Choose language
            </h5>
          </div>
          <p>
            Select the language that have been used to complete this
            particular activity.
          </p>
          <div className="select-language py-3">
            <div className="form-check">
              <input className="form-check-input" onChange={e => setLanguage(e.target.value)} id="English" type="radio" name="language" value="English" checked={language === "English"} />
              <label className="form-check-label" for="English">English</label>
            </div>
            <div className="form-check">
              <input className="form-check-input" onChange={e => setLanguage(e.target.value)} id="Hindi" type="radio" name="language" value="Hindi" checked={language === "Hindi"} />
              <label className="form-check-label" for="Hindi">Hindi</label>
            </div>
          </div>
        </div>
        <div className="mdl-footer pb-3">
          <button
            type="button"
            className="btn modal-close-btn col-5  col-sm-4"
            data-bs-dismiss="modal"
          >
            Go back
          </button>
          <button
            className="btn activity-pink-btn col-5 col-sm-4"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
            data-bs-target="#submit-sbmsn"
            onClick={() => setShow(true)}
          >
            Continue
          </button>
        </div>
      </Modal>
      <Modal className={"modal fade "} modalId={"submit-sbmsn"}>
        <div className="modal-body px-5">
          <div className="mdl-header text-center pb-3">
            <div className="submit-img py-4">
              {/* <img src={check_icon} /> */}
            </div>
            <h5 className="modal-title" id="staticBackdropLabel">
              {resubmitRes ? resubmitRes : (uploadProgress == 100) ? "Please wait until server side processing of file is complete." : "uploading..."}
            </h5>
            <div className="progress mt-1">
              <div className="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{ width: `${uploadProgress}%` }}>{`${uploadProgress}%`}</div>
            </div>
          </div>
        </div>
        <div className="mdl-footer pb-3">
          {resubmitRes && <button type="button" onClick={() => { setResubmit(false) }} className="btn activity-pink-btn col-5 col-sm-4" data-bs-dismiss="modal">Okay</button>}
        </div>
      </Modal>
      <SubmissionModal show={show} handleClose={handleClose} handleSubmit={handleSubmit} />
      <PopUp show={showModal?.actionModal} handleClose={() => handleCloseModal("actionModal")}>
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="btn-close" onClick={() => handleCloseModal("actionModal")}></button>
          </div>
          <div className="modal-body">
            <div className="resubmit-mdl-btn col-sm-12 text-center  my-4">
              {data?.[0]?.reviewAllowed?.status &&
                <button onClick={() => handleShowModal("reviewModal")} type="button" className="activity-pink-btn btn my-2">
                  <img src={review_icon} width={24} />
                  <span className="ms-2 mx-auto">Raise a review request</span>
                </button>
              }
              {data?.[0]?.deleteAllowed?.status &&
                <button onClick={() => handleShowModal("confirmModal")} type="button" className="modal-close-btn btn my-2">
                  <img src={delete_icon} width={24} alt="icon" />
                  <span className="ms-2 mx-auto">Delete this submission</span>
                </button>
              }
            </div>
          </div>
        </div>
      </PopUp>
      <PopUp show={showModal?.confirmModal} handleClose={() => handleCloseModal("confirmModal")}>
        <div className="modal-body px-5">
          <div className="mdl-header text-center pb-3">
            <h5 className="modal-title pb-3" id="staticBackdropLabel">Confirm Delete</h5>
            <p className="m-0">Are you sure you want to detele the submission? Submissions can be deleted within 30 mins only.</p>
          </div>
        </div>
        <div className="mdl-footer pb-3">
          <button type="button" className="btn modal-close-btn col-5 col-sm-4" onClick={() => handleCloseModal("confirmModal")}>Cancel</button>
          <button
            className="btn activity-pink-btn col-5 col-sm-4"
            onClick={() => deleteSubmission()}
          >
            Continue
          </button>
        </div>
      </PopUp>
      <PopUp show={showModal?.deleteModal} handleClose={() => handleCloseModal("deleteModal")}>
        <div className="modal-body px-5">
          <div className="mdl-header text-center pb-3">
            <div className="submit-img py-4"></div>
            <h5 className="modal-title" id="staticBackdropLabel">{deleteRes ?? "Processing..."}</h5>
          </div>
        </div>
        <div className="mdl-footer pb-3">
          <button type="button" className="btn activity-pink-btn col-5  col-sm-4" onClick={() => setReload(submissionId)}>Okay</button>
        </div>
      </PopUp>
      <PopUp show={showModal?.reviewModal} handleClose={() => handleCloseModal("reviewModal")}>
        <div className="modal-body px-5">
          <div className="mdl-header text-center pb-3">
            <div className="submit-img py-4"><img src={review_img} height="100px" alt="icon" /></div>
            <h5 className="modal-title pb-3" id="staticBackdropLabel">Confirm Review</h5>
            <p className="m-0">
              Are you sure you want to raise a review? <br />
              Reviews can be raised only within 7 days of any grade sharing.
            </p>
          </div>
        </div>
        <div className="mdl-footer pb-3">
          <button type="button" onClick={() => handleCloseModal("reviewModal")} className="btn modal-close-btn col-5 col-sm-4">Cancel</button>
          <button onClick={() => submitReview()} className="btn activity-pink-btn col-5 col-sm-4">Continue</button>
        </div>
      </PopUp>
      <PopUp show={showModal?.submitReviewModal} handleClose={() => handleCloseModal("submitReviewModal")}>
        <div className="modal-body px-5">
          <div className="mdl-header text-center pb-3">
            {/* <div className="submit-img py-4"><img src={check_icon} /></div> */}
            <h5 className="modal-title" id="staticBackdropLabel">{responseMsg ?? "Processing..."}</h5>
          </div>
        </div>
        <div className="mdl-footer pb-3">
          <button onClick={() => handleCloseModal("submitReviewModal")} type="button" className="btn activity-pink-btn col-5  col-sm-4">Okay</button>
        </div>
      </PopUp>
    </div>
  )
}

export default List