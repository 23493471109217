import React from 'react'
import ReactPlayer from "react-player";
const SummaryList = () => {
    return (
        <>
            <div className='user-detail-main mb-4 d-none d-md-block'>
                <div className='row'>
                    <div className='col-md-6 col-xl-7 d-flex flex-wrap'>
                        <div className='pe-md-2 pe-xl-5'>
                            <div className='profile-user-img m-0'>
                                <img src="../../images/user-profile.png" />
                                <svg>
                                    <defs>
                                    <path id="curve" d="M 15,85 A -108,-108 0 0 1 140,40" />
                                    </defs>
                                    <text fill="black" font-size="24">
                                    <textPath href="#curve">
                                        Unplaced
                                    </textPath>
                                    </text>
                                </svg>
                            </div>
                            <h3 className='profile-user-name inter justify-content-start mt-2 mt-xl-3'>Nikhil Kumar <img src="../../images/verify-tag.png" /></h3>
                            <div>
                                <div className='content-row inter  mb-3 mb-xl-4'>
                                    <p className='skill-sub-title'>Gender : Male</p>
                                </div>
                                <div className='content-row inter  mb-3 mb-xl-4'>
                                    <h2 className='skill-title mb-2 mb-xl-3'>WORK EXP</h2>
                                    <p className='skill-sub-title'>14 months</p>
                                </div>
                                <div className='content-row inter mb-4'>
                                    <h2 className='skill-title mb-2 mb-xl-3'>AREA OF INTEREST</h2>
                                    <p className='skill-sub-title mb-2'>Digital Marketing</p>
                                    <p className='skill-sub-title mb-0'>Visual Design</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='content-row inter mb-4'>
                                <h2 className='skill-title mb-2 mb-xl-3'>Education</h2>
                                <p className='skill-sub-title mb-2'>Post Grad : IIM, Sirmaur</p>
                                <p className='skill-sub-title mb-0'>MBA </p>
                            </div>
                            <div className='content-row inter mb-4'>
                                <p className='skill-sub-title mb-2'>Graduation : National Institute of Design</p>
                                <p className='skill-sub-title mb-0'>Bachelors of Design | 89% </p>
                            </div>
                            <div className='content-row inter mb-4 mb-md-2 mb-lg-4'>
                                <p className='skill-sub-title mb-0'>12th  | CBSE | 89% </p>
                            </div>
                            <div className='content-row inter mb-4'>
                                <p className='skill-sub-title mb-0'>10th | CBSE | 79%</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 col-xl-5'>
                    <ReactPlayer
                        url="https://www.youtube.com/watch?v=xn4h81XH0Lk"
                        controls={true}
                        height={"270px"}
                        width={"100%"}
                        className='summary-video'
                    />
                        <div className='d-md-flex flex-md-column flex-lg-row mt-4'>
                            <button type='button' className='site-btn border-btn flex-1 me-2 fs-14 px-2 mb-md-2 mb-lg-0'>Shortlist candidate  </button>
                            <button type='button' className='site-btn blue-btn flex-1 fs-14 px-2'>Digital Portfolio </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* For Mobile */}
            <div className='user-detail-main mb-4 d-block d-md-none'>
                <div className='pe-md-5 flex-1'>
                    <div className='d-flex justify-content-between'>
                        <div>
                            <div className='profile-user-img m-0'>
                                <img src="../../images/user-profile.png" />
                                <svg>
                                    <defs>
                                    <path id="curve" d="M 15,85 A -108,-108 0 0 1 140,40" />
                                    </defs>
                                    <text fill="black" font-size="24">
                                    <textPath href="#curve">
                                        Unplaced
                                    </textPath>
                                    </text>
                                </svg>
                            </div>
                            <h3 className='profile-user-name inter justify-content-start mt-1 mt-md-3'>Nikhil Kumar <img src="../../images/verify-tag.png" /></h3>
                        </div>
                        <div className='d-flex flex-column'>
                            <button type='button' className='site-btn border-btn flex-1 mb-2 fs-14 px-2 '>Shortlist candidate  </button>
                            <button type='button' className='site-btn blue-btn flex-1 fs-14 px-2'>Digital Portfolio </button>
                        </div>
                    </div>
                    
                    <ReactPlayer
                        url="https://www.youtube.com/watch?v=xn4h81XH0Lk"
                        controls={true}
                        height={"270px"}
                        width={"100%"}
                    />
                </div>
                        
                <div className='row mt-3'>
                    <div className='col-5'>
                        <div>
                            <div className='content-row inter mb-2'>
                                <p className='skill-sub-title'>Gender : Male</p>
                            </div>
                            <div className='content-row inter  mb-3 mb-xl-4'>
                                <h2 className='skill-title mb-1'>WORK EXP</h2>
                                <p className='skill-sub-title'>14 months</p>
                            </div>
                            <div className='content-row inter mb-4'>
                                <h2 className='skill-title mb-2'>AREA OF INTEREST</h2>
                                <p className='skill-sub-title mb-1'>Digital Marketing</p>
                                <p className='skill-sub-title mb-0'>Visual Design</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-7'>
                        <div>
                            <div className='content-row inter mb-3'>
                                <h2 className='skill-title mb-2'>Education</h2>
                                <p className='skill-sub-title mb-2'>Post Grad : IIM, Sirmaur</p>
                                <p className='skill-sub-title mb-0'>MBA </p>
                            </div>
                            <div className='content-row inter mb-3'>
                                <p className='skill-sub-title mb-1'>Graduation : National Institute of Design</p>
                                <p className='skill-sub-title mb-0'>Bachelors of Design | 89% </p>
                            </div>
                            <div className='content-row inter mb-3'>
                                <p className='skill-sub-title mb-0'>12th  | CBSE | 89% </p>
                            </div>
                            <div className='content-row inter'>
                                <p className='skill-sub-title mb-0'>10th | CBSE | 79%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SummaryList