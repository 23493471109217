import React, { Fragment, useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import QuizEventDetails from './Quiz';
import EventDetails from './Champions/EventDetails';
import { currentEventCode } from '../Redux/Actions/config';
import { getEventByName, resetEventDetails } from '../Redux/Actions/champions';

const EventDetailsPage = () => {

  const { event_code } = useParams();
  const { state } = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const eventDetails = useSelector(state => state.champions.event_details);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (eventDetails?.hasOwnProperty("status") && !eventDetails?.status) {
      navigate(`/programs/${currentEventCode}`);
    } else if (eventDetails?.hasOwnProperty("event_id")) {
      setLoaded(true);
    }
  }, [eventDetails])

  useEffect(() => {
    dispatch(getEventByName({ eventCode: event_code }));
    return () => {
      dispatch(resetEventDetails())
    }
  }, [isAuthenticated])

  return (
    <Fragment>
      {(loaded && eventDetails?.hasOwnProperty("event_id")) ?
        <>{(eventDetails?.hasOwnProperty("is_quiz_only") && eventDetails?.is_quiz_only) ?
          <QuizEventDetails />
          : <EventDetails defaultTab={state?.tab} />
        }</>
        : <div className="d-flex justify-content-center align-items-center vh-100 wh-100 fs-1">Loading...</div>
      }
    </Fragment>
  )
}

export default EventDetailsPage