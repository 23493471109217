import React from 'react'
import SummaryList from './SummaryList';

const PageLayout = () => {
    return (
        <>
            <div className='container summary-container'>
                <div className='row mt-5'>
                    <div className='col-md-3'>

                    </div>
                    <div className='col-md-9'>
                        <div className='sorting-dropdown inter d-flex justify-content-end align-items-center'>
                            <span>Sort By :</span>
                            <select className='form-control w-auto'>
                                <option>10th Marks</option>
                            </select>
                        </div>
                        <SummaryList />
                        <SummaryList />
                        <SummaryList />
                        <SummaryList />
                        <SummaryList />
                        <SummaryList />
                        <SummaryList />
                    </div>
                </div>
            </div>
            
        </>
    )
}

export default PageLayout;