import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from "react-router-dom";
// import * as HC from "../../../helper/config";
// import { MEDIAPATH } from '../../../helper/config';
// import { getPlans } from '../../../redux/actions/champions';
import { useLocation, useNavigate } from 'react-router-dom';
import { APIURL, MEDIAPATH } from '../../Redux/Actions/config';
import { getPlans } from '../../Redux/Actions/champions';

const RegisterStepPayment = (props) => {

   // const APIURL = HC.APIURL;
   const stepHistory = props?.stepHistory?.indexOf(11) > -1 ? [...props.stepHistory] : [...props.stepHistory, 11]

   const { competitionId, event_title } = props;
   const { name } = props.data

   let category4 = 'Others';
   const navigate = useNavigate();
   const location = useLocation();
   const dispatch = useDispatch();

   const city = location.state?.city;

   const user = useSelector(state => state.auth.user);
   const token = useSelector(state => state.auth.token);
   const kidId = useSelector(state => state.champions.globalKidId)
   const RegPermission = useSelector(state => state.champions.reg_permission)

   if (city?.includes("India") || city?.includes("india")) {
      category4 = 'India';
   }

   //Set order failue messages 
   const [orderFail, setOrderFailMsg] = useState('');
   const [scriptFailMsg, setScriptFailMsg] = useState('');

   const plans = useSelector(state => state.champions.plan_data);

   useEffect(() => {
      dispatch(getPlans({ kid_id: kidId, competition_id: props?.competitionId }))
   }, [])

   function loadScript(src) {
      const script = document.createElement('script');
      script.src = src;
      return document.body.appendChild(script);
   }


   const narcSubmitOne = (e) => {
      e.preventDefault();
   }

   let sendRazorpayRequest = '';

   const registerForComp = (productId) => {
      const rzp = loadScript('https://checkout.razorpay.com/v1/checkout.js');
      if (!rzp) {
         setScriptFailMsg("Razorpay SDK failed to load.");
      }
      const requestOptions = {
         method: "POST",
         headers: {
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            "access-token": token,
            "kid-ex-client": "website",
         },
         body: JSON.stringify({
            kidId,
            competitionId,
            productSKUId: productId,
            emailId: user?.email
         })
      };

      // Call fetch function to send request to api
      fetch(`${APIURL}/api/register/kid/register/v2`, requestOptions)
         .then((response) => response.json())
         .then((res) => {
            if (res.status) {
               const data = {
                  "bookingId": res.data.bookingId,
                  "totalAmount": res.data.productDetail.razorPayAmount,
                  "paymentType": res.data.paymentType,
                  "productQuantityDto": {
                     "productId": res.data.productDetail.productQuantityDto.productId,
                     "productName": res.data.productDetail.productQuantityDto.productName,
                     "discountAmount": res.data.productDetail.productQuantityDto.discountAmount,
                     "totalPrice": res.data.productDetail.productQuantityDto.totalPrice,
                     "finalPrice": res.data.productDetail.productQuantityDto.finalPrice,
                     "basePrice": res.data.productDetail.productQuantityDto.basePrice,
                     "gst": res.data.productDetail.productQuantityDto.gst,
                     "discountPercentage": res.data.productDetail.productQuantityDto.discountPercentage,
                  },
                  "razorPayAmount": res.data.productDetail.razorPayAmount
               }
               createPurchase(data);
            } else {
               const message = res?.message ?? "something went wrong";
               setOrderFailMsg(message);
               setTimeout(() => { setOrderFailMsg(""); }, 3000)
            }
         });
   }

   const createPurchase = (data) => {
      const requestOptions = {
         method: "POST",
         headers: {
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            "access-token": token,
            "kid-ex-client": "website",
         },
         body: JSON.stringify(data)
      };
      fetch(`${APIURL}/api/purchase/create`, requestOptions)
         .then((response) => response.json())
         .then((res) => {
            if (res.status) {
               razorPayOrderRequest(
                  res?.data?.razorPayOrderID,
                  data?.totalAmount,
                  data.productQuantityDto?.productName,
                  name,
                  user?.emailId,
                  user?.mobile_no,
                  data.paymentType, res?.data?.razorpayKey,
                  res?.data?.invoiceId,
                  res?.data?.razorPayId
               );
            } else {
               const message = res?.message ?? "something went wrong";
               setOrderFailMsg(message);
               setTimeout(() => { setOrderFailMsg(""); }, 3000)
            }
         });
   }

   const updatePurchase = (data) => {
      const requestOptions = {
         method: "POST",
         headers: {
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            "access-token": token,
            "kid-ex-client": "website",
         },
         body: JSON.stringify(data)
      };

      // Call fetch function to send request to api
      fetch(`${APIURL}/api/purchase/update`, requestOptions)
         .then((response) => response.json())
         .then((responseData) => {
            const { status, data } = responseData;
            if (status && data.invoicePaymentStatus) {
               props.onComplete({ step: 9, stepHistory })
            } else {
               setOrderFailMsg("Payment Failed");
               setTimeout(() => { setOrderFailMsg(""); }, 3000)
            }

         });
   }

   function razorPayOrderRequest(orderID, totalAmt, planName, name, email, number, PaymentType, razorpayKey, invoiceId, razorPayId) {
      let options = {
         "key": razorpayKey,
         "amount": totalAmt,
         "name": planName,
         "description": event_title,
         "order_id": orderID,
         "handler": function (response) {
            if (response.razorpay_order_id === orderID && response.razorpay_payment_id !== '' && response.razorpay_order_id !== '' && response.razorpay_signature !== '') {
               const data = {
                  "paymentStatus": true,
                  "razorPayOrderID": orderID,
                  "razorPayPaymentID": response?.razorpay_payment_id,
                  "razorPaySignature": response?.razorpay_signature,
                  "paymentType": PaymentType,
                  "invoiceId": invoiceId,
                  "razorPayID": razorPayId
               }
               updatePurchase(data);
            } else {
               const data = {
                  "paymentStatus": false,
                  "razorPayOrderID": orderID,
                  "paymentType": PaymentType,
                  "invoiceId": invoiceId,
                  "razorPayID": razorPayId
               }
               updatePurchase(data);
            }
         },
         "prefill": {
            "name": name,
            "email": email,
            "contact": number
         },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
      paymentObject.on('payment.failed', function (response) {
         if (response.error.code === 'BAD_REQUEST_ERROR' && response.error.reason === "payment_failed") {
            const data = {
               "paymentStatus": false,
               "razorPayOrderID": orderID,
               "paymentType": PaymentType,
               "invoiceId": invoiceId,
               "razorPayID": razorPayId
            }
            updatePurchase(data);
         }
      });
   }

   const freeTeamReg = () => {
      props.onComplete({
         step: 10,
         formData: { kidId },
         stepHistory
      })
   }

   return (
      <div className="formSection">
         <div className="head">
            <div className="title">
               Select registration type
            </div>
            <div className="typeInfo">Please select your preferred option</div>
         </div>



         {scriptFailMsg && (
            <div className="msg error">{scriptFailMsg}</div>
         )}

         <form onSubmit={narcSubmitOne} className="form stepFour">
            <div className="accountPayment">
               {plans?.planDtoList?.map((item, index) => {
                  return <button key={index} onClick={() => registerForComp(item?.plan_pricing_details?.productQuantityDto?.productId)} className={`${item?.is_premium ? 'premium mb-3' : 'regular mb-3'}`} ><div className="icon"><img src={`${MEDIAPATH}/skin/images/diamond.svg`} alt="regularPlan" /></div>{item?.plan_pricing_details?.productQuantityDto?.productName}<span>INR {item?.plan_pricing_details?.productQuantityDto.finalPrice}/-</span></button>
               })}
               <div className="country-pice mb-3">Prices vary outside India</div>
            </div>
            {RegPermission?.team &&
               <div className="head"><div className="typeInfo">Have a team registration code? Register as a team member <span className="a-link" onClick={freeTeamReg}>here.</span></div></div>
            }
            {orderFail && <div className="msg error text-danger">{orderFail}</div>}
            <div className="pagenation mt-5">
               <ul>
                  {props?.stepHistory?.map(item => <li className="current cursor-pointer" onClick={() => props.goBack(item)}></li>)}
                  <li className="current"></li>
                  <li></li>
               </ul>
            </div>
         </form>
      </div>
   );
};

export default RegisterStepPayment;
