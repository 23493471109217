import React from 'react'
import "./style.css";
import Banner from './Component/Banner';
import PageLayout from './Component/PageLayout';

const Summary = () => {
    return (
        <>
            <Banner />
            <PageLayout />
        </>
    )
}

export default Summary;