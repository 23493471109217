import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import moment from "moment";

import Base from "../../Components/Layout/Base";
import RegisterUser from "./Components/RegisterUser/RegisterUser";
import RegisterStepOne from "./Components/RegisterStepOne";
import RegisterStepTwo from "./Components/RegisterStepTwo";
import RegisterStepThree from "./Components/RegisterStepThree";
import RegisterStepSix from "./Components/RegisterStepSix";

import { eventProps, getActivities, getCateoryList, resetEventDetails, resetEventProps } from "../../Redux/Actions/champions";
import { resetQuiz } from "../../Redux/Actions/quiz";
import "./style.css";
import "./responsive.css";

const URLRegex = /^(http|https)/;

const QuizEventDetails = () => {

  const event_code = useParams()?.["event_code"]

  const dispatch = useDispatch();
  const location = useLocation();

  const data = location.state || {};
  const componentsMap = {
    1: RegisterStepOne,
    2: RegisterStepTwo,
    3: RegisterStepThree,
    4: RegisterStepSix,
  }

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const kids = useSelector(state => state.dashboard.kids);
  const eventDetails = useSelector(state => state.champions.event_details);
  const event_props = useSelector(state => state.champions.eventProps);
  const activityList = useSelector(state => state.champions.activityList)

  const [categoryId, setCategoryId] = useState()
  const [state, setState] = useState({ step: isAuthenticated ? 3 : 1, formData: {} })

  useEffect(() => {
    dispatch(getCateoryList({ competition_id: eventDetails?.event_id, page_number: 0, page_size: 100 }, id => {
      const cid = id;
      setCategoryId(cid)
    }))
    dispatch(eventProps({ event_id: eventDetails?.event_id }));
    return () => {
      dispatch(resetQuiz());
      dispatch(resetEventDetails());
      dispatch(resetEventProps());
    }
  }, [])

  useEffect(() => {
    if (categoryId) {
      dispatch(getActivities({ category_id: categoryId, competition_id: eventDetails?.event_id }))
    }
  }, [categoryId])

  const handleCompNavigation = ({ formData = {}, step = 0 } = {}) => {
    setState({
      ...state,
      step: step || state?.step + 1,
      formData: { ...state?.formData, ...formData },
      ...data
    });
  }

  const Comp = componentsMap[state.step];

  const RegisterModal = () => {
    if (eventDetails?.register_as_individual) {
      if (eventDetails?.kids?.length > 0 && kids?.[0]?.name) {
        return <RegisterUser event={eventDetails} activityId={activityList?.[0]?.id} />
      } else {
        return <Comp onComplete={handleCompNavigation} data={state.formData} event={eventDetails} competitionId={eventDetails?.event_id} event_code={eventDetails?.event_code} activityId={activityList?.[0]?.id} />
      }
    }
    return false;
  }

  return (
    <Base>
      <div id="register-page">
        <div className="top-banner-sec">
          <div className="banner-overly">
            <div className="row">
              <div className="col-12 col-md-5 lndng-banner-form">
                <div className="form-sec">
                  <RegisterModal />
                </div>
              </div>
              <div className="col-12 col-md-7 bnr-content-sec">
                <div className="content-sec">
                  <h1 className="hdng">Welcome to {eventDetails?.event_name}</h1>
                  <p className="timeline">{moment(eventDetails?.start_date).format("DD MMM Y")} - {moment(eventDetails?.end_date).format("DD MMM Y")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tabs">
          <div className="tab-buttons adjust-padding">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link active tab-btns"
                  id="quiz-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#quiz"
                  type="button"
                  role="tab"
                  aria-controls="quiz"
                  aria-selected="true"
                >
                  Quiz details
                </a>
              </li>
              {activityList?.[0]?.activityDetails?.youtube?.length > 0 &&
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link tab-btns"
                    id="vedios-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#vedios"
                    type="button"
                    role="tab"
                    aria-controls="vedios"
                    aria-selected="false"
                  >
                    Watch video
                  </a>
                </li>
              }
              {event_props?.rules_and_faq &&
                <li className="nav-item">
                  <Link className="tab-btns" to={{ pathname: `/programs/${event_code}/rules`, state: eventDetails }}>
                    Rules & FAQs
                  </Link>
                </li>
              }
              {event_props?.awards_and_certificates &&
                <li className="nav-item">
                  <Link className="tab-btns" to={{ pathname: `/programs/${event_code}/prize`, state: eventDetails }}>Prizes</Link>
                </li>
              }
              {event_props?.leaderboard &&
                <li className="nav-item">
                  <Link className="tab-btns" to={{ pathname: `/programs/leaderboard`, state: { event_id: eventDetails?.event_id } }}>Leaderboard</Link>
                </li>
              }
            </ul>
          </div>
          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="quiz" role="tabpanel" aria-labelledby="quiz-tab">
              <div className="tab-desc adjust-padding px-5">
                {activityList?.[0]?.activityDetails?.description?.details?.length > 0 &&
                  <div className="details adjust-padding">
                    <div className="sec-hdng">
                      <p>Details</p>
                    </div>
                    <div className="content">
                      <ul>{activityList?.[0]?.activityDetails?.description?.details?.map(item => <li>{item}</li>)}</ul>
                    </div>
                  </div>
                }
              </div>
            </div>
            <div
              className="tab-pane fade container"
              id="vedios"
              role="tabpanel"
              aria-labelledby="vedios-tab"
            >
              <div className="container-fluid">
                {activityList?.[0]?.activityDetails?.youtube?.map((item, index) => {
                  const videoSrc = URLRegex.test(item.url) ? item.url : `https://www.youtube.com/embed/${item.url}`;
                  return (
                    <div className="post-banner post-card" key={index}>
                      <iframe
                        className="mobile-video"
                        src={videoSrc}
                        height="300"
                        width="100%"
                        title="Iframe Example"
                      ></iframe>
                      <div className="post-card-content">
                        <h6 className="post-card-heading">{item.title}</h6>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Base>
  );
};
export default QuizEventDetails;
