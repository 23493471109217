import Modal from 'react-bootstrap/Modal';

function PopUp({ size = "md", centered = true, show, handleClose, ...props }) {

  return (
    <Modal size={size} centered={centered} show={show} onHide={handleClose}>
      <Modal.Body className='p-0'>
        {props.children}
      </Modal.Body>
    </Modal>
  );
}

export default PopUp;