import { QUIZ_ACTIVITY, QUIZ_CERTIFICATE, QUIZ_DATA, QUIZ_HISTORY, QUIZ_LOCAL_DATA, QUIZ_QUESTION, QUIZ_RESET, QUIZ_SUMMARY } from "../Actions/types";

const initialState = {
   history: [],
   quizData: {},
   questionData: {},
   localData: {},
   quizSummary: {},
   activity: [],
   certificate: {}
}

export default function (state = initialState, action) {
   const { type, payload } = action;
   switch (type) {
      case QUIZ_HISTORY:
         return { ...state, history: payload }
      case QUIZ_DATA:
         return { ...state, quizData: payload }
      case QUIZ_QUESTION:
         return { ...state, questionData: payload }
      case QUIZ_LOCAL_DATA:
         return { ...state, localData: { ...state.localData, ...payload } }
      case QUIZ_SUMMARY:
         return { ...state, quizSummary: payload }
      case QUIZ_ACTIVITY:
         return { ...state, activity: payload }
      case QUIZ_CERTIFICATE:
         return { ...state, certificate: payload }
      case QUIZ_RESET:
         return { ...initialState }
      default:
         return state;
   }
}