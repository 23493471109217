import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { handleLoginModal, logoutApp } from '../../Redux/Actions/auth';
import { MEDIAPATH } from '../../Redux/Actions/config';
import { isIIMSirMauriEmail, isValidString } from '../../helper';

const Navigation = () => {

  const dispatch = useDispatch();

  const { isAuthenticated, user } = useSelector(state => state.auth);

  const handleLogin = () => {
    dispatch(handleLoginModal(true))
  }
  const handleLogout = () => {
    dispatch(logoutApp());
    setTimeout(() => {
      window.location.href = "/";
    }, 1000);

  }

  return (
    <nav id="home-page" className="navbar navbar-header navbar-expand-sm navbar-dark p-0 shadow">
      <div className="container">
        <Link className="navbar-brand p-0" to="/">
          <img src="/images/logo.svg" alt="logo" />
        </Link>
        <button className="navbar-toggler" id="toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
          <span className="navbar-toggler-icon">
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </span>
        </button>
        <div className="collapse navbar-collapse" id="mynavbar">
          <ul className="navbar-nav me-auto col-sm-9 col-md-9 col-lg-8">
            <li className="nav-item">
              <Link className="nav-link" to="/"> Home </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link fw-600" to="/programs">Programs</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/#team"> Our Team </Link>
            </li>
            <li className="dropdown">
              <Link className="nav-link" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                Our Services {" "} <img className="menu-arrow" src="/images/menu-arrow.png" alt="arrow" />
              </Link>
              <ul className="dropdown-menu">
                <li>
                  <Link className="dropdown-item" to="/#training">Employee Training</Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/#hiring">Employee Hiring</Link>
                </li>
              </ul>
            </li>
            <li><Link to="/#contact" className="contact nav-link">Contact Us</Link></li>
            {/* <li className="dropdown">
              <Link className="nav-link" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                More {" "} <img className="menu-arrow" src="/images/menu-arrow.png" alt="arrow" />
              </Link>
              <ul className="dropdown-menu">
                <li>
                  <Link className="dropdown-item" to="/nsdc-job-fair">NSDC Virtual Job Fair</Link>
                </li>
              </ul>
            </li> */}
          </ul>
          {isAuthenticated ?
            <ul className="navbar-nav">
              <li className="dropdown">
                <Link className="nav-link" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                  <img className="preview rounded-circle profilepic__image me-2" src={isValidString(user?.profile_photo) ? user?.profile_photo : `${MEDIAPATH}/skin/images/profile/profile.jpeg`} alt={user?.name} width="25px" height="25px" />
                  <img className="menu-arrow" src="/images/menu-arrow.png" alt="arrow" />
                </Link>
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-item" to="/account">Profile</Link>
                  </li>
                  {isIIMSirMauriEmail(user?.emailId) &&
                    <li>
                      <Link className="dropdown-item" to="/digital-portfolio">My Digital Portfolio</Link>
                    </li>
                  }
                  <li>
                    <Link className="dropdown-item" to="#" onClick={handleLogout}>Logout</Link>
                  </li>
                </ul>
              </li>
            </ul>
            : <Link to="#" onClick={handleLogin} className="contact nav-link">Login/Signup</Link>
          }
        </div>
      </div>
    </nav>
  )
}

export default Navigation