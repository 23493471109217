import React from 'react'
import PropTypes from 'prop-types';
import Navigation from './Navigation'
import Footer from './Footer'

const Base = (props) => {
  return (
    <div id="Master-naukari" className="wrapper d-flex flex-column justify-content-between min-vh-100">
      <Navigation />
      {props.children}
      <Footer />
    </div>
  )
}

Base.propTypes = {
  children: PropTypes.object
}
export default Base