import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Base from '../Components/Layout/Base';
import { MEDIAPATH } from '../Redux/Actions/config';
import { getQuizSummary } from '../Redux/Actions/quiz';
import "./EventQuiz/ns-quiz.css";


const EventQuizSummary = () => {

   const dispatch = useDispatch();
   const location = useLocation();

   const kidId = location?.state?.kidId;
   const acm_quiz_id = location?.state?.acm_quiz_id;
   const attempt_id = location?.state?.attempt_id;

   const quizSummary = useSelector(state => state.quiz.quizSummary);

   useEffect(() => {
      const data = {
         kidId,
         acm_quiz_id,
         attempt_id
      }
      dispatch(getQuizSummary(data));
   }, [])

   const timeConvert = sec => {
      const min = Math.floor(sec / 60);
      if (min > 1)
         return `${min} min`;
      else
         return `${sec} sec`;
   }

   const TextBlock = ({ title, value, type }) => {
      return (<div class="row mb-3">
         <div class="card shadow-sm rounded border-0 p-2">
            <div class="card-body nsquiz-summary">
               <div className="title">
                  <span>{title}</span><span className={`pull-right kidex-text-${type}`}>{value}</span>
               </div>
            </div>
         </div>
      </div>)
   }
   return (
      <Base>
         <div className="atl-team-reg atl-tinker-register ns-quiz-summary-con">
            <div className="bannerImg">
               <img style={{ width: "100%" }} src={`${MEDIAPATH}/skin/images/quiz/quiz_summary.jpg`} alt="narc register" />
            </div>
            <div className="container-kidex my-5 ns-quiz kidex-theme-font summary-content">
               <TextBlock title="No of questions attempted." value={`${quizSummary?.attempt_question}/${quizSummary?.total_quiz_question}`} type="warning" />
               <TextBlock title="No of correct answers." value={quizSummary?.correct_question} type="success" />
               <TextBlock title="No of incorrect answers." value={quizSummary?.incorrect_question} type="danger" />
               <TextBlock title="Time taken to complete the quiz." value={`${timeConvert(quizSummary?.time_taken)}`} />
               <TextBlock title="Average time taken for each question." value={timeConvert(quizSummary?.average_time_question)} />
               <div className="row my-5">
                  <div className="text-center">
                     <p className="fs-2 fw-bold theme-text-color">Total Score : {quizSummary?.attempt_score}</p>
                  </div>
               </div>
               <div className="row my-5">
                  <div className="text-center question-btn">
                     {quizSummary?.show_retake_button && <a type="button" class="btn mx-2 quiz-button-out-link-pnk-comn">Retake the quiz</a>}
                     <a href="/champions" type="button" class="btn mx-2 quiz-button-pnk-comn">Explore more</a>
                  </div>
               </div>
            </div>
         </div>
      </Base>
   )
}

export default EventQuizSummary