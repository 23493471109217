import React from "react";
import { Link } from "react-router-dom";
import { MEDIAPATH } from "../../Redux/Actions/config";

const SuccessForm = (props) => {

  const StepHeading = ({ title, subtitle, isSubtitle }) => {
    return (
      <>
        <div className="title">{title}</div>
        {isSubtitle && <div className="subtext" style={{ lineHeight: "19px" }}>{subtitle}</div>}
      </>
    )
  }

  return (
    <div className="formSection">
      <div className="head center">
        <div className="icon">
          <img src={`${MEDIAPATH}/skin/images/celebration.png`} alt="Hurray" />
        </div>
        <StepHeading title="Hurray!" subtitle="Your registration is completed. Look forward to your active
          participation." isSubtitle={true} />
      </div>
      <div className="form stepSix">
        <div className="submitMe text-center">
          <Link to={`/programs/${props?.event_code}`} state={{ tab: "activities" }} className="btn btn-theme-primary fw-600 rounded-pill">Explore more</Link>
        </div>

        <div className="pagenation">
          <ul>
            {props?.stepHistory?.map(item => <li className="current"></li>)}
            <li className="current"></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SuccessForm;
