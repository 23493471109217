import React, { useEffect } from 'react'
import Base from '../../Components/Layout/Base'
import "./style.css";

const Ppc = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [])

  return (
    <Base>
      <div className="wrapper">
        <div className="t-n-c-block">
          <div className="heading">
            <div className="head">
              <h1 className="title">Privacy Policy</h1>
            </div>
            <div className="date">Last Updated: November 09, 2022</div>
          </div>

          <div className="policy-terms">
            <p className="paragraph">
              This policy describes the information we collect, process &amp;
              safeguard to offer world-class products and features from
              masterNAUKRI to our customers while safeguarding the data in a
              secure manner to respect &amp; uphold users’ data privacy.
            </p>

            <div className="text-block">
              <div className="text">Your Consent</div>
              <p className="paragraph">
                By accessing https://www.masternaukri.com/ or any of our digital
                products/apps (“our Website &amp; App”) and using it on a
                continued basis, you expressly consent and confirm to KidEx
                Venture Private Limited (“masterNAUKRI”) collecting, maintaining,
                using, processing and disclosing your personal and other
                information in accordance with this Privacy Policy.
              </p>

              <div className="text">Commitment</div>

              <p className="paragraph">
                masterNAUKRI intends to protect the privacy of its users (whether
                or not registered with masterNAUKRI) on its website &amp; app and
                the privacy of their data provided to masterNAUKRI from time to
                time. The privacy of our Website &amp; App users, whether you are
                our former or existing registered user or merely a visitor to our
                Website &amp; App, is important to us and we are strongly
                committed to your right to privacy and to keeping your personal
                and other information secure. We encourage you to read our privacy
                statement to understand what types of personally identifiable
                information we collect and how we use this information. For the
                purpose of providing you with quality services, our Website &amp;
                App collects preliminary personal information that allows us to
                identify you as a user and know more about your requirements. Some
                of our services may direct you to web domains of third parties.
                Information (if any) provided by you on such web domains may be
                dealt by them in the manner provided by the privacy statements of
                such third party web domains. This Privacy Policy only deals with
                your information shared with us (either as a registered user or a
                mere visitor) and we shall in no manner be liable/bound for
                protection of information (if any), which may be shared by you on
                such third party web domains. We encourage you to very carefully
                review the privacy policy of any third-party website you visit
                before using it and to be very cautious before you disclose your
                personal information on such websites.
              </p>

              <div className="text">
                What type of information does masterNAUKRI collect from you?
              </div>

              <p className="paragraph">
                To provide masterNAUKRI products, we must process information
                about you. The types of information we collect depend on how you
                use our products. You can easily view your shared information
                and/or delete the information you have shared whenever you wish.
              </p>
              <ul className="data-collect-list">
                <li>Personal info</li>
                <p className="paragraph">Name, Email address, Address, Phone number and Other info</p>
                <li>Financial info</li>
                <p className="paragraph">Purchase history</p>
                <li>Messages</li>
                <p className="paragraph">SMS or MMS and Other in-app messages</p>
                <li>Photos and videos</li>
                <p className="paragraph">Photos and Videos</p>
                <li>Files and docs</li>
                <p className="paragraph">Files and docs</p>
                <li>App activity</li>
                <p className="paragraph">App interactions, In-app search history, Installed apps, Other user-generated content and
                  Other actions</p>
                <li>App info and performance</li>
                <p className="paragraph">
                  Crash logs, Diagnostics and Other app performance data
                </p>
                <li>Device or other IDs</li>
                <p className="paragraph">Device or other IDs</p>
              </ul>
              <div className="text">Personal Information</div>

              <p className="paragraph">
                If you are a tutor, teacher, lecturer, instructor or coaching
                institution, masterNAUKRI requires you to register on our Website
                by providing some or all of: your name, email ID, personal phone
                number, password, location, gender, resume and your professional
                qualifications. The Website also allows you to share a
                photograph/video that may help better identify you and which you
                may share at your own discretion. If you are a prospective
                customer looking to get connected with Coach(es) and/or access any
                of masterNAUKRI’s features, our Website &amp; App collects some or
                all of: your name, DOB, email ID, personal phone number, personal
                photo, select personal information (child’s name, child’s image,
                child’s school &amp; class information, child’s DOB &amp; gender,
                relation with child, child’s learning profile related inputs,
                family member’s name, phone number, email, gender), content
                created by you/your child &amp; family, and location. Further, in
                order to consummate certain transactions, our Website &amp; App
                may also require you to submit your credit/debit card and/or bank
                account details through a secure Third Party Payment Gateway
                provider. We may collect your bank details in certain
                circumstances, where money needs to be transferred to you via NEFT
                to your bank account. You may opt to share your contact book
                information to easily navigate the app as you wish to invite your
                personal network to become a part of the masterNAUKRI Website
                &amp; App system. Such information is kept strictly confidential.
                masterNAUKRI also collects your other personal details including
                but not limited to your feedback, suggestions, views, comments,
                etc. that may be shared/volunteered by you on our discussion
                forums or other pages which you may access or visit on our Website
                &amp; App. Such information being in the public domain is
                available for access for all our Website’s users and visitors. In
                this regard, you are advised to exercise discretion before you
                make such details public, as such information may be vulnerable to
                mis-use and can be easily misused. masterNAUKRI in this regard
                fully disclaims any liability(ies) or claim(s) which may arise by
                use/misuse of such information shared by you, by any third party
                or any party not known to masterNAUKRI.
              </p>

              <p className="paragraph">
                For masterNAUKRI to recognize you as a registered member, you must
                login with your login credentials. Your login credentials are
                never shared by masterNAUKRI with anybody within or outside of
                masterNAUKRI.
              </p>

              <p className="paragraph">
                All other information exchanged by you with masterNAUKRI in the
                form of written communication, responses to emails, feedback
                required from you, participation in discussions, etc. shall be
                deemed to be confidential and shall be owned by masterNAUKRI and
                shall be available for the exclusive use of masterNAUKRI. Our
                Website &amp; app does not collect information about you unless
                you specifically and knowingly provide it. masterNAUKRI DOES NOT
                trade or sell your information in any manner, except as specified
                herein, or if express consent is sought from you.
              </p>

              <p className="paragraph">
                Information and content you provide. We collect the content and
                other information you provide when you use our products, including
                when you sign up for an account, create or share content. This can
                include information in or about the content you provide (like
                metadata), such as the location of a photo or the date a file was
                created. It can also include what you see through features we
                provide, such as camera, so we can do things like give you tips on
                using camera formats.
              </p>

              <p className="paragraph">
                Networks and connections. We collect information about the people,
                kids, hashtags you are connected to and how you interact with them
                across our products, such as people you share content with the
                most or circles you are part of.
              </p>

              <p className="paragraph">
                Your usage. We collect information about how you use our products,
                the features you use; the actions you take; the people or accounts
                you share content with; and the time, frequency and duration of
                your activities.
              </p>

              <p className="paragraph">
                Things others do and information they provide about you. We also
                receive content that other people provide about you when they use
                our products.
              </p>

              <div className="text">Non-Personal Information</div>
              <p className="paragraph">
                You agree that masterNAUKRI may gather usage statistics and usage
                data from your use of our Website &amp; App to evaluate your use
                of our products/services, to improve our products/services,
                Website &amp; App content and to ensure that you are complying
                with the terms of the applicable agreements between you and
                masterNAUKRI. The statistics and data collected may or may not be
                aggregated. These statistics contain no information that can
                distinctly identify you.
              </p>

              <p className="paragraph">
                We may in future also share this information with third party
                service providers or third party advertisers to measure the
                overall effectiveness of our online advertising, content,
                programming and for other bonafide purpose as we may desire. By
                usage of our Website &amp; App you expressly permit masterNAUKRI
                to access such information for one or more purposes deemed fit by
                masterNAUKRI.
              </p>

              <div className="text">Information from Third Parties</div>
              <p className="paragraph">
                We may collect, process and store your user ID associated with any
                social media account (such as your Facebook and Google account)
                that you use to sign into the Services or connect with or use with
                the Services. When you sign in to your account with your social
                media account information, or otherwise connect to your social
                media account with the Services, you consent to our collection,
                storage, and use, in accordance with this Privacy Policy, of the
                information that you make available to us through the social media
                interface. This could include, without limitation, any information
                that you have made public through your social media account,
                information that the social media service shares with us, or
                information that is disclosed during the sign-in process. Please
                see your social media provider's privacy policy and help center
                for more information about how they share information when you
                choose to connect your account.
              </p>

              <p className="paragraph">
                We may also obtain information about you from third parties such
                as partners, marketers, third-party websites, and researchers, and
                combine that information with information which we collect from or
                about you.
              </p>

              <div className="text">Cookies</div>
              <p className="paragraph">
                Similar to other commercial web sites, our Website &amp; App
                utilizes standard technology called "Cookies" and Web server logs
                to collect information about how our Website &amp; App is used.
                Cookies are small pieces of information that are stored by your
                browser on your computer's or smartphone’s hard drive. Cookies are
                only read by the server that places them, and are unable to inter
                alia run programs on your computer or smartphone, plant viruses or
                harvest your personal information. Cookies allow Web servers to
                recognize your computer or smartphone each time you return to our
                Website &amp; App including the date and time of visits, the pages
                viewed, time spent at our Website &amp; App, and the Websites
                &amp; Apps visited just before and just after our Website, verify
                your registration or password information (in case of registered
                users) during a particular session so that you do not have to
                input your password multiple times as you navigate the site and
                suggest products or services that may interest you. This
                information is collected on an aggregate basis. None of this
                information is associated with you as an individual.
              </p>

              <p className="paragraph">
                Most web browsers automatically accept cookies. By changing the
                options on your web browser or using certain software programs,
                you can control how and whether cookies will be accepted by your
                browser. masterNAUKRI understands and supports your right to block
                any unwanted Internet activity, especially that of unscrupulous
                websites. However, blocking masterNAUKRI cookies may disable
                certain features on our Website &amp; App and may make it
                impossible for you to use certain services available on our
                Website &amp; App.
              </p>

              <div className="text">Session Data</div>
              <p className="paragraph">
                We automatically log generic information about your computer's
                &amp; smartphone’s connection to the Internet, which we call
                "session data", that is anonymous and not linked to any personal
                information. Session data consists of information such as the IP
                address, operating system, type of browser software being used by
                you and the activities conducted by you on our Website &amp; App.
                An IP address is a number that lets electronic devices like
                computers attached to the Internet, such as our web servers, know
                where to send data back to the user, such as the pages of the site
                the user wishes to view. We collect session data because it helps
                us analyze things such as the items visitors are likely to click
                on most, the manner in which visitors click preferences on our
                Website &amp; App, number of visitors surfing to various pages on
                the site, time spent by the visitors on our Website &amp; App and
                frequency of their visit. It also helps us diagnose problems with
                our servers and lets us administer our systems better. Although
                such information does not identify any visitor personally, it is
                possible to determine from an IP address a visitor's Internet
                Service Provider (ISP), and the approximate geographic location of
                his or her point of connectivity.
              </p>

              <div className="text">Other</div>
              <p className="paragraph">
                We may from time to time add or enhance products/services
                available on our Website &amp; App. To the extent these
                products/services are provided to and used by you, we will use the
                information you provide in this regard to facilitate the
                products/service requested. For example, if you email us with a
                question, we will use your email address, name, nature of the
                question, etc. to respond to your question. We may also store and
                publish such information to assist us in making the Website &amp;
                App better and easier to use.
              </p>
              <div className="text">Promotional Initiatives</div>
              <p className="paragraph">
                masterNAUKRI’s presence on social networking websites including
                but not limited to LinkedIn, Facebook, Twitter, Youtube, Google
                Plus, etc. is only a promotional initiative to invite
                registrations and participation of prospective customers located
                in different parts of the country. The domain links contained
                therein may either direct you to our Website &amp; App or request
                your participation by way of feedback, suggestions. masterNAUKRI
                in this regard fully disclaims any liability(ies) or claim(s)
                which may arise by use/misuse of your feedback, suggestions,
                views, etc. on any of the aforementioned networking websites or
                blogs, by any third party whether or not known to masterNAUKRI.
              </p>

              <div className="text">
                How does masterNAUKRI protect my Personal Information?
              </div>
              <p className="paragraph">
                masterNAUKRI is committed to protecting the privacy and the
                confidentiality of your personal information. Whenever
                masterNAUKRI obtains personal information from you, our Website
                &amp; App uses commercially reasonable efforts and general
                industry standards to protect it from any unauthorized access or
                disclosure. Save as otherwise provided herein, access to your
                personal information is limited to masterNAUKRI’s personnel and
                such authorized third parties who may host your data on our behalf
                or may assist us in providing the products and services requested
                by you. masterNAUKRI uses its best endeavours to maintain
                physical, electronic and procedural safeguards that aim to protect
                your information against loss, misuse, damage, modification, and
                unauthorized access or disclosure. However, masterNAUKRI assumes
                no liability whatsoever for any disclosure of personal information
                due to unauthorized third party access or other acts of third
                parties, or any other acts or omissions beyond reasonable control
                of masterNAUKRI.
              </p>

              <div className="text">
                Does masterNAUKRI secure the content posted by its users?
              </div>
              <p className="paragraph">
                The content that you store, post, or transmit on or through our
                Website &amp; App, such as forum postings, your profile page, the
                images you upload to our Website &amp; App, may be accessed by
                other users (registered or unregistered), including people that
                you do not know depending on your choices regard sharing of such
                content. The purpose of making such content available to your
                defined target users is to enhance your overall experience and to
                provide you a platform where users meet and interact on a one to
                one basis. However, the content/images that you post/share on our
                Website &amp; App may be prone to mis-use by a party not known to
                you. masterNAUKRI in this regard fully disclaims any
                liability(ies) or claim(s) which may arise by use/misuse of such
                content, by any third party or any party not known to
                masterNAUKRI. We suggest that you exercise care and caution in
                communicating with other users and only disclose your information
                to other users that you know to be trustworthy. You should not
                assume that your content will be kept private.
              </p>

              <div className="text">
                How does masterNAUKRI use the information that it collects?
              </div>
              <p className="paragraph">
                We use the information we have (subject to choices you make) as
                described below and to provide and support the products and
                related services. Here's how:
              </p>

              <p className="paragraph">
                Provide, personalize and improve our products. We use the information we have to deliver our products, including
                to personalize features and content. To create personalized
                products that are unique and relevant to you, we use your
                connections; how you use and interact with our products; and the
                people you're connected to.
              </p>

              <p className="paragraph">
                Product research and development. We use the information we have to develop, test and improve our
                products, including by conducting surveys and research, and
                testing and troubleshooting new products and features.
              </p>

              <p className="paragraph">
                Promote safety, integrity and security. We use the information we have to verify accounts and activity,
                combat harmful conduct, detect and prevent spam and other bad
                experiences, maintain the integrity of our products, and promote
                safety and security on and off of our Products.
              </p>

              <p className="paragraph">
                Communicate with you. We use the information we have to send you marketing
                communications, communicate with you about our Products, and let
                you know about our policies and terms. We also use your
                information to respond to you when you contact us.
              </p>

              <p className="paragraph">
                Research and innovate for social good. We use the information we have (including from research partners
                we collaborate with) to conduct and support research and
                innovation on topics of general welfare, child development, health
                and well-being.
              </p>

              <p className="paragraph">
                Your information may be used by us and/or authorized third
                parties/service partners to support your interaction with us and
                to provide you our Services and products that we offer. By
                submitting your personal information to masterNAUKRI, you
                expressly acknowledge and consent to masterNAUKRI to use such
                information and to process the same in a manner deemed fit by
                masterNAUKRI. This may involve conducting data analysis, research
                based on reviews about deals, transfer of your information to
                third party experts for the purpose of outsourcing services
                offered to you by masterNAUKRI, etc.
              </p>

              <div className="text">How is this information shared?</div>
              <p className="paragraph">
                <span className="subtitle">
                  Your information is shared with others in the following ways:
                </span>
              </p>
              <p className="paragraph">
                <span className="span-text">Sharing on our Products.</span>
                <br />
                People and accounts you share and communicate with when you share
                using our products, you choose the audience for what you share.
                Your chosen network for content can see your action. Public
                information can be seen by anyone, on or off our products.
              </p>

              <p className="paragraph">
                <span className="span-text">
                  Content others share or reshare about you.
                </span>
                <br />
                You should consider who you choose to share with, because people
                who can see your activity on our products can choose to share it
                with others on and off our Products, including people and
                businesses outside the audience you shared with.
                <br />
                People can also use our products to create and share content about
                you with the audience they choose. For example, people can share a
                photo of you. If you are uncomfortable with what others have
                shared about you on our products, you can report to us at
                info@masternaukri.com.
              </p>

              <p className="paragraph">
                <span className="span-text">New owner.</span>
                <br />
                If the ownership or control of all or part of our products or
                their assets changes, we may transfer your information to the new
                owner.
              </p>

              <p className="paragraph">
                <span className="span-text">Researchers and academics.</span>
                <br />
                We also provide information and content to research partners and
                academics to conduct research that advances scholarship and
                innovation that support our business or mission, and enhance
                discovery and innovation on topics of general welfare, child
                development, public interest, health and well-being.
              </p>

              <div className="text">How is this information stored?</div>

              <p className="paragraph">
                We provide you with the ability to access, rectify, port and erase
                your data. You can inform us on info@masternaukri.com to delete
                your account or account related selective information.
              </p>

              <p className="paragraph">
                We store data until it is no longer necessary to provide our
                services and products, or until your account is deleted -
                whichever comes first. This is a case-by-case determination that
                depends on things like the nature of the data, why it is collected
                and processed, and relevant legal or operational retention needs.
              </p>

              <p className="paragraph">
                When you delete your account, we delete things you have posted,
                such as your photos, and you won't be able to recover that
                information later. Information that others have shared about you
                isn't part of your account and won't be deleted.
              </p>

              <div className="text">
                How does masterNAUKRI address children's privacy?
              </div>

              <p className="paragraph">
                masterNAUKRI requires the visitor to disclose their DOB while
                creating the profile. Creating of profile is important to use
                Website &amp; App features. If visitor is below 18 years of age,
                visitor is required to link a guardian to the account for access.
                Guardian has access to activity by the child on the Website/App.
                If you are the parent or guardian of a child under 18 years of age
                and believe that they have disclosed personal information to us
                which needs to be deleted/edited, please contact us at
                info@masternaukri.com so that we may take immediate steps to
                delete/edit the child's information.
              </p>

              <div className="text">I have more questions about privacy policy.</div>
              <p className="paragraph">
                You may reach out to our Grievance &amp; Customer Communications
                manager (B. Anitha) at&nbsp;info@masternaukri.com or mail at: 3rd
                floor, Plot 108, Sector 44, Gurgaon, Haryana, India. The Grievance
                &amp; Customer Communications manager &amp; her team will address
                your questions/complaints, suggestions.
              </p>

              <div className="text">
                Will masterNAUKRI change privacy policy in the future?
              </div>

              <p className="paragraph">
                This policy provides a general statement of the ways in which
                masterNAUKRI aims to protect your personal information. You may,
                however, in connection with specific products or services offered
                by masterNAUKRI, be provided with privacy policies or statements
                that substitute or supplement this policy. The terms and
                conditions of this policy may be changed from time to time,
                without your consent (express or implied) to reflect changes in
                our practices concerning the collection and use of your personal
                information. The revised policy will be effective immediately upon
                posting to our web site unless otherwise mentioned. This version
                of the policy is effective from November, 2022. You shall be bound
                by the revised Privacy Policy and it is your sole responsibility
                to check the same from time to time. You acknowledge that no
                knowledge of the revised Privacy Policy shall be a defence in any
                proceedings with regard to the Privacy Policy.
              </p>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </Base>
  )
}

export default Ppc