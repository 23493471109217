import React, { useState } from "react";
import SendOTP from "./SendOTP";
import ValidateOTP from "./ValidateOTP";
import "./style.css";

const MultiForm = ({ embedded = false }) => {

  const [step, setStep] = useState(1);

  return (
    <div className="multi-step-kidex-login">
      <div className="multiform-inner">
        {{ 1: <SendOTP setStep={setStep} embedded={embedded} />, 2: <ValidateOTP setStep={setStep} embedded={embedded} /> }[step]}
      </div>
    </div>
  );
};

export default MultiForm;
