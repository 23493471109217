import React from "react";
import moment from "moment";
import { Notify } from "notiflix";
import { Formik } from "formik";
import DatePicker from 'react-date-picker';
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../Redux/Actions/user";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { genderList } from "../../helper";

const BasicDetails = () => {

  const dispatch = useDispatch();

  const user = useSelector(state => state.auth.user);
  const citites = useSelector(state => state.dashboard.cities);

  const update_user = (data) => {
    if (data.mobile_no.length > 10) return Notify.failure("Mobile number is not valied!")
    dispatch(updateUser(data));
  }

  return (
    <div className="your-detail-sec col-sm-12">
      <div className="your-detail-form-sec col-8 mx-auto pb-2">
        <h5 className="detail-form-heading form-title">Your basic details</h5>
        <Formik
          initialValues={{
            name: user?.name,
            emailId: user?.emailId,
            mobile_no: user?.mobile_no,
            city: user?.city,
            dob: user?.dob,
            gender: user?.gender
          }}
          enableReinitialize={true}
          onSubmit={update_user}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
            handleSubmit,
          }) => (
            <form className="row g-3 needs-validation form-sec" noValidate onSubmit={handleSubmit} id="json-form">
              <div className="form-field mb-3">
                <label className="form-label">Full Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder=""
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  name="name"
                />
              </div>
              <div className="form-field mb-3">
                <label className="form-label">Email Id</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.emailId}
                  name="emailId"
                />
              </div>
              <div className="form-field mb-3">
                <label className="form-label">Mobile Number</label>
                <div className="input-group ">
                  <span className="input-group-text" id="mobile">+91</span>
                  <input
                    type="text"
                    disabled={!user?.mobile_updatable}
                    className="form-control"
                    aria-label="mobile"
                    aria-describedby="mobile"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.mobile_no}
                    name="mobile_no"
                  />
                </div>
              </div>
              <div className="form-field mb-3">
                <label className="form-label">City of Residence</label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.city}
                  name="city"
                >
                  <option selected>Select</option>
                  {citites?.map((item) => <option key={item.id} value={item.appString}>{item.appString}</option>)}
                </select>
              </div>
              <div className="form-field date-form-field mb-3">
                <label className="form-label">Date of Birth</label>
                <DatePicker
                  className="form-control"
                  name="dob"
                  onChange={(date) => { setFieldValue('dob', moment(date).format("YYYY-MM-DD")) }}
                  value={values?.dob && moment(values?.dob).format("YYYY-MM-DD")}
                  defaultValue={new Date(moment().subtract(18, 'years'))}
                  maxDate={new Date(moment().subtract(18, 'years'))}
                  format="dd/MM/y"
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                />
              </div>
              <div className="form-field mb-3">
                <label className="form-label">Gender</label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.gender}
                  name="gender"
                >
                  <option selected>Select</option>
                  {genderList?.map((item) => <option key={item.id} value={item.value}>{item.label}</option>)}
                </select>
              </div>
              <div className="d-flex justify-content-center detail-submit-btn">
                <button type="submit" className="btn form-submit-btn">Save</button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default BasicDetails;
