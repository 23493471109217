import React from 'react'
const Banner = () => {
    return (
        <>
            <div className="summary-banner">
                <div className='container'>
                    <div className='row'>
                        <div className='col-6 col-md-7 col-xl-6'>
                            <h1 className='quicksand pt-md-5'>
                                IIM SIRMAUR
                                <span>Student Digital Portfolio</span>
                            </h1>
                        </div>
                        <div className='col-6 col-md-5 col-xl-6 d-flex align-items-center justify-content-center'>
                            <img src='../../images/iim-large.png'  className='mw-auto'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Banner;