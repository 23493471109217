import AppRoutes from './AppRoutes';
import Notiflix from 'notiflix';
import { Fragment } from 'react';
import LoginModel from './Components/Authentication/LoginModel';
import "./helper/interceptor";
import "./css/narc-register.css";
import './App.css';
import "./Responsive.css";

Notiflix.Notify.init({
  showOnlyTheLastOne: true,
  timeout: 5000,
  clickToClose: true,
  pauseOnHover: true,
});

Notiflix.Confirm.init({
  titleColor: '#c41d8e',
  okButtonBackground: '#c41d8e',
});

Notiflix.Report.init({
  success: {
    svgColor: '#c41d8e',
    buttonBackground: '#c41d8e',
  }
});

function App() {
  return (
    <Fragment>
      <LoginModel />
      <AppRoutes />
    </Fragment>
  );
}

export default App;
