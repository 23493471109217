import axios from "axios";
import { TENANT_KEY } from "../Redux/Actions/config";
import store from "../Redux/store";
import { LOGOUT } from "../Redux/Actions/types";

const whitelistedRoutes = ['/api/entity/kid/profile/nologin', '/api/entity/kid/eprofile/nologin'];
const isWhitelisted = (url) => {
  return whitelistedRoutes.some(route => url.includes(route));
}

axios.interceptors.request.use(request => {
  const currentState = store.getState();
  const token = currentState.auth.token;
  if (isWhitelisted(request?.url)) {
    request.headers["kid-ex-tenant-id"] = TENANT_KEY;
  } else if (token) {
    request.headers["access-token"] = token;
  } else if (!request?.url?.includes("api/auth/token/validate")) {
    request.headers["kid-ex-tenant-id"] = TENANT_KEY;
  }
  request.headers["kid-ex-client"] = "website";
  return request;
});

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (401 === error.response.status) {
    localStorage.removeItem("token");
    store.dispatch({ type: LOGOUT });
    window.location = "/";
    return Promise.reject(error);
  } else {
    return Promise.reject(error);
  }
});