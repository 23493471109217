import moment from "moment";

export const validateEmail = email => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);

export const validateMobile = mobile => /^[0-9]{10}$/.test(mobile);

export const ageRange = ["3", "4", "5", "6", "7-8", "9-11", "12-14", "15-17"];

export const getAgeByDOB = dob => Number.isFinite(dob) ? moment().diff(new Date(dob), 'y') : 0;

export const sliceString = (string, length) => string?.length > length ? `${string.substring(0, length)}...` : string;

export const isValidString = str => str !== null && str !== undefined && str.trim() !== "";

export const getQueryString = params => Object.keys(params).map(key => `${key}=${params[key]}`).join('&');

export const genderList = [
  { id: 1, label: "Male", value: "Male" },
  { id: 2, label: "Female", value: "Female" },
  { id: 3, label: "Other", value: "Other" },
]
export const gradeLabes = {
  EX: "Expert",
  M: "Master",
  I: "Intermediate",
  B: "Beginner",
  A: "Aspirant",
  NA: "Not Applicable"
}

export const getGrade = data => {
  let res = {}
  if (data) {
    const { current_grade, grade_status } = data;
    switch (grade_status) {
      case "GradeAwaited":
      case "Grade Awaited":
        res = {
          color: "warning",
          grade: "Grade Awaited",
          resubmission: false
        }
        break;
      case "Graded":
      case "GRADED":
        res = {
          color: "success",
          grade: `${current_grade} (${gradeLabes[current_grade]})`,
          resubmission: false
        }
        break;
      case "UnderReview":
      case "UNDER REVIEW":
        res = {
          color: "warning",
          grade: `Under Review (${current_grade})`,
          resubmission: false
        }
        break;
      case "Reviewed":
      case "REVIEWED":
        res = {
          color: "success",
          grade: `${current_grade} (${gradeLabes[current_grade]})`,
          resubmission: true
        }
        break;
      default:
        res = {
          color: "success",
          grade: `${current_grade} (${gradeLabes[current_grade]})`,
          resubmission: false
        }
    }

    if (current_grade == "NA") {
      res["color"] = "danger";
    }
  }

  return res;
}

export const convertTime = (timer) => {
  if (timer > 0) {
    let min = Math.floor(timer / 60);
    let sec = Math.floor(timer % 60);
    if (min < 10) {
      min = `0${min}`
    }
    if (sec < 10) {
      sec = `0${sec}`
    }
    return `${min}:${sec} min`;
  } else {
    return `00:00 min`
  }
}

export const convertTimeStamp = (timer) => {
  let time = "";
  if (timer > 0) {
    let min = Math.floor(timer / 60);
    let sec = Math.floor(timer % 60);
    if (min > 0) {
      time += `${min} min `
    }
    if (sec > 0) {
      time += `${sec} sec`
    }
    return time;
  } else {
    return `00:00 min`
  }
}

export const getFileCategory = (fileType) => {
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
  const documentExtensions = ['pdf', 'doc', 'docx', 'txt'];
  const videoExtensions = ['mp4', 'mov', 'avi', 'mkv'];

  if (imageExtensions.includes(fileType)) {
    return 'Image';
  } else if (documentExtensions.includes(fileType)) {
    return toCamelCase(fileType);
  } else if (videoExtensions.includes(fileType)) {
    return 'Video';
  } else {
    return 'Other';
  }
}

export const toCamelCase = str => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export const getFirstVideoUrl = (files) => {
  for (let i = 0; i < files.length; i++) {
    if (files[i].fileType.toLowerCase() === "video") {
      return files[i].fileUrl;
    }
  }
  return null;
};

export const formatFloat = (num) => {
  if (num % 1 === 0) {
    return num;
  } else {
    return num.toFixed(2);
  }
}

export const isIIMSirMauriEmail = email => {
  const domain = email?.split('@')[1];
  return domain === "iimsirmaur.ac.in";
}