import React, { useState, useEffect } from "react";
import OTPInput from "otp-input-react";
import { useDispatch } from "react-redux";
import { authenticate, quizFormData, validate } from "../../../Redux/Actions/auth";
import { APIURL, TENANT_KEY } from "../../../Redux/Actions/config";
const successStyle = { color: "green", textAlign: "left" }

const RegisterStepTwo = (props) => {

  const dispatch = useDispatch();
  const { number } = props.data

  const [OTP, setOTP] = useState();
  const [success, setSuccess] = useState("");
  const [resendOTPMsg, setResendOTPMsg] = useState("");
  const [cityData, setCityData] = useState({});

  useEffect(() => {
    getClassDataWithFetch();
  }, []);

  const getClassDataWithFetch = async () => {

    fetch(`${APIURL}/api/city/`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Headers": "Content-Type",
        "Content-Type": "application/json",
        "kid-ex-tenant-id": TENANT_KEY
      }
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === true) {
          setCityData(res);
        }
      });
  };

  var cityInfo = "";
  if (cityData !== "") {
    cityInfo = cityData.data;
  }

  const narcSubmitOne = (e) => {
    e.preventDefault();
    setResendOTPMsg("");
    if (!OTP) {
      setSuccess("Please enter Otp");
    } else if (OTP.length < 6) {
      setSuccess("Please enter valid Otp");
    }
    if (OTP !== "" && OTP !== undefined) {
      dispatch(validate({ mobile: number, secret: OTP }, () => {
        props.onComplete({ formData: { number } })
      }))
    }
  };

  const resendOTP = () => {
    dispatch(authenticate({ mobile: number }));
  }

  return (
    <div className="register-new-child card p-4">
      <div className="card-body">
        <h4><strong>Enter verification code</strong></h4>
        <form onSubmit={narcSubmitOne.bind(this)} className="form stepTwo mt-3">
          <div className="verificationTo mb-3">We have just sent a verification code to your provided contact details.</div>
          {success && <div className="text-danger mb-3">{success}</div>}
          <div className="form-group mb-3">
            <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false} secure />
          </div>
          <div className="otpUpdate mb-3">
            <div className="resend-otp-btn" onClick={resendOTP}>Send the code again</div>
          </div>
          {resendOTPMsg && <div className="msg error mb-3" style={successStyle}>{resendOTPMsg}</div>}
          <div className="submitMe">
            <button className="btn start-btn" disabled={OTP?.length != 6}>Enter OTP</button>
          </div>
          <div className="form-pagination mt-2 text-center">
            <ul>
              <li className="active"></li>
              <li className="active"></li>
            </ul>
          </div>
        </form>
      </div>
    </div>
  )
}

export default RegisterStepTwo;
