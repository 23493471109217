import React from 'react'
import { useSelector } from 'react-redux';
import moment from 'moment';
import { formatFloat } from '../../../helper';

const WorkAndEducation = () => {

  const profile = useSelector(state => state.digiProfile.data);

  return (
    <div className='mt-5'>
      <h2 className='title-18 title-16 inter text-uppercase mb-3'>WORK EXPERIENCE</h2>
      <div className='job-exp-row mb-4'>
        <div className='job-designation'>
          {(profile?.work_experience_duration === null || profile?.work_experience_duration === 0) ? "Fresher" : `${profile?.work_experience_duration} months`}
        </div>
        {/* {profile?.work_experience?.map(item => {
          return (<div className='job-exp-row-item inter'>
            <div className='job-detail-left'>
              <div className='job-exp-date mb-2'>Jan 24 - May 24</div>
              <div className='job-designation mb-2'>
                Product Manager
                <span className='job-type'>{WORKTYPE[item?.work_type] ?? item?.work_type}</span>
              </div>
              <div className='job-company'>Pebble</div>
            </div>
            <div className='job-description'>
              <p>{item?.description}</p>
            </div>
          </div>)
        })} */}
      </div>
      <div className='inter mb-4'>
        <h2 className='title-18 title-16 inter text-uppercase mb-3'>EDUCATION</h2>
        <div className='mb-5'>
          <div className='job-exp-date mb-2'>
            {profile?.post_graduation_start_date ? moment(profile?.post_graduation_start_date).format("MMM YY") : "NA"} -  {profile?.post_graduation_end_date ? moment(profile?.post_graduation_end_date).format("MMM YY") : profile?.post_graduation_start_date ? "Present" : "NA"} | {profile?.post_graduation_cgpa ? `${formatFloat(profile?.post_graduation_cgpa)}%` : "NA"}
          </div>
          <div className='job-designation'>
            {profile?.post_graduation_college ?? "NA"}
            <span className='job-type'>{profile?.postGraduationDegree ?? "NA"}</span>
          </div>
        </div>
        {profile?.graduation_college &&
          <div className='mb-5'>
            <div className='job-exp-date mb-2'>
              {profile?.graduation_start_date ? moment(profile?.graduation_start_date).format("MMM YY") : "NA"} -  {profile?.graduation_end_date ? moment(profile?.graduation_end_date).format("MMM YY") : profile?.graduation_start_date ? "Present" : "NA"} | {profile?.graduation_cgpa ? `${formatFloat(profile?.graduation_cgpa)}%` : "NA"}
            </div>
            <div className='job-designation'>
              {profile?.graduation_college ?? "NA"}
              <span className='job-type'>{profile?.graduationDegree ?? "NA"}</span>
            </div>
          </div>
        }
        {profile?.twelfth_board_name &&
          <div className='mb-5'>
            <div className='job-exp-date mb-2'>{profile?.twelfth_percentage ? `${formatFloat(profile?.twelfth_percentage)}%` : "NA"}</div>
            <div className='job-designation'>
              {profile?.twelfth_board_name ?? "NA"}
              <span className='job-type'>Class 12th</span>
            </div>
          </div>
        }
        {profile?.tenth_board_name &&
          <div className='mb-5'>
            <div className='job-exp-date mb-2'>{profile?.tenth_percentage ? `${formatFloat(profile?.tenth_percentage)}%` : "NA"}</div>
            <div className='job-designation'>
              {profile?.tenth_board_name ?? "NA"}
              <span className='job-type'>Class 10th</span>
            </div>
          </div>
        }
        <div className='mb-5'>
          <div className='job-designation'>
            AREA OF INTEREST
            <span className='job-type'>{profile?.area_of_interest_1 ?? "NA"}</span>
            <span className='job-type'>{profile?.area_of_interest_2 ?? "NA"}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WorkAndEducation