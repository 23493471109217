import React from 'react'
import "./style.css";

const MasterLogin = () => {
    return (
        <div className='section-height'>
            <div className='row h-100 m-0'>
                <div className='col-md-6 d-flex justify-content-center align-items-center side-vector'>
                    <div className='text-center inter'>
                        <h1 className='login-title'>Company Login</h1>
                        <div className='login-form'>
                            <label className='text-start'>Email</label>
                            <input type='email' placeholder='Email' className='form-control'/>
                        </div>
                        <button type='button' className='site-btn blue-btn w-100 mt-4'>Send OTP</button>
                    </div>
                </div>
                <div className='col-md-6 right-side-vector'>
                    <div className='text-center'>
                        <img src='../../images/logom.svg' />
                        <div className='mt-5'>
                            <h2 className='login-slider-title mb-4'>Talent Acquisition</h2>
                            <div className='row'>
                                <div className='login-slide'>
                                    <img src='../../images/header-hexa-slide-1.svg'  className='up-layer'/>
                                    <div className='login-slide-content'>
                                        <p className='inter'>
                                            <b>Digital Interviews:</b> Conduct seamless and efficient interviews online.
                                        </p>
                                    </div>
                                    <img src='../../images/footer-hexa-slide-1.svg'  className='up-layer'/>
                                </div>
                                <div className='login-slide'>
                                    <img src='../../images/header-hexa-slide-2.svg'  className='up-layer'/>
                                    <div className='login-slide-content'>
                                        <p className='inter'>
                                            <b>Digital Interviews:</b> Conduct seamless and efficient interviews online.
                                        </p>
                                    </div>
                                    <img src='../../images/footer-hexa-slide-2.svg'  className='up-layer'/>
                                </div>
                                <div className='login-slide'>
                                    <img src='../../images/header-hexa-slide-3.svg'  className='up-layer'/>
                                    <div className='login-slide-content'>
                                        <p className='inter'>
                                            <b>Digital Interviews:</b> Conduct seamless and efficient interviews online.
                                        </p>
                                    </div>
                                    <img src='../../images/footer-hexa-slide-3.svg'  className='up-layer'/>
                                </div>
                            </div>

                        </div>

                        <div class='r-hex'>
                            <div class='r-hex-inner'></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MasterLogin;